import { keyBy } from "lodash-es"

import { useKitSession } from "../KitSessionContext"

import CrisisResponseTeamSummary from "domains/Exercise/ResultsComponents/CrisisResponseTeamSummary"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useSessionExerciseInstances } from "resources/exercise"
import { useKitParticipants } from "resources/monthly_kit"
import BulletedList from "ui/BulletedList"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const CrisisResponseSummaryStep = ({ className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!participants || (isFetching && !exerciseInstances)) {
    return <Loading />
  }

  const participantIdMap = keyBy(participants, "id")

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-small">Group discussion</h2>
      <SidebarContainer>
        <TextContainer maxWidth={860} className="mr-xl">
          <p className="text-gray-9 mb-small">Now look over your team results as a whole.</p>
          <BulletedList className="text-gray-9 mb-medium">
            <li>
              Look at the team overview chart. Are there any responder types with high overlap or any that are missing?
              What do you think that means for your team?
            </li>
            <li>
              Click on each person’s name in the key to review their the top superpowers. Have you seen any of these in
              action?
            </li>
          </BulletedList>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
      <CrisisResponseTeamSummary
        userIdMap={participantIdMap}
        exerciseInstances={exerciseInstances}
        team={team}
        className="mb-xl"
      />
    </StepContent>
  )
}

export default CrisisResponseSummaryStep
