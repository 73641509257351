import { useKitSession } from "../KitSessionContext"

import { getUserExerciseInstance } from "domains/Exercise/results_utils"
import FiveSensesUserResults from "domains/Exercise/ResultsComponents/FiveSensesUserResults"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SessionSelectedUsersShareView from "domains/KitSession/components/SessionSelectedUsersShareView"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useSessionExerciseInstances } from "resources/exercise"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useSelectedUser } from "ui/SelectedUserContext"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const FiveSensesShareStep = ({ sessionStep, className }) => {
  const { kitInstance, team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (isFetching && !exerciseInstances) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-medium">Share out</h2>
      <SidebarContainer>
        <TextContainer className="text-gray-9 mr-xl">
          <p className="mb-large">
            Now each person will get time to share their stories with the team. If there’s time, you can ask each other
            clarifying questions.
          </p>
        </TextContainer>
        <FacilitatorTip className="sidebar-right" icon="share-screen" />
      </SidebarContainer>
      <SessionSelectedUsersShareView
        sessionStep={sessionStep}
        kitInstance={kitInstance}
        minMinutesPerUser={3}
        maxMinutesPerUser={4}
      >
        <UserResults exerciseInstances={exerciseInstances} />
      </SessionSelectedUsersShareView>
    </StepContent>
  )
}

const UserResults = ({ exerciseInstances }) => {
  const { selectedUser } = useSelectedUser()
  const selectedExercise = getUserExerciseInstance(exerciseInstances, selectedUser)

  if (!selectedExercise) {
    return null
  }

  return <FiveSensesUserResults exercise={selectedExercise} selectedUser={selectedUser} isSession={true} />
}

export default FiveSensesShareStep
