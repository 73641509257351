import { styled } from "styled-components"

import { getUserAnswer } from "../results_utils"

import Grid from "ui/Grid"
import Tooltip from "ui/Tooltip"

const ImageFieldMultiUserGrid = styled(function ImageFieldMultiUserGrid({
  identifier,
  exerciseInstances,
  sortedUsers,
  className,
}) {
  const answerMaps = sortedUsers
    .map((user) => {
      const answer = getUserAnswer(exerciseInstances, user, identifier)
      if (!answer) {
        return null
      }
      return { user, answer }
    })
    .filter(Boolean)

  return (
    <Grid className={className} $columns={3} $columnsMobile={2} $gap="var(--spacing-3)">
      {answerMaps.map(({ user, answer }) => (
        <Tooltip key={user.id} top float title={user.short_name}>
          <img src={answer} alt={identifier} className="image-grid-image border-radius m-auto" />
        </Tooltip>
      ))}
    </Grid>
  )
})`
  .image-grid-image {
    object-fit: cover;
    max-width: 100%;
    max-height: 250px;
  }
`

export default ImageFieldMultiUserGrid
