import { walkNodeTree } from "utils/dom"
import { safeDynamicImport } from "utils/import"
import { hashContentString } from "utils/string"

// revertTranslationMap is used when switching translation layers - first the
// previous translation changes must be perfectly removed so that new
// translationLayer keys map correctly to the right content on screen.
// This revert process is also used when switching language back to English.
const revertTranslationMap = new Map()

function isTranslatableKit(slug) {
  return ["psychological-safety", "jumbo-guess-who"].includes(slug)
}

async function applyTranslationLayer(slug, translationCode = null, isTeamLead = false) {
  revertTranslationMap.forEach((originalText, node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      node.nodeValue = originalText
    } else if (node.tagName === "INPUT" || node.tagName === "TEXTAREA") {
      node.setAttribute("placeholder", originalText)
    }
  })
  revertTranslationMap.clear()

  // Providing a null translationCode simply reverts whatever translation was applied:
  if (!translationCode) {
    return
  }

  // Lazily-load translation-layer files so they have no effect on users without
  // kit-translation feature-flag:
  const userType = isTeamLead ? "lead" : "member"
  const basePath = `layers/${slug}.${userType}`
  const translationVersion = "v2"
  const translationLayer = await (async () => {
    switch (translationCode) {
      case "es":
        return await safeDynamicImport(() => import(`./${basePath}.spanish.${translationVersion}.json`))
      case "fr":
        return await safeDynamicImport(() => import(`./${basePath}.french.${translationVersion}.json`))
      case "zh-CN":
        return await safeDynamicImport(() => import(`./${basePath}.chinese-simplified.${translationVersion}.json`))
      case "hi":
        return await safeDynamicImport(() => import(`./${basePath}.hindi.${translationVersion}.json`))
      case "ru":
        return await safeDynamicImport(() => import(`./${basePath}.russian.${translationVersion}.json`))
      case "vi":
        return await safeDynamicImport(() => import(`./${basePath}.vietnamese.${translationVersion}.json`))
      case "rv":
        return await safeDynamicImport(() => import(`./${basePath}.reversed.${translationVersion}.json`))
      case "pl":
        return await safeDynamicImport(() => import(`./${basePath}.piglatin.${translationVersion}.json`))
      default:
        return null
    }
  })(translationCode)

  const isSessionPage = window.location.pathname.startsWith("/session")

  let header, title, content, navigation, loaders
  do {
    await new Promise(requestAnimationFrame)

    content = document.querySelector(!isSessionPage ? ".main-container" : '[data-testid="session-step-content"]')
    loaders = document.querySelectorAll('[data-testid="loading-spinner"]')

    if (isSessionPage) {
      header = document.querySelector('[data-testid="session-step-header"]')
      title = document.querySelector('[data-testid="session-step-title"]')
      navigation = document.querySelector('[data-testid="session-step-navigation"], .finish-button')
    }
  } while (loaders.length || !content || (isSessionPage && !(header && navigation)))

  const stepUrlKey = window.location.pathname.replace(/\d+/, "{KIT_INSTANCE_ID}")
  const stepData = translationLayer[stepUrlKey]
  if (stepData) {
    for (const [section, element] of Object.entries({
      header,
      title,
      content,
      navigation,
    })) {
      if (!element) {
        continue
      }
      // TODO(hackathon,kit-translation,evnp): Would DOM MutationObserver be a more robust approach here?
      walkNodeTree(element, {
        ignore: (
          node // ignore contenteditable="true" elements + their children
        ) => node.getAttribute?.("contenteditable")?.toLowerCase() === "true",
        filter: (node) =>
          node.nodeType === Node.TEXT_NODE ||
          ((node.tagName === "INPUT" || node.tagName === "TEXTAREA") && node.getAttribute?.("placeholder")),
        includePreceedingAdjacentNodes: true,
        callback: (node, preceedingAdjacentNodes) => {
          const nodes = [...preceedingAdjacentNodes, node]
          const originalText =
            node.nodeType === Node.TEXT_NODE
              ? nodes.map((n) => n.nodeValue).join("")
              : (node.getAttribute?.("placeholder") ?? "")
          const key = hashContentString(originalText, { ignoreNumbers: true })
          let translatedText = stepData[section]?.[key]
          // FALLBACK - use full node text as key (useful for testing/debugging)
          //if (!translatedText) {
          //  translatedText = stepData[section][originalText]
          //}
          if (translatedText) {
            // Update numbers inside translatedText with values from originalText:
            // (in case they've changed since translation, or are dynamic values)
            originalText.match(/\d+/g)?.forEach((number) => {
              translatedText = translatedText.replace(/\d+/, number)
            })

            if (node.nodeType === Node.TEXT_NODE) {
              revertTranslationMap.set(node, node.nodeValue)

              // Add spaces around translated text. These will generally be ignored
              // by standard HTML whitespace handling behavior, but if two text nodes
              // are adjacent it will allow a space between them to be maintained.
              node.nodeValue = ` ${translatedText} `

              // Clear content of adjacent nodes; we added full text to the last node.
              for (const prevNode of preceedingAdjacentNodes) {
                revertTranslationMap.set(prevNode, prevNode.nodeValue)
                prevNode.nodeValue = ""
              }
            } else if (node.tagName === "INPUT" || node.tagName === "TEXTAREA") {
              revertTranslationMap.set(node, originalText)
              node.setAttribute("placeholder", translatedText)
            }
          }
        },
      })
    }
  }
}

export { applyTranslationLayer, isTranslatableKit }
