import { Formik } from "formik"
import { find, keyBy } from "lodash-es"
import { useState } from "react"

import ShareResultsButton from "./ShareResultsButton"
import UpdateResultsButton from "./UpdateResultsButton"

import { getUserExerciseInstance, getExerciseAnswer } from "domains/Exercise/results_utils"
import AssessmentAccuracyForm from "domains/Exercise/ResultsComponents/AssessmentAccuracyForm"
import TalentsChart from "domains/Exercise/ResultsComponents/TalentsChart"
import TalentsGrid from "domains/Exercise/ResultsComponents/TalentsGrid"
import TalentsUserResults from "domains/Exercise/ResultsComponents/TalentsUserResults"
import LineRatingField from "forms/fields/LineRatingField"
import SelectField from "forms/fields/SelectField"
import { StarShootingIcon } from "icons/FontAwesomeIcons"
import { useLatestTeamExerciseInstances } from "resources/exercise"
import { sortUsersByShortName } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import View from "ui/View"

const TalentsResultsReview = ({ kit, selectedTeam }) => {
  const [shareLink, setShareLink] = useState(null)
  const query = useQueryParams()
  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })

  if (isFetching) {
    return <Loading />
  }

  const users = selectedTeam.members.filter((x) => getUserExerciseInstance(exerciseInstances, x))
  const userIdMap = keyBy(users, "id")
  const initialUserId = parseInt(query.participant)
  const initialUser = find(users, { id: initialUserId })
  const filteredExerciseInstances = exerciseInstances.filter((x) => userIdMap[x.user_id])

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-medium">
        <StarShootingIcon className="text-orange-3 title-icon mr-medium" />
        <h2 className="text-gray-9">Talent Profile</h2>
      </View>
      <p className="text-gray-9 mb-small">
        Here are the individual and team Talent Profiles from your team session on Natural Talents. Use these charts to
        make sure everyone is doing work that maximizes their talents.
      </p>

      <SelectedUserResults
        users={users}
        userIdMap={userIdMap}
        exerciseInstances={filteredExerciseInstances}
        initialUser={initialUser}
        shareLink={shareLink}
        setShareLink={setShareLink}
        kit={kit}
        selectedTeam={selectedTeam}
      />
    </>
  )
}

const TEAM_ALL = 0

const SelectedUserResults = ({
  users,
  userIdMap,
  exerciseInstances,
  initialUser,
  shareLink,
  setShareLink,
  kit,
  selectedTeam,
}) => {
  const [selectedUserId, setSelectedUserId] = useState(initialUser?.id || TEAM_ALL)
  const sortedUsers = sortUsersByShortName({ users })
  const initialValues = { current_team_member: selectedUserId }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, { id: selectedUserId })

  const handleUserChange = ({ target }) => {
    const user = find(users, {
      id: parseInt(target.value),
    })
    setSelectedUserId(user?.id || TEAM_ALL)
    setShareLink(null)
  }

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-large mt-medium">
        <View $flexDirection="row" $flexDirectionTablet="column" $flexDirectionMobile="column" $alignItems="center">
          <Formik initialValues={initialValues}>
            <SelectField name="current_team_member" size="medium" value={selectedUserId} onChange={handleUserChange}>
              <option value={TEAM_ALL}>Team view</option>
              {sortedUsers.map(({ id, short_name }) => (
                <option key={id} value={id}>
                  {short_name}
                </option>
              ))}
            </SelectField>
          </Formik>
          <View $justifyContent="flex-end" $justifyContentTablet="flex-start" $justifyContentMobile="flex-start">
            <ShareResultsButton
              selectedUserId={selectedUserId}
              selectedExercise={selectedExercise}
              shareLink={shareLink}
              setShareLink={setShareLink}
              shareAssessmentTitle="Talent Profile"
            />
            <UpdateResultsButton selectedUserId={selectedUserId} kitSlug={kit.slug} selectedTeam={selectedTeam} />
          </View>
        </View>
      </View>
      {selectedUserId === TEAM_ALL ? (
        <>
          <TalentsChart
            userIdMap={userIdMap}
            exerciseInstances={exerciseInstances}
            showLegend={true}
            className="mb-xl"
          />
          <TalentsGrid exerciseInstances={exerciseInstances} userIdMap={userIdMap} className="mb-xl" />
        </>
      ) : (
        <>
          <h2 className="text-bold text-gray-9 mt-xl mb-medium">Your top talents</h2>
          <p className="text-gray-9 mb-large">
            These areas are more likely to come easily to you and bring you joy. Try to spend as much of your time here
            as you can.
          </p>
          <TalentsChart userIdMap={userIdMap} exerciseInstances={[selectedExercise]} className="mb-xl" />
          <TalentsUserResults exercise={selectedExercise} className="mb-xl" />
          <TalentAssessmentAccuracyReview selectedExercise={selectedExercise} />
        </>
      )}
    </>
  )
}

const TalentAssessmentAccuracyReview = ({ selectedExercise }) => {
  const values = {
    assessment_feel: getExerciseAnswer(selectedExercise, "assessment_feel"),
    assessment_feel_reason: getExerciseAnswer(selectedExercise, "assessment_feel_reason"),
  }
  const lineRatingLabels = ["Not at all", "Just a bit", "Somewhat", "Pretty much", "A lot"]

  return (
    <AssessmentAccuracyForm title="Reflection">
      <p className="text-normal text-gray-9 mb-large">
        The purpose of this exercise is to help uncover patterns and inspire conversation; not put you in a box.
      </p>
      <div className="text-field-label mb-xs">How much do these results feel like you?</div>
      <LineRatingField
        numOptions={5}
        axisLabels={lineRatingLabels}
        ariaLabels={lineRatingLabels}
        className="mb-large px-small"
        disabled
        value={values?.assessment_feel}
      />
      <p className="text-field-label mb-xs">Why or why not?</p>
      <div className="text-gray-8 bg-gray-1 border-radius px-medium py-small">{values?.assessment_feel_reason}</div>
    </AssessmentAccuracyForm>
  )
}

export default TalentsResultsReview
