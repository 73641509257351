import * as Sentry from "@sentry/browser"

import { useKitSession } from "../KitSessionContext"

import NumPeopleCompletedProgressBar from "./NumPeopleCompletedProgressBar"
import NumPeopleCompletedTrafficSign from "./NumPeopleCompletedTrafficSign"

import {
  useKitParticipantCount,
  useKitParticipants,
  useKitStepCompletedUsers,
  useUpdateKitStepCompletedUsers,
} from "resources/monthly_kit"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const NumPeopleCompleted = ({ label, display_type = "circle_counter", path, kitInstance, sessionStep }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: completedUsers, isInitialLoading } = useKitStepCompletedUsers({
    kitInstanceId: kitInstance.id,
    stepPath: path ?? sessionStep.path,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { mutateAsync: updateKitStepCompletedUsers } = useUpdateKitStepCompletedUsers({
    kitInstanceId: kitInstance.id,
    stepPath: path ?? sessionStep.path,
  })

  useEffectAfterChange(() => {
    const updateKitStepsCompletedUsersCallback = async () => {
      await updateKitStepCompletedUsers()
    }
    if (!isInitialLoading) {
      updateKitStepsCompletedUsersCallback()
    }
  }, [updateKitStepCompletedUsers, isInitialLoading])

  if (isInitialLoading) {
    return <Loading />
  }
  if (display_type === "text_count") {
    return (
      <DisplayTextCount
        label={label}
        completedUsers={completedUsers}
        kitInstance={kitInstance}
        sessionRealtimeUpdates={sessionRealtimeUpdatesV2}
      />
    )
  } else if (display_type === "circle_counter") {
    return (
      <DisplayCircleCounter
        label={label}
        completedUsers={completedUsers}
        kitInstance={kitInstance}
        sessionRealtimeUpdates={sessionRealtimeUpdatesV2}
      />
    )
  } else if (display_type === "stop_light_counter") {
    return (
      <StopLightCounter
        label={label}
        completedUsers={completedUsers}
        kitInstance={kitInstance}
        sessionRealtimeUpdates={sessionRealtimeUpdatesV2}
      />
    )
  } else {
    Sentry.captureException(new Error(`NumPeopleCompleted.js: Unexpected display_type "${display_type}"`))
    return null
  }
}

const DisplayTextCount = ({ label, completedUsers, kitInstance, sessionRealtimeUpdates }) => {
  const { data: { participant_count: participantCount } = {} } = useKitParticipantCount({
    kitInstance,
    sessionRealtimeUpdates,
  })

  if (!participantCount) {
    return null
  }

  return (
    <h2>
      <span className="text-thin text-gray-8">{label}</span> {completedUsers ?? 1} of {participantCount}
    </h2>
  )
}

const DisplayCircleCounter = ({ label, completedUsers, kitInstance, sessionRealtimeUpdates }) => {
  const { data: participants } = useKitParticipants({
    kitInstance,
    sessionRealtimeUpdates,
  })

  if (!participants) {
    return null
  }

  const ariaLabel = `${completedUsers ?? 1} of ${participants.length} participants completed`

  return (
    <div className="mb-xl">
      <p className="text-gray-9 text-bold mb-small">{label}</p>
      <div aria-label={ariaLabel}>
        <div aria-hidden={true}>
          <NumPeopleCompletedProgressBar current={completedUsers ?? 1} total={participants.length} />
        </div>
      </div>
    </div>
  )
}

const StopLightCounter = ({ completedUsers, kitInstance, sessionRealtimeUpdates }) => {
  const { data: { participant_count: participantCount } = {} } = useKitParticipantCount({
    kitInstance,
    refetchInterval: 3000,
    sessionRealtimeUpdates,
  })

  if (!participantCount) {
    return null
  }

  return (
    <div className="mb-xl">
      <NumPeopleCompletedTrafficSign current={completedUsers ?? 1} total={participantCount} />
    </div>
  )
}

export default NumPeopleCompleted
