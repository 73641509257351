import { Form, Formik } from "formik"
import { pick } from "lodash-es"

import { useIsSchoolAccountType } from "components/AccountTypeContext"
import JobFunctionsField, { JOB_FUNCTIONS, SCHOOL_JOB_FUNCTIONS } from "forms/fields/JobFunctionsField"
import PreferredPronounsField from "forms/fields/PreferredPronounsField"
import TextField from "forms/fields/TextField"
import FormMessage from "forms/FormMessage"
import Yup, { profileSchemas, userSchemas } from "forms/yup"
import { ArrowRightIcon } from "icons/FontAwesomeIcons"
import SubmitButton from "ui/SubmitButton"
import View from "ui/View"

const nameSchema = pick(userSchemas, ["first_name", "last_name"])
const profileSchema = Yup.object().shape(pick(profileSchemas, ["pronouns", "job_function"]))
const nameOnlyFormSchema = Yup.object().shape(nameSchema)
const profileOnlyFormSchema = Yup.object().shape({ profile: profileSchema })
const fullFormSchema = Yup.object().shape({ ...nameSchema, profile: profileSchema })

const NameAndProfileForm = ({
  user,
  showFirstAndLastName,
  showProfile,
  onSubmit,
  initialFirstName = null,
  initialLastName = null,
}) => {
  if (!showFirstAndLastName && !showProfile) {
    return null
  }

  const formSchema =
    showProfile && showFirstAndLastName
      ? fullFormSchema
      : showFirstAndLastName
        ? nameOnlyFormSchema
        : profileOnlyFormSchema
  const nameInitialValues = showFirstAndLastName
    ? { first_name: initialFirstName ?? user.first_name ?? "", last_name: initialLastName ?? user.last_name ?? "" }
    : {}
  const profileInitialValues = showProfile
    ? {
        profile: {
          pronouns: user.profile?.pronouns ?? "",
          job_function: user.profile?.job_function ?? "",
        },
      }
    : {}
  const initialValues = {
    ...nameInitialValues,
    ...profileInitialValues,
  }

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={formSchema} onSubmit={onSubmit}>
      <ProfileForm showFirstAndLastName={showFirstAndLastName} showProfile={showProfile} />
    </Formik>
  )
}

const ProfileForm = ({ showFirstAndLastName, showProfile }) => {
  const isSchool = useIsSchoolAccountType()
  const jobFunctions = isSchool ? SCHOOL_JOB_FUNCTIONS : JOB_FUNCTIONS

  return (
    <Form name="register" autoComplete="off" className="space-y-large mt-large">
      {!!showFirstAndLastName && (
        <>
          <TextField
            label="First name *"
            name="first_name"
            placeholder="Enter your first name here"
            autoFocus
            size="medium"
          />
          <TextField
            label="Last name *"
            name="last_name"
            placeholder="Enter your last name here"
            size="medium"
            autoComplete="off"
          />
        </>
      )}
      {!!showProfile && (
        <>
          <PreferredPronounsField
            name="profile.pronouns"
            label="How would you like us to refer to you?"
            size="medium"
          />
          <JobFunctionsField
            name="profile.job_function"
            label="Your job function *"
            size="medium"
            jobFunctions={jobFunctions}
          />
        </>
      )}
      <FormMessage />
      <View className="pt-large">
        <SubmitButton>
          <span>Continue</span>
          <ArrowRightIcon />
        </SubmitButton>
      </View>
    </Form>
  )
}

export default NameAndProfileForm
