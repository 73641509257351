import cn from "classnames"
import { Form, Formik } from "formik"
import Markdown from "react-markdown"
import remarkGfm from "remark-gfm"
import { styled } from "styled-components"

import { ARTI_RATING_NA_VALUE } from "../constants"

import ArtiKitRecommendation from "./ArtiKitRecommendation"

import LineRatingField from "forms/fields/LineRatingField"
import TextField from "forms/fields/TextField"
import { FaceAngryIcon, FaceFrownIcon, FaceGrinHeartsIcon, FaceMehIcon, FaceSmileIcon } from "icons/FontAwesomeIcons"
import useWindowSize from "ui/hooks/useWindowSize"
import HorizontalRule from "ui/HorizontalRule"
import Loading from "ui/Loading"
import { onKeyEvent } from "utils/browser"

const ArtiBotMessage = styled(function ArtiBotMessage({
  className,
  message,
  showLoading,
  showRatingFields,
  showKitRecommendations,
  onRating,
  onKeyPressFocusElement,
  user,
  team,
  messageIndex,
}) {
  const showTextInputFeedback = !user.is_demo_mode_active
  const { isMobileLandscapeOrSmaller } = useWindowSize()
  const userIsTeamLead = user.id === team.team_lead_id
  return (
    <div className={className}>
      <Markdown remarkPlugins={[remarkGfm]}>{message.text?.trim()}</Markdown>
      {!!showLoading && <Loading size="25px" className={cn("mb-small", { "arti-message-loading": !message.text })} />}
      {!showLoading && !!showRatingFields && (
        <Formik
          initialValues={{
            helpfulness_rating: { value: message.helpfulnessRating?.toString() ?? null },
            accuracy_rating: { value: message.accuracyRating?.toString() ?? null },
            feedback: message.feedback ?? "",
          }}
          enableReinitialize
        >
          <Form className="mt-medium">
            <HorizontalRule margin="my-medium" />
            <label className="text-semi-bold text-small">How helpful was this answer?</label>
            <LineRatingField
              className="mt-xxs mb-xs"
              name="helpfulness_rating"
              barMode
              iconRating={true}
              saveOnChange={onRating}
              numOptions={6}
              //   TODO (loranne): Add from utils when available
              optionTransformer={getRatingFieldOptionTransformer({
                isMobile: isMobileLandscapeOrSmaller,
              })}
            />
            {!!showTextInputFeedback && (
              <TextField
                className="mt-xxs mb-xs full-width"
                name="feedback"
                placeholder="Optional: What was unhelpful or helpful about it?"
                saveOnChange={onRating}
                onKeyPress={onKeyEvent("enter", (ev) => {
                  ev.preventDefault()
                  onKeyPressFocusElement?.focus()
                })}
              />
            )}
          </Form>
        </Formik>
      )}
      {!showLoading && !!showKitRecommendations && !!userIsTeamLead && !!messageIndex && (
        <ArtiKitRecommendation user={user} team={team} />
      )}
    </div>
  )
})`
  position: relative;
  min-height: 3.5rem;

  ul {
    line-height: 1.6rem;
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-1);
  }
  text-normal {
    line-height: 1.6rem;
  }

  .arti-message-loading {
    position: absolute;
    /* vertically-center loading spinner: */
    top: calc(50% - 25px); /* 25px: size of loading spinner */
    left: 0;
  }
`

function getRatingFieldOptionTransformer({ isMobile }) {
  return (value) => {
    const labels = [
      {
        label: "Very unhelpful",
        Component: FaceAngryIcon,
      },
      {
        label: "Somewhat unhelpful",
        Component: FaceFrownIcon,
      },
      {
        label: "Neutral",
        Component: FaceMehIcon,
      },
      {
        label: "Somewhat helpful",
        Component: FaceSmileIcon,
      },
      {
        label: "Very helpful",
        Component: FaceGrinHeartsIcon,
      },
    ].map(({ label, Component }, idx) => ({
      label: <Component key={label} className="icon" />,
      tooltip: label,
      value: idx + 1,
    }))

    return (
      [
        // We reverse ordering of rating fields on mobile. This required an inversion
        // of values, which is why we use 'value: labels.length - idx' below:
        ...(isMobile ? labels.reverse() : labels),
        // We designate 1st space on arti rating fields to the "N/A" value, which
        // is ARTI_RATING_NA_VALUE = -1 on backend. Perform that value mapping here:
        { value: ARTI_RATING_NA_VALUE, label: <div className="text-normal">N/A</div> },
      ][value - 1] ?? { value }
    )
  }
}

export default ArtiBotMessage
