import cn from "classnames"
import { styled } from "styled-components"

import { getExerciseAnswer, getExerciseComponent } from "../results_utils"

import getIconOrError from "icons"
import { SmileIcon } from "icons/FontAwesomeIcons"
import Loading from "ui/Loading"
import View from "ui/View"

const IconGroupRatingFieldSelectedUserBarChart = styled(function IconGroupRatingFieldSelectedUserBarChart({
  identifiers,
  exerciseInstance,
  className,
}) {
  if (!exerciseInstance) {
    return null
  }

  const answerData = identifiers.map((identifier) => {
    const exerciseComponent = getExerciseComponent(exerciseInstance, identifier)
    const options = exerciseComponent.options
    const label = exerciseComponent.label
    const answer = parseInt(getExerciseAnswer(exerciseInstance, identifier))
    const selectedOption = answer
      ? (options.find((option) => parseInt(option.value) === answer) ?? options[answer - 1] ?? {})
      : {}
    const { icon = null, color: iconColor = null } = selectedOption

    const extraExerciseComponent = getExerciseComponent(exerciseInstance, `${identifier}_extra`)
    const extraOptions = extraExerciseComponent.options
    const extraAnswer = getExerciseAnswer(exerciseInstance, `${identifier}_extra`)
    const extraAnswerChoice = extraOptions.find(({ value }) => value.toString() === extraAnswer)
    const extraIcon = extraAnswerChoice ? extraAnswerChoice.icon : null
    const extraIconLabel = extraAnswerChoice ? extraAnswerChoice.short_label : null

    return {
      label,
      value: answer,
      icon,
      iconColor,
      numOptions: options.length,
      extraValue: extraAnswer,
      extraIcon,
      extraIconLabel,
      extraOptions,
      extraNumOptions: extraOptions.length,
    }
  })

  if (!answerData.length) {
    return <Loading />
  }

  return (
    <View className={cn(className, "results")} $flexDirection="column">
      {answerData.map((answer) => {
        const Icon = answer.icon && getIconOrError(answer.icon)
        const ExtraIcon = answer.extraIcon && getIconOrError(answer.extraIcon)
        return (
          <View className="answer-row mb-large" $alignItems="center" key={answer.label}>
            <div className="answer-label text-semi-bold text-align-right mr-xs">{answer.label}</div>
            {!!Icon ? (
              <Icon color={answer.iconColor} className="fa-xl answer-icon mr-xs" />
            ) : (
              <SmileIcon className="hidden fa-xl mr-xs" />
            )}
            <div className="bar-total">
              {Array(answer.numOptions)
                .fill()
                .map((_, idx) => (
                  <div
                    key={idx}
                    className="bar-segment"
                    style={{
                      backgroundColor:
                        !Number.isInteger(answer.value) || idx + 1 > answer.value ? "var(--gray-4)" : answer.iconColor,
                    }}
                  ></div>
                ))}
            </div>
            {!!ExtraIcon && <ExtraIcon color="var(--gray-8)" className="fa-xl answer-icon ml-xs" />}
          </View>
        )
      })}
      <View className="chart-legend mb-xxxl" $justifyContent="center">
        {answerData[0].extraOptions.map((option) => {
          const LegendIcon = option.icon && getIconOrError(option.icon)
          return (
            <div className="chart-legend-item pr-medium" key={option.short_label}>
              {!!LegendIcon && <LegendIcon color="var(--gray-8)" className="fa-xl answer-icon" />}
              <span className="chart-legend-label pl-xxs">{option.short_label}</span>
            </div>
          )
        })}
      </View>
    </View>
  )
})`
  display: flex;

  .results {
    display: flex;
    flex-direction: column;
  }

  .amswer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .answer-label {
    min-width: 80px;
    text-align: right;
  }

  .answer-icon {
    display: flex;
  }

  .bar-total {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .bar-segment {
    flex-grow: 1;
    height: 20px;
    border: 1px solid #fff;
    border-radius: 0;

    :first-child {
      border-radius: 4px 0 0 4px;
    }

    :last-child {
      border-radius: 0 4px 4px 0;
    }
  }

  .chart-legend {
    display: flex;
    flex-direction: row;
  }

  .chart-legend-item {
    display: flex;
    flex-direction: row;

    :last-child {
      padding-right: 0;
    }
  }
`
export default IconGroupRatingFieldSelectedUserBarChart
