import DOMPurify from "dompurify"

const PurifyInnerHTMLDiv = ({ children }) =>
  typeof children === "string" ? (
    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(children) }} />
  ) : (
    (children ?? null) // Must return null if children=undefined, otherwise
    // "Error: Nothing was returned from render" will occur for that value.
  )

export default PurifyInnerHTMLDiv
