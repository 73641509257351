import api from "api"

interface ManuallyAddAccountData {
  email: string
  account_name: string
  account_type: AccountType
  product_type: string
  stripe_customer_id?: string
  stripe_subscription_id?: string
  num_people: number
  create_first_team: boolean
  send_email: boolean
  remove_demo_team_conversions: boolean
  gratitude_promo?: boolean
}

export default function manuallyAddAccount(values: ManuallyAddAccountData) {
  return api.post("/rtonly/manually_add_account/", values).then(({ data }) => data)
}
