import cn from "classnames"

import { getExerciseAnswer } from "../results_utils"

import AnswerDisplayContent from "ui/AnswerDisplayContent"

const TextFieldReadOnlyField = ({ identifier, exerciseInstance, marginClass = "mb-xl", className }) => {
  const answer = getExerciseAnswer(exerciseInstance, identifier)
  return <AnswerDisplayContent answer={answer} className={cn(className, marginClass)} />
}

export default TextFieldReadOnlyField
