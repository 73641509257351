import * as Sentry from "@sentry/browser"
import { useQueryClient } from "@tanstack/react-query"
import cn from "classnames"
import { Fragment } from "react"
import { useNavigate } from "react-router"
import { styled } from "styled-components"

import { authCacheKey } from "./resource"

import { initializeFirebaseUI } from "domains/Authentication/firebase"
import useEffectAfterFirstRender from "ui/hooks/useEffectAfterFirstRender"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import { forceOnePageReload, getLastForcedReloadTimeMs } from "utils/browser"
import { asStringOrNull } from "utils/string"

const FIREBASEUI_CONTAINER_ID = "firebaseui-auth-container"
const LOGIN_REDIRECT_LOADING_FORCE_REFRESH_AFTER_MS = 10_000

function getFirebaseUIContainerElement(): HTMLElement | null {
  return document.getElementById(FIREBASEUI_CONTAINER_ID) ?? null
}

interface FirebaseUIProps {
  className?: string
  ssoProviders?: SSOProviderData[]
  redirectUrl?: string | null
  ssoRedirectLogin?: boolean
  isLoginRedirectLoading?: boolean
  containerIdSuffix?: string | null
}

const FirebaseUI = ({
  className,
  ssoProviders = [],
  redirectUrl = null,
  ssoRedirectLogin = false,
  isLoginRedirectLoading = false,
  containerIdSuffix = null,
}: FirebaseUIProps = {}) => {
  const { next } = useQueryParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const containerId = containerIdSuffix ? `${FIREBASEUI_CONTAINER_ID}-${containerIdSuffix}` : FIREBASEUI_CONTAINER_ID

  useEffectAfterFirstRender((): (() => void) | null => {
    // No need to await here even though initializeFirebaseUI is an async function:
    initializeFirebaseUI(containerId, {
      ssoProviders,
      ssoRedirectLogin,
      onSignIn: () => {
        queryClient.removeQueries({ queryKey: authCacheKey, exact: true })
        navigate(redirectUrl || asStringOrNull(next) || "/")
      },
    })
    // If we're in redirect-loading state which gets stuck for a long period of time,
    // force a page reload to try to resolve issue holding up Firebase auth:
    // (page reload will ever happen once, due to forceOnePageReload logic)
    if (isLoginRedirectLoading) {
      const redirectLoadingRoute = window.location.pathname
      const timeoutId = setTimeout(() => {
        // After timeout period, only go ahead with reloading the page if:
        // - component is still in a "redirect-loading" state, AND
        // - the current route (url) has remained the same during this period.
        if (isLoginRedirectLoading && window.location.pathname === redirectLoadingRoute) {
          const willReload = forceOnePageReload()
          if (willReload) {
            Sentry.captureMessage(
              `[sso] Forced a page reload due to long post-redirect loading state (${
                LOGIN_REDIRECT_LOADING_FORCE_REFRESH_AFTER_MS / 1000
              } seconds).`
            )
          } else {
            const lastReloadMs = getLastForcedReloadTimeMs()
            const lastReloadDescription = lastReloadMs ? `${new Date().getTime() - lastReloadMs}ms ago` : ""
            Sentry.captureMessage(
              `[sso] Would have forced a page reload due to long post-redirect loading state (${
                LOGIN_REDIRECT_LOADING_FORCE_REFRESH_AFTER_MS / 1000
              } seconds), but forced-reload already occurred${lastReloadDescription}.`
            )
          }
        }
      }, LOGIN_REDIRECT_LOADING_FORCE_REFRESH_AFTER_MS)
      // Cancel possible forced-reload on component unmount (ie. auth success):
      return () => {
        clearTimeout(timeoutId)
        const lastReloadMs = getLastForcedReloadTimeMs()
        if (lastReloadMs) {
          Sentry.captureMessage(
            `[sso] Authentication successful after a forced page reload ${new Date().getTime() - lastReloadMs}ms ago.`
          )
        }
      }
    } else {
      return null
    }
  })

  return (
    <Fragment>
      {!!isLoginRedirectLoading && (
        <div className="mb-xl">
          Signing you in...
          <Loading className="mb-small" />
          Please wait. This can take up to one minute.
        </div>
      )}
      <div className={cn(className, { hidden: isLoginRedirectLoading })}>
        <div id={containerId}></div>
        <div className="firebaseui-loading-button">
          <Loading size="24px" className="mt-none pt-xs" />
        </div>
      </div>
    </Fragment>
  )
}

export default styled(FirebaseUI)`
  &.hidden {
    display: none;
  }

  position: relative;

  .firebaseui-loading-button {
    pointer-events: none;
    position: absolute;
    top: var(--spacing-3);
    right: 50%;
    transform: translateX(50%); /* center horizontally */

    /* width, height, and box-shadow copied directly from Firebase UI button element: */
    width: 187px;
    height: 40px;
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);

    /* Fade loading button out when Firebase UI button fades in: */
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #${FIREBASEUI_CONTAINER_ID}:empty + .firebaseui-loading-button {
    opacity: 1;
  }

  /* stylelint-disable-next-line selector-id-pattern */
  #${FIREBASEUI_CONTAINER_ID} {
    // Prevent change of content height when FirebaseUI initializes,
    // and also fade in the UI smoothly:
    &:empty {
      min-height: 128px;
      opacity: 0;
    }
    &:not(:empty) {
      opacity: 1;
    }
    transition: opacity 0.2s ease;

    // fix button centering:
    div {
      padding: 0;
    }

    // eliminate extra space on right side of buttons:
    button {
      width: fit-content;
    }

    // fix terms & conditions layout:
    p {
      padding: 0 24px;
    }
  }
`

export { getFirebaseUIContainerElement }
export type { FirebaseUIProps }
