import { formatPopularTopicAsSelectFieldOption, getPopularTopics } from "../utils"

import { ArtiTourStepNames } from "./ArtiTour"

import AdvancedSelectField from "forms/fields/AdvancedSelectField"

const ArtiPopularTopicsSelect = function ArtiPopularTopicsSelect({
  className,
  name,
  user,
  team,
  selectedMember,
  chatTypeSelected,
  placeholder,
  onSelect,
  formik,
  menuIsOpen,
  width = null,
  menuWidth = null,
  menuHeight = null,
  alignCenter = false,
  alignRight = false,
  borderRadius = null,
  borderRadiusOpen = null,
  disabled = false,
  fadeWhenDisabled = false,
}) {
  const options = getPopularTopics({ team, user, member: selectedMember, chatTypeSelected }).map(
    formatPopularTopicAsSelectFieldOption
  )
  return (
    <div className={className} data-arti-tour-step={ArtiTourStepNames.PopularTopics}>
      <AdvancedSelectField
        overflowHidden
        name={name}
        width={width}
        menuWidth={menuWidth}
        menuHeight={menuHeight}
        alignCenter={alignCenter}
        alignRight={alignRight}
        placeholder={<span className="text-italic">{placeholder}</span>}
        borderRadius={borderRadius}
        borderRadiusOpen={borderRadiusOpen}
        saveOnChange={(_name, value) => onSelect({ value, formik })}
        options={options}
        menuIsOpen={menuIsOpen}
        disabled={disabled}
        fadeWhenDisabled={fadeWhenDisabled}
      />
    </div>
  )
}

export default ArtiPopularTopicsSelect
