import cn from "classnames"
import { styled } from "styled-components"

import View from "ui/View"
import { getCustomKitTypeInfo } from "utils/kit"

const KitTypePillRevamp = styled(function KitTypePill({ className, selectedKitInfo }) {
  const kitType = selectedKitInfo?.type
  const customKitOrganization = selectedKitInfo?.custom_kit_organization
  if (!kitType || !customKitOrganization) {
    return null
  }

  const kitTypeInfo = getCustomKitTypeInfo(customKitOrganization)
  const customPillLogo = kitTypeInfo?.customPillLogo
  const pillText = kitTypeInfo.pillText
  const pillColorCss = kitTypeInfo.pillColorCss

  return (
    <View
      className={cn(className, "fit-content text-xs text-semi-bold border border-radius px-xs", pillColorCss)}
      $alignItems="center"
    >
      {!!customKitOrganization && <img src={customPillLogo} alt="custom-logo" className="xs-logo-icon mr-xs" />}
      <div className="text-nowrap">{pillText}</div>
    </View>
  )
})`
  background: rgb(254 122 0 / 3%);
`

export default KitTypePillRevamp
