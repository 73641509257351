import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

import api from "api"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useSelectedTeamId } from "ui/SelectedTeamContext"
import { checkNamedArguments } from "utils/function"
import { getRealtimeCacheKey, useRealtimeQuery } from "utils/query"
import { buildUrl } from "utils/string"

function getLatestTeamExerciseInstances({ teamId, exerciseSlug }: { teamId: TeamID; exerciseSlug: ExerciseSlug }) {
  checkNamedArguments("getLatestTeamExerciseInstances", arguments, { required: ["teamId", "exerciseSlug"] })
  return () =>
    api
      .get(`/teams/${teamId}/latest_team_exercise_instances_for_slug/?exercise_slug=${exerciseSlug}`)
      .then(({ data }) => data)
}

function useLatestTeamExerciseInstances({
  teamId,
  exerciseSlug,
  enabled = true,
  refetchInterval = false,
}: {
  teamId: TeamID
  exerciseSlug: ExerciseSlug
  enabled?: boolean
  refetchInterval?: number | false
}) {
  checkNamedArguments("useLatestTeamExerciseInstances", arguments, {
    required: ["teamId", "exerciseSlug"],
    optional: ["enabled", "refetchInterval"],
  })
  return useQuery(
    ["exercises", "team_instances", teamId, exerciseSlug, "latest"],
    getLatestTeamExerciseInstances({ teamId, exerciseSlug }),
    {
      enabled: !!enabled && !!teamId && !!exerciseSlug,
      refetchInterval,
    }
  )
}

function getLatestUserExerciseInstances({ teamId, userId }: { teamId: TeamID; userId: UserID }) {
  checkNamedArguments("getLatestUserExerciseInstances", arguments, { required: ["teamId", "userId"] })
  return () => api.get(`/teams/${teamId}/latest_user_exercise_instances/?user_id=${userId}`).then(({ data }) => data)
}

function useLatestUserExerciseInstances({
  teamId,
  userId,
  enabled = true,
}: {
  teamId: TeamID
  userId: UserID
  enabled?: boolean
}) {
  checkNamedArguments("useLatestUserExerciseInstances", arguments, {
    required: ["teamId", "userId"],
    optional: ["enabled"],
  })
  return useQuery(
    ["exercises", "user_instances", teamId, userId, "latest"],
    getLatestUserExerciseInstances({ teamId, userId }),
    { enabled: !!enabled && !!teamId && !!userId }
  )
}

function getSessionExerciseInstances({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getSessionExerciseInstances", arguments, { required: ["kitInstanceId"] })
  return () => api.get(`/monthly_kit/kit_instances/${kitInstanceId}/exercise_instances/`).then(({ data }) => data)
}

function useSessionExerciseInstances(
  kitInstanceId: KitInstanceID,
  {
    enabled = true,
    refetchInterval = null,
    sessionRealtimeUpdates = false,
  }: {
    enabled?: boolean
    refetchInterval?: number | null
    sessionRealtimeUpdates?: boolean
  } = {}
) {
  const sessionExerciseInstancesDataWithPolling = useQuery(
    getSessionExerciseInstancesCacheKey({ kitInstanceId }),
    getSessionExerciseInstances({ kitInstanceId }),
    {
      enabled: !!enabled && !!kitInstanceId && !sessionRealtimeUpdates,
      // @ts-ignore TODO: @shivam-risingteam update this
      refetchInterval,
      refetchIntervalInBackground: true,
    }
  )

  const sessionExerciseInstancesDataWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "exercise_answers" }),
    queryCacheKey: getSessionExerciseInstancesCacheKey({ kitInstanceId }),
    queryFn: getSessionExerciseInstances({ kitInstanceId }),
    enabled: !!enabled && !!kitInstanceId && !!sessionRealtimeUpdates,
  })

  if (!!sessionRealtimeUpdates) {
    return sessionExerciseInstancesDataWithoutPolling
  }
  return sessionExerciseInstancesDataWithPolling
}

// Use exercise instances tied to a session when in the session context,
// otherwise use exercise instances for the whole team
function useSessionOrTeamExerciseInstances(
  slug: ExerciseSlug,
  {
    refetchInterval,
    enabled,
    sessionRealtimeUpdates,
  }: {
    refetchInterval?: number | boolean
    enabled?: boolean
    sessionRealtimeUpdates?: boolean
  } = {
    refetchInterval: false,
    enabled: true,
    sessionRealtimeUpdates: false,
  }
) {
  const { kitInstance } = useKitSession()
  const { selectedTeamId } = useSelectedTeamId()
  // @ts-ignore TODO: @shivam-risingteam update this
  const sessionExerciseInstancesData = useSessionExerciseInstances(kitInstance?.id, {
    enabled: !!enabled && !!kitInstance,
    // @ts-ignore TODO: @shivam-risingteam update this
    refetchInterval,
    sessionRealtimeUpdates,
  })
  const teamExerciseInstancesData = useLatestTeamExerciseInstances({
    // @ts-ignore TODO: @shivam-risingteam update this
    teamId: selectedTeamId,
    exerciseSlug: slug,
    enabled: !!enabled && !kitInstance,
    // @ts-ignore TODO: @shivam-risingteam update this
    refetchInterval,
  })

  if (kitInstance) {
    return sessionExerciseInstancesData
  } else {
    return teamExerciseInstancesData
  }
}

function getSessionExerciseInstancesCacheKey({ kitInstanceId }: { kitInstanceId: KitInstanceID }) {
  checkNamedArguments("getSessionExerciseInstancesCacheKey", arguments, { required: ["kitInstanceId"] })
  // For some reason using the full ['monthly_kit', ...] cache key briefly creates a conflict,
  // returning the kit instance rather than the exercises
  return ["kit_instances", kitInstanceId, "exercise_instances"]
}

function getExerciseInstanceCacheKey({
  teamId,
  slug,
  teamLevelExercise,
}: {
  teamId: TeamID
  slug: ExerciseSlug
  teamLevelExercise: boolean
}) {
  checkNamedArguments("getExerciseInstanceCacheKey", arguments, {
    required: ["teamId", "slug", "teamLevelExercise"],
  })
  return ["exercises", "instance", teamId, slug, !!teamLevelExercise]
}

function getExerciseInstanceCacheKeyFromExerciseInstance(exerciseInstance: ExerciseInstanceData) {
  return getExerciseInstanceCacheKey({
    teamId: exerciseInstance.team_id,
    slug: exerciseInstance.slug,
    teamLevelExercise: exerciseInstance.is_team_level_exercise,
  })
}

function getExerciseInstance({ teamId, slug }: { teamId: TeamID; slug: ExerciseSlug }) {
  checkNamedArguments("getExerciseInstance", arguments, { required: ["teamId", "slug"] })
  return () =>
    api.get(`/teams/${teamId}/latest_user_exercise_instance_for_slug/`, { params: { slug } }).then(({ data }) => data)
}
function useExerciseInstance({ teamId, slug }: { teamId: TeamID; slug: ExerciseSlug }) {
  checkNamedArguments("useExerciseInstance", arguments, { required: ["teamId", "slug"] })
  return useQuery(
    getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise: false }),
    getExerciseInstance({ teamId, slug }),
    {
      enabled: !!(teamId && slug),
    }
  )
}

function getTeamLevelExerciseInstance({ teamId, slug }: { teamId: TeamID; slug: ExerciseSlug }) {
  checkNamedArguments("getTeamLevelExerciseInstance", arguments, { required: ["teamId", "slug"] })
  return () =>
    api
      .get(`/teams/${teamId}/latest_team_level_exercise_instance_for_slug/`, { params: { slug } })
      .then(({ data }) => data)
}
function useTeamLevelExerciseInstance({
  teamId,
  slug,
  kitInstanceId = null,
  refetchInterval = null,
  enabled = true,
  sessionRealtimeUpdates = false,
}: {
  teamId: TeamID
  kitInstanceId: KitInstanceID | null
  slug: ExerciseSlug
  refetchInterval?: number | boolean | null //check this
  enabled?: boolean
  sessionRealtimeUpdates?: boolean
}) {
  checkNamedArguments("useTeamLevelExerciseInstance", arguments, {
    required: ["teamId", "slug"],
    optional: ["kitInstanceId", "refetchInterval", "enabled", "sessionRealtimeUpdates"],
  })
  const teamExerciseInstanceDataWithPolling = useQuery(
    getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise: true }),
    getTeamLevelExerciseInstance({ teamId, slug }),
    {
      // @ts-ignore TODO: @shivam-risingteam update this
      refetchInterval,
      enabled: !!enabled && !!teamId && !!slug && !sessionRealtimeUpdates,
    }
  )

  const teamExerciseInstanceDataWithoutPolling = useRealtimeQuery({
    realtimeCacheKey: getRealtimeCacheKey({ kitInstanceId, key: "team_exercise_answers" }),
    queryCacheKey: getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise: true }),
    queryFn: getTeamLevelExerciseInstance({ teamId, slug }),
    enabled: !!enabled && !!teamId && !!slug && !!sessionRealtimeUpdates,
  })
  if (!!sessionRealtimeUpdates) {
    return teamExerciseInstanceDataWithoutPolling
  }
  return teamExerciseInstanceDataWithPolling
}

function getOrCreateExerciseInstance({
  teamId,
  slug,
  version,
  teamLevelExercise,
}: {
  teamId: TeamID
  slug: ExerciseSlug
  version: ExerciseVersion
  teamLevelExercise: boolean
}) {
  checkNamedArguments("getOrCreateExerciseInstance", arguments, {
    required: ["teamId", "slug", "version", "teamLevelExercise"],
  })
  return () =>
    api
      .post(`/teams/${teamId}/get_or_create_latest_exercise_instance/`, { slug, version, teamLevelExercise })
      .then(({ data }) => data)
}
function useOrCreateExerciseInstance({
  teamId,
  slug,
  version,
  teamLevelExercise = false,
}: {
  teamId: TeamID
  slug: ExerciseSlug
  version: ExerciseVersion
  teamLevelExercise?: boolean
}) {
  checkNamedArguments("useOrCreateExerciseInstance", arguments, {
    required: ["teamId", "slug", "version"],
    optional: ["teamLevelExercise"],
  })
  return useQuery(
    getExerciseInstanceCacheKey({ teamId, slug, teamLevelExercise }),
    getOrCreateExerciseInstance({ teamId, slug, version, teamLevelExercise }),
    { enabled: !!teamId && !!slug && !!version }
  )
}

function getExerciseDefinition({
  teamId,
  slug,
  version,
}: {
  teamId: TeamID
  slug: ExerciseSlug
  version: ExerciseVersion
}) {
  checkNamedArguments("getExerciseDefinition", arguments, { required: ["teamId", "slug", "version"] })
  return () =>
    api.get(`/teams/${teamId}/get_exercise_definition/`, { params: { slug, version } }).then(({ data }) => data)
}
function useExerciseDefinition({
  teamId,
  slug,
  version,
}: {
  teamId: TeamID
  slug: ExerciseSlug
  version: ExerciseVersion
}) {
  checkNamedArguments("useExerciseDefinition", arguments, { required: ["teamId", "slug", "version"] })
  return useQuery(
    ["exercises", "definition", teamId, slug, version],
    getExerciseDefinition({ teamId, slug, version }),
    { enabled: !!teamId && !!slug && !!version }
  )
}

function updateExerciseInstance(id: ExerciseInstanceID) {
  return (values: ExerciseInstanceData) => api.patch(`/exercises/instances/${id}/`, values).then(({ data }) => data)
}
function useUpdateExerciseInstance(id: ExerciseInstanceID) {
  const queryClient = useQueryClient()
  return useMutation(updateExerciseInstance(id), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

function postExerciseAnswer(id: ExerciseInstanceID) {
  return (values: ExerciseAnswerData) =>
    api.post(`/exercises/instances/${id}/answers/`, values).then(({ data }) => data)
}
function useMutateExerciseAnswer(id: ExerciseInstanceID) {
  const queryClient = useQueryClient()
  return useMutation(postExerciseAnswer(id), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

async function shareExerciseResults(id: ExerciseInstanceID) {
  const data = await api.post(`/exercises/instances/${id}/share_exercise_results/`)
  return data
}
function useShareExerciseResults() {
  return useMutation(shareExerciseResults)
}

function getSharedExerciseInstance(params: { share_code: string }) {
  return () => api.get("/exercises/instances/get_shared_exercise_instance/", { params }).then(({ data }) => data)
}
function useSharedExerciseInstance(params: { share_code: string }) {
  return useQuery(["share_code", params], getSharedExerciseInstance(params))
}

function updateExerciseAnswerImage(exerciseAnswerId: ExerciseInstanceID) {
  return async function ({ identifier, file }: { identifier: string; file: File }) {
    const formData = new FormData()
    formData.append("exercise_answer_image", file)
    formData.append("identifier", identifier)
    const headers = {
      "Content-Type": "multipart/form-data",
    }

    const { data } = await api.post(`/exercises/instances/${exerciseAnswerId}/update_answer_image/`, formData, {
      headers,
    })
    return data
  }
}

function useUpdateExerciseAnswerImage(exerciseAnswerId: ExerciseInstanceID) {
  const queryClient = useQueryClient()
  return useMutation(updateExerciseAnswerImage(exerciseAnswerId), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

function updateExerciseAnswerImageURL(exerciseAnswerId: ExerciseInstanceID) {
  return async function ({ identifier, object_key }: { identifier: string; object_key: string }) {
    const { data } = await api.post(`/exercises/instances/${exerciseAnswerId}/update_answer_image_url/`, {
      object_key,
      identifier,
    })
    return data
  }
}

function useUpdateExerciseAnswerImageURL(exerciseAnswerId: ExerciseInstanceID) {
  const queryClient = useQueryClient()
  return useMutation(updateExerciseAnswerImageURL(exerciseAnswerId), {
    onSuccess: (data) => {
      queryClient.setQueryData(getExerciseInstanceCacheKeyFromExerciseInstance(data), data)
    },
  })
}

function deleteExerciseAnswer(exerciseInstanceId: ExerciseInstanceID) {
  return async function ({ identifier }: { identifier: string }) {
    const { data } = await api.delete(
      buildUrl(["exercises", "instances", exerciseInstanceId, "delete_user_exercise_instance_for_slug"]),
      {
        data: { identifier },
      }
    )
    return data
  }
}

// TODO @shivam-risingteam: The correct params are not being passed here. Fix this cache key
function useDeleteExerciseAnswer({ exerciseInstanceId }: { exerciseInstanceId: ExerciseInstanceID }) {
  checkNamedArguments("useDeleteExerciseAnswer", arguments, { required: ["exerciseInstanceId"] })
  const queryClient = useQueryClient()
  return useMutation(deleteExerciseAnswer(exerciseInstanceId), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(getExerciseInstanceCacheKeyFromExerciseInstance(data))
    },
  })
}

async function triggerStandaloneExerciseCompleted(exerciseInstanceId: ExerciseInstanceID) {
  await api.post(`/exercises/instances/${exerciseInstanceId}/trigger_standalone_exercise_completed/`)
}

export {
  useDeleteExerciseAnswer,
  useLatestTeamExerciseInstances,
  useLatestUserExerciseInstances,
  useSessionExerciseInstances,
  useSessionOrTeamExerciseInstances,
  useExerciseInstance,
  useTeamLevelExerciseInstance,
  useOrCreateExerciseInstance,
  useUpdateExerciseInstance,
  useMutateExerciseAnswer,
  useShareExerciseResults,
  useSharedExerciseInstance,
  useUpdateExerciseAnswerImage,
  triggerStandaloneExerciseCompleted,
  useExerciseDefinition,
  useUpdateExerciseAnswerImageURL,
}
