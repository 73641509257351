import { KIT_STATUS } from "domains/LeaderKit/KitsTableofContents/utils"
import { CircleCheckIcon, CircleHalfStrokeIcon } from "icons/FontAwesomeIcons"

const KIT_STATUS_ICON = {
  COMPLETE: <CircleCheckIcon color="var(--green-3)" className="fa-lg mt-large mb-small" />,
  IN_PROGRESS: <CircleHalfStrokeIcon color="var(--gray-8)" className="fa-lg mt-large mb-small" />,
}

const MINI_SUB_TYPE = {
  CONNECT: "connect",
  PLAY: "play",
  UNDERSTAND: "understand",
  GAME: "game",
}

const MINI_FILTER_KEYS = {
  AVAILABLE: "available",
  COMPLETED: "completed",
  IN_PROGRESS: "in_progress",
  MINI_KITS: "mini_kits",
  ALL_SUBTYPES: "all_subtypes",
  CONNECT: "connect",
  PLAY: "play",
  UNDERSTAND: "understand",
}

const getMiniSubTypeContent = (subType) => {
  if (subType === MINI_SUB_TYPE.CONNECT) {
    return {
      title: "Connect",
      tileBackground: "background-linear-gradient-connect",
      subTypeBackground: "bg-yellow-tint",
    }
  } else if (subType === MINI_SUB_TYPE.PLAY || subType === MINI_SUB_TYPE.GAME) {
    return {
      title: "Play",
      tileBackground: "background-linear-gradient-play",
      subTypeBackground: "bg-green-tint",
    }
  } else if (subType === MINI_SUB_TYPE.UNDERSTAND) {
    return {
      title: "Understand",
      tileBackground: "background-linear-gradient-understand",
      subTypeBackground: "bg-orange-tint",
    }
  }
}

const getSubTypeFilter = (subType) => {
  switch (subType) {
    case MINI_SUB_TYPE.CONNECT:
      return MINI_FILTER_KEYS.CONNECT
    case MINI_SUB_TYPE.UNDERSTAND:
      return MINI_FILTER_KEYS.UNDERSTAND
    case MINI_SUB_TYPE.PLAY:
    case MINI_SUB_TYPE.GAME:
      return MINI_FILTER_KEYS.PLAY
    default:
      return MINI_FILTER_KEYS.ALL_SUBTYPES
  }
}

const getMiniKitStatusIcon = (status) =>
  status === KIT_STATUS.COMPLETE
    ? KIT_STATUS_ICON.COMPLETE
    : status === KIT_STATUS.IN_PROGRESS
      ? KIT_STATUS_ICON.IN_PROGRESS
      : null

export {
  KIT_STATUS_ICON,
  MINI_SUB_TYPE,
  MINI_FILTER_KEYS,
  getSubTypeFilter,
  getMiniKitStatusIcon,
  getMiniSubTypeContent,
}
