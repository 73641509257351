import * as Sentry from "@sentry/browser"
import { Formik, Form, useFormikContext } from "formik"
import { styled } from "styled-components"

import { fetchS3Post } from "api"
import ImageField from "forms/fields/ImageField"
import handleErrors from "forms/handleErrors"
import { CameraIcon, CloudArrowUpIcon } from "icons/FontAwesomeIcons"
import { useUpdateSessionPicture, useUpdateSessionPictureURL, useSessionPicture } from "resources/monthly_kit"
import { useTeam } from "resources/teams"
import { getAWSImageUploadURL } from "resources/users"
import Button from "ui/Button"
import DragAndDrop from "ui/DragAndDrop"
import useFeatures, { FLAGS, SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import View from "ui/View"
import {
  resizeImage,
  getImageExtension,
  SUPPORTED_RESIZING_IMAGE_TYPES,
  SUPPORTED_IMAGE_TYPES,
  ACCEPTABLE_UPLOAD_TYPES,
} from "utils/image"
import { useHasTeamFeature } from "utils/team"

const MAX_IMAGE_SIZE_MB = 8

const getFieldId = (kitInstance) => `session_picture_${kitInstance.id}`

const SessionImage = ({ className, kitInstance, enableRefetch = true }) => (
  <Formik initialValues={{ [getFieldId(kitInstance)]: "" }} className="mt-medium">
    <Form className={className}>
      <SessionImageUploadField kitInstance={kitInstance} enableRefetch={!!enableRefetch} />
    </Form>
  </Formik>
)

const SessionImageUploadField = ({ kitInstance, enableRefetch }) => {
  const { data: team } = useTeam({ teamId: kitInstance.team_id })
  const { [FLAGS.RTDEV_UPLOAD_IMAGE_DIRECTLY_AWS]: directlyUploadImage } = useFeatures()
  const { enabled: imageResizing } = useHasTeamFeature(team, SHARED_FLAGS.IMAGE_RESIZING)
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: sessionPicture } = useSessionPicture({
    kitInstanceId: kitInstance.id,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
    ...(enableRefetch ? { refetchInterval: 3000 } : {}),
  })
  const formik = useFormikContext()
  const { mutateAsync: updateSessionPicture, status: uploadStatusDeprecated } = useUpdateSessionPicture({
    kitInstanceId: kitInstance.id,
  })
  const { mutateAsync: updateSessionPictureURL, status: uploadStatusURL } = useUpdateSessionPictureURL({
    kitInstanceId: kitInstance.id,
  })

  const uploadFile = async (file) => {
    // For some reason Formik doesn't automatically set this on image fields,
    // but it's needed for error messages to display
    formik.setFieldTouched(getFieldId(kitInstance), true, false)

    if (!file) {
      formik.setErrors({ [getFieldId(kitInstance)]: "Image upload failed, please try again" })
      return
    }
    const imageExtension = getImageExtension({ file })
    if (!SUPPORTED_IMAGE_TYPES.has(imageExtension)) {
      formik.setErrors({ [getFieldId(kitInstance)]: `Image type must be one of: ${SUPPORTED_IMAGE_TYPES.join(", ")}` })
      return
    }
    const image =
      imageResizing && SUPPORTED_RESIZING_IMAGE_TYPES.includes(imageExtension) ? await resizeImage({ file }) : file
    if (image.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      formik.setErrors({ [getFieldId(kitInstance)]: `Image size must be under ${MAX_IMAGE_SIZE_MB}mb` })
      return
    }

    if (directlyUploadImage) {
      const { aws_presigned_data: presignedUploadData, object_key: objectKeyAWS } = await getAWSImageUploadURL({
        userId: "me",
        imageExtension,
        kitInstanceId: kitInstance?.id,
      })
      fetchS3Post(presignedUploadData, image)
        .then(async (response) => {
          if (response.ok) {
            await updateSessionPictureURL({ object_key: objectKeyAWS })
          } else {
            Sentry.captureException(response)
          }
        })
        .catch((error) => {
          Sentry.captureException(error)
        })
    } else {
      await updateSessionPicture(image)
    }
    formik.setFieldTouched(getFieldId(kitInstance), false)
  }

  const onChange = handleErrors(({ target: { files } }) => uploadFile(files[0]), null, formik)

  const onDropHandler = (files) => uploadFile(files[0])

  const uploadStatus = !!directlyUploadImage ? uploadStatusURL : uploadStatusDeprecated

  return uploadStatus === "loading" ? (
    <Loading />
  ) : (
    <DragAndDrop onDropHandler={onDropHandler}>
      <View $flexDirection="column" $justifyContent="center" $alignItemsMobile="center">
        {!sessionPicture ? (
          <View className="photo-dropzone border-radius-small" $justifyContent="center" $alignItems="center">
            <View className="text-blue-2" $flexDirection="column" $justifyContent="center" $alignItems="center">
              <CloudArrowUpIcon className="fa-3x" />
              <p className="text-bold mb-small">Drag & drop your photo here or</p>
              <Button
                as="label"
                className="tertiary cursor-pointer img-upload-button"
                htmlFor={getFieldId(kitInstance)}
              >
                Browse Files
              </Button>
            </View>
          </View>
        ) : (
          <View className="team-picture">
            <View $flexDirection="column">
              <img src={sessionPicture} alt="Team" />
            </View>
            <label
              htmlFor={getFieldId(kitInstance)}
              className="team-picture-button text-gray-9 border-radius-medium px-small"
            >
              <CameraIcon className="fa-lg cursor-pointer img-upload-button" />
            </label>
          </View>
        )}
        <ImageField
          id={getFieldId(kitInstance)}
          name={getFieldId(kitInstance)}
          hidden
          onChange={onChange}
          accept={ACCEPTABLE_UPLOAD_TYPES}
        />
      </View>
    </DragAndDrop>
  )
}

export default styled(SessionImage)`
  .photo-dropzone {
    width: ${({ kitInstance }) => (!kitInstance.session_completed_at ? "377px" : "350px")};
    height: 212px;
    background: rgb(10 132 255 / 10%);
    border: 1px dashed var(--rising-blue);
  }

  label[for="${({ kitInstance }) => getFieldId(kitInstance)}"] .field-message {
    width: 100%;
    text-align: center;
  }

  .team-picture {
    position: relative;
    width: auto;

    img {
      max-width: 760px;
      max-height: 400px;
      object-fit: contain;
    }
  }

  .team-picture-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--fg);
    box-shadow: var(--lift-4);
  }

  .img-upload-button {
    z-index: var(--z-above-zero);
  }
`
