import { useQueryClient } from "@tanstack/react-query"
import { useCallback, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"

import { getBillingData } from "../Register/BillingDataContext"

import AdminOnboardingProfilePage from "./AdminOnboardingProfilePage"
import OnboardingProfilePage from "./OnboardingProfilePage"
import SchoolOnboardingProfilePage from "./SchoolOnboardingProfilePage"

import { useIsSchoolAccountType, useSetAccountTypeByBillingData } from "components/AccountTypeContext"
import handleErrors from "forms/handleErrors"
import {
  createBillingAccount,
  createCheckoutSessionAndRedirect,
  createGratitudePromoAccount,
  useOwnedAccount,
} from "resources/billing"
import { MARKETING_PRICING_URL } from "resources/get_started"
import { useUpdateCurrentUser } from "resources/users"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useFeatures, { FLAGS } from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import MailToLink from "ui/MailToLink"

const Onboarding = ({ user, className }) => {
  const features = useFeatures()
  const queryClient = useQueryClient()
  const { mutateAsync: updateCurrentUser } = useUpdateCurrentUser()
  const queryParams = useQueryParams()
  const { subscription, quantity } = queryParams
  const { data: ownedAccountOrNull, isFetching: ownedAccountIsFetching } = useOwnedAccount()
  const billingData = getBillingData({ subscription, quantity, queryParams })
  useSetAccountTypeByBillingData(billingData)
  const navigate = useNavigate()
  const isSchool = useIsSchoolAccountType()
  const isPurchasing = subscription && quantity
  const [hasError, setHasError] = useState(false)

  const handleIsPurchasingBillingRedirect = async () => {
    try {
      const ownedAccount = ownedAccountOrNull ?? (await createBillingAccount())
      if (ownedAccount.is_subscription_active) {
        // TODO(onboarding-launch): remove if statement
        if (features[FLAGS.RTDEV_ONBOARDING]) {
          navigate("/")
        } else {
          navigate("/get-started/create-account/welcome")
        }
      } else if (billingData.gratitudePromo) {
        await createGratitudePromoAccount(ownedAccount.id, queryClient)
        navigate("/kit/gratitude")
      } else {
        let checkoutCancelUrl
        // TODO(onboarding-launch): remove if statement
        if (features[FLAGS.RTDEV_ONBOARDING]) {
          checkoutCancelUrl = `${window.location.origin}/get-started/sub/${subscription}/${quantity}`
        } else {
          checkoutCancelUrl = window.location.href
        }
        await createCheckoutSessionAndRedirect({
          billingData,
          billingAccountId: ownedAccount.id,
          checkoutCancelUrl,
        })
      }
    } catch (error) {
      setHasError(true)
    }
  }

  const onSubmit = handleErrors(async (values) => {
    await updateCurrentUser({
      profile: values,
      team_lead_onboarding_complete: true,
    })

    if (isPurchasing) {
      await handleIsPurchasingBillingRedirect()
    } else {
      // TODO(onboarding-launch): remove if statement
      if (features[FLAGS.RTDEV_ONBOARDING]) {
        navigate("/")
      } else {
        navigate("/get-started/create-account/welcome")
      }
    }
  })

  // TODO(onboarding-launch): this flow needs cleaning up once we launch new onboarding
  // Currently, we redirect to purchase flow immediately if new-onboarding is active.
  const purchaseCallback = useCallback(handleIsPurchasingBillingRedirect, [
    billingData,
    features,
    navigate,
    ownedAccountOrNull,
    queryClient,
    quantity,
    subscription,
  ])
  useEffectAfterChange(() => {
    if (!ownedAccountIsFetching && features[FLAGS.RTDEV_ONBOARDING]) {
      if (isPurchasing) {
        purchaseCallback()
      } else {
        navigate("/")
      }
    }
  }, [ownedAccountIsFetching, features, isPurchasing, purchaseCallback, navigate])
  if (!ownedAccountIsFetching && features[FLAGS.RTDEV_ONBOARDING]) {
    if (hasError) {
      return (
        <div>
          There was an error processing your request. Please try again by clicking{" "}
          <a href={MARKETING_PRICING_URL}>here</a> or contact us at <MailToLink email="support@risingteam.com" />
        </div>
      )
    } else if (isPurchasing) {
      return <Loading />
    } else if (!user.has_an_active_account) {
      return <Navigate to="/select-or-update-payment" replace />
    } else {
      return <Navigate to="/" replace />
    }
  }

  if (!isPurchasing && !user.has_an_active_account) {
    return <Navigate to="/select-or-update-payment" replace />
  }

  // Onboarding is only for purchase flow or team lead registration
  if (!isPurchasing && !user.is_a_team_lead) {
    return <Navigate to="/" replace />
  }

  if (ownedAccountIsFetching) {
    return null
  }

  if (isSchool) {
    return (
      <SchoolOnboardingProfilePage
        user={user}
        headerText="Tell us about yourself"
        subtext="To help us design your experience, tell us more about you and your team"
        onSubmit={onSubmit}
        className={className}
      />
    )
  }

  if (quantity > 1) {
    return (
      <AdminOnboardingProfilePage
        user={user}
        headerText="Tell us about yourself"
        subtext="To help us design your experience, tell us more about you and your team"
        onSubmit={onSubmit}
        className={className}
      />
    )
  }

  return (
    <OnboardingProfilePage
      user={user}
      headerText="Tell us about yourself"
      subtext="To help us design your experience, tell us more about you and your team"
      onSubmit={onSubmit}
      className={className}
    />
  )
}

export default Onboarding
