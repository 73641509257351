import { get } from "lodash-es"

import { getExerciseAnswer } from "../results_utils"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useSessionOrTeamExerciseInstances } from "resources/exercise"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useHasTeamFeature } from "utils/team"

const TextFieldValueReferenceText = ({ identifier, fallbackText, exerciseInstance }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { data: exerciseInstances = [], isInitialLoading } = useSessionOrTeamExerciseInstances(exerciseInstance.slug, {
    refetchInterval: 30000,
    sessionRealtimeUpdates,
  })

  if (!exerciseInstance || isInitialLoading) {
    return null
  }

  const referenceAnswerId = parseInt(getExerciseAnswer(exerciseInstance, identifier))
  const textFieldAnswer = exerciseInstances
    .flatMap((instance) => instance.answers)
    .find((answer) => answer.id === referenceAnswerId)?.data

  return get(textFieldAnswer, ["value"], textFieldAnswer) ?? fallbackText
}

export default TextFieldValueReferenceText
