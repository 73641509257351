import { useKitSession } from "../KitSessionContext"

import { IfAnswersExist, IfAnswersDoNotExist } from "domains/Exercise/ResultsBlocks/IfAnswersExist"
import { useTeamLevelExerciseInstance } from "resources/exercise"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const IfTeamLevelExerciseAnswerExistsOrNotContainer = ({ kitInstance, identifier, checkDoesNotExist, children }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesFlagActive } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE
  )
  const sessionRealtimeUpdates = !!sessionRealtimeUpdatesFlagActive && !!kitInstance && !!team
  const { data: exerciseInstance, isFetching } = useTeamLevelExerciseInstance({
    teamId: kitInstance.team_id,
    kitInstanceId: kitInstance?.id,
    slug: kitInstance.kit.exercise.slug,
    sessionRealtimeUpdates,
  })

  if (!exerciseInstance && isFetching) {
    return <Loading />
  }

  if (!exerciseInstance) {
    return null
  }

  const AnswerComponent = checkDoesNotExist ? IfAnswersDoNotExist : IfAnswersExist

  return (
    <AnswerComponent identifiers={[identifier]} exerciseInstance={exerciseInstance}>
      {children}
    </AnswerComponent>
  )
}

export default IfTeamLevelExerciseAnswerExistsOrNotContainer
