import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

import api, { auth } from "api"
import { authCacheKey } from "domains/Authentication/resource"
import { featuresCacheKey } from "ui/hooks/useFeatures"

const MARKETING_URL = "https://risingteam.com"
const MARKETING_PRICING_URL = MARKETING_URL + "/pricing#prices"

async function checkUserEmail(email: string) {
  const { data } = await auth.post("/register/check_email/", { email })
  return data
}

async function register(values: RTUser) {
  const { data } = await auth.post("/register/", values)
  return data
}

function useRegister() {
  const queryClient = useQueryClient()
  return useMutation(register, {
    onSuccess: (data) => {
      queryClient.setQueryData(authCacheKey, data)
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      window.heap.identify(data.id)
    },
  })
}

async function registerInvited(values: RTUser) {
  const { data } = await auth.post("/register_invited/", values)
  return data
}

function useRegisterInvited() {
  const queryClient = useQueryClient()
  return useMutation(registerInvited, {
    onSuccess: (data) => {
      queryClient.setQueryData(authCacheKey, data)
      queryClient.removeQueries({ queryKey: featuresCacheKey, exact: true })
      window.heap.identify(data.id)
    },
  })
}

async function verifyToken({ uid, token }: { uid: string; token: string }) {
  const { data } = await auth.post("/verify_invite_link/", { uid, token })
  return data
}

function useVerifyToken({ uid, token }: { uid: string; token: string }) {
  return useQuery(["verify_invite_link"], () => verifyToken({ uid, token }))
}

async function getPromo(code: string | null) {
  const { data } = await api.get(`/billing/promo/?code=${code}`)
  return data
}

function usePromo(code: string | null) {
  return useQuery(["billing", "promo", code], () => getPromo(code), { enabled: !!code })
}

async function createShrmVolunteerSession() {
  const { data } = await api.post("/billing/shrm_volunteer/")
  return data
}

function useCreateShrmVolunteerSession() {
  return useMutation(createShrmVolunteerSession)
}

export {
  MARKETING_URL,
  MARKETING_PRICING_URL,
  checkUserEmail,
  register,
  useRegister,
  registerInvited,
  useRegisterInvited,
  useVerifyToken,
  usePromo,
  useCreateShrmVolunteerSession,
}
