import cn from "classnames"
import { useField } from "formik"
import { styled } from "styled-components"

import ExercisePieSliceFieldBody from "./ExercisePieSliceFieldBody"

import { formatExercisePieChartData } from "domains/Exercise/results_utils"
import useWindowSize from "ui/hooks/useWindowSize"
import PieSliceChart from "ui/PieSliceChart"
import { getRepeatingColorFunc } from "ui/theme"
import View from "ui/View"
import { plural } from "utils/string"

const ExercisePieSliceGroupFieldBody = ({
  saveOnChange,
  name: groupName,
  items,
  maxSlices,
  exerciseComponentNameMap,
  className,
  colors,
}) => {
  const { isTabletOrSmaller, isDesktopOrLarger } = useWindowSize()
  const [{ value: sliceValues = {} }] = useField(groupName)
  const [{ value: otherValue = {} }] = useField(`${groupName}_other`)

  // Data is mapped from items to ensure the chart:
  // 1. Only includes data still in the definition
  // 2. Matches the order specified in the definition
  const selectedItems = items.filter(({ identifier }) => sliceValues[identifier] > 0)
  const data = formatExercisePieChartData(selectedItems, sliceValues, otherValue)

  const keys = data.map(({ id }) => id)
  const getColor = getRepeatingColorFunc(keys, colors, { sortKeys: false })

  const sum = Object.values(sliceValues).reduce((sum, v) => sum + (v ?? 0), 0)
  const availableSlices = Math.max(0, maxSlices - sum)

  const onChangeItem = (fieldName, value) => {
    const itemName = fieldName.split(".")[1]
    const newSliceValues = {
      ...sliceValues,
      [itemName]: value,
    }
    saveOnChange(groupName, newSliceValues)
  }

  return (
    <View className={className} $flexDirectionTablet="column" $alignItems="center">
      <View $flexDirection="column" $flex={1}>
        {items.map((item) => {
          const id = `${groupName}.${item.identifier}`
          const CustomFieldComponent = exerciseComponentNameMap[item.custom_field?.component]
          return (
            <ExercisePieSliceFieldBody
              key={id}
              id={id}
              sentence={item.sentence}
              customField={item.custom_field}
              CustomFieldComponent={CustomFieldComponent}
              saveOnChange={onChangeItem}
              customSaveOnChange={saveOnChange}
              availableSlices={availableSlices}
              color={getColor({ id: item.identifier })}
            />
          )
        })}
      </View>
      {!!isTabletOrSmaller && (
        <SliceCounter availableSlices={availableSlices} className="border-top border-gray-5 my-small sticky-bottom" />
      )}
      <View $flexDirection="column" $flex={1} $justifyContent="center" $alignItems="center">
        {!!isDesktopOrLarger && <SliceCounter availableSlices={availableSlices} />}
        <PieSliceChart data={data} availableSlices={availableSlices} colors={getColor} enableArcLabels={false} />
      </View>
    </View>
  )
}

const SliceCounter = styled(function SliceCounter({ availableSlices, className }) {
  return <div className={cn("text-semi-bold", className)}>{plural(availableSlices, "Vote")} Left</div>
})`
  width: 100%;
  padding: var(--spacing-1) 0 var(--spacing-3) 0;
  text-align: center;
  color: var(--rising-orange);
  background-color: var(--fg);
`

export default ExercisePieSliceGroupFieldBody
