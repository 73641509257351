import Resizer from "react-image-file-resizer"

import { checkNamedArguments } from "utils/function"

const SUPPORTED_RESIZING_IMAGE_TYPES = ["JPEG", "PNG", "WEBP"]

// Note: Keep in sync with backend/risingteam/views/view_utils.py
const SUPPORTED_IMAGE_TYPES = new Set([
  "jpg",
  "jpeg",
  "apng",
  "png",
  "gif",
  "bmp",
  "tiff",
  "tif",
  "webp",
  "ico",
  "avif",
])
const ACCEPTABLE_UPLOAD_TYPES = Array.from(SUPPORTED_IMAGE_TYPES)
  .map((type) => `.${type}`)
  .join(",")

function getImageExtension({ file }: { file: File }): string {
  checkNamedArguments("getImageExtension", arguments, { required: ["file"] })
  return file?.name.split(".")?.pop()?.toLowerCase() || ""
}

// client side image resizer
function resizeImage({ file }: { file: File }): Promise<string | File | Blob | ProgressEvent<FileReader>> {
  checkNamedArguments("resizeImage", arguments, { required: ["file"] })
  const imageExtension = getImageExtension({ file })
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1920, // maxWidth
      1080, // maxHeight
      imageExtension, // compressFormat
      100, // quality (set to 100 if no compression for jpeg)
      0, // rotation (set to 0 for no rotation)
      resolve,
      "file" // outputType
    )
  })
}

export {
  resizeImage,
  getImageExtension,
  SUPPORTED_RESIZING_IMAGE_TYPES,
  SUPPORTED_IMAGE_TYPES,
  ACCEPTABLE_UPLOAD_TYPES,
}
