import { getExerciseAnswer } from "../results_utils"

import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useSessionOrTeamExerciseInstances } from "resources/exercise"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import InputRadio from "ui/InputRadio"
import PurifyInnerHTMLDiv from "ui/PurifyInnerHTMLDiv"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const RadioGroupReadOnlyField = ({
  identifier,
  exerciseComponent,
  exerciseInstance,
  isValueReferenceAnswer,
  optionExerciseFieldId,
}) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { data: exerciseInstances = [], isInitialLoading } = useSessionOrTeamExerciseInstances(exerciseInstance.slug, {
    refetchInterval: 30_000,
    sessionRealtimeUpdates,
  })

  if ((optionExerciseFieldId || isValueReferenceAnswer) && isInitialLoading) {
    return null
  }

  const exerciseFieldOptions = exerciseInstances
    .map(({ answers }) => answers.find(({ identifier }) => identifier === optionExerciseFieldId))
    .filter((answer) => answer?.data?.value)
    .map(({ data }) => data)
    .map(({ value }) => ({ value, label: value }))

  if (optionExerciseFieldId && !exerciseFieldOptions.length) {
    return <p className="text-gray-8 text-italic">No response given.</p>
  }

  const referenceAnswerId = parseInt(getExerciseAnswer(exerciseInstance, identifier))
  let referenceAnswer = null
  exerciseInstances.find(({ answers }) => {
    referenceAnswer = answers.find(({ id }) => id === referenceAnswerId)?.data.value ?? null
    return referenceAnswer
  })

  const choiceOptions = optionExerciseFieldId ? exerciseFieldOptions : exerciseComponent.options
  const userSelections = isValueReferenceAnswer
    ? referenceAnswer
    : (getExerciseAnswer(exerciseInstance, identifier) ?? [])

  return (
    <div className="space-y-small mb-xl">
      {choiceOptions.map(({ value, label }) => (
        <View $alignItems="center" as="label" key={value}>
          <InputRadio disabled type="radio" className="ml-none mr-small" checked={userSelections.includes(value)} />
          <PurifyInnerHTMLDiv className="text-small">{label}</PurifyInnerHTMLDiv>
        </View>
      ))}
    </div>
  )
}

export default RadioGroupReadOnlyField
