import { useQuery } from "@tanstack/react-query"

import { root } from "../../api"

const FLAGS = {
  DEMO_MODE: "demo_mode",
  RT_ONLY: "rt_only",
  REOPEN_SESSION: "reopen_session",
  SUPERUSER_REPORTS: "superuser_reports",
  ADMIN_EXTENDED_FEATURES: "admin_extended_features",
  SUPERUSER_MANAGE_ALL_ACCOUNTS: "superuser_manage_all_accounts",
  ALLOW_TEAM_LEADS_TO_ADD_TEAMS: "allow_team_leads_to_add_teams",
  ONLY_ADMINS_CAN_CREATE_TEAMS: "only_admins_can_create_teams",
  TAG_MANAGEMENT: "tag_management",
  MULTI_ACCOUNT_REPORTING: "multi_account_reporting",
  SESSION_FEEDBACK_AI_SUMMARY: "session_feedback_ai_summary",
  SHOW_CREATE_TEAM: "show_create_team",

  RTDEV_ONBOARDING: "rtdev_onboarding",
  RTDEV_AI_SUMMARIZATION_ENGAGEMENT_SURVEY: "rtdev_ai_summarization_engagement_survey",
  RTDEV_UPLOAD_IMAGE_DIRECTLY_AWS: "rtdev_upload_image_directly_aws",
  RTDEV_ALLOW_DELETE_USER_ANSWERS: "rtdev_allow_delete_user_answers",
  RTDEV_KITS_TAB_REVAMP: "rtdev_kits_tab_revamp",
}

const TEAM_FLAGS = {
  JUMBO_PROMO_SESSIONS: "jumbo_promo_sessions",
  RTDEV_KIT_TRANSLATION: "rtdev_kit_translation",
}

const ACCOUNT_FLAGS = {
  SHOW_ADMIN_TAB_PEOPLE_SECTION: "show_admin_tab_people_section",
}

const SHARED_FLAGS = {
  ARTI_FORCE_ENABLED: "arti_force_enabled",
  ARTI_FORCE_DISABLED: "arti_force_disabled",
  ARTI_LLM_SELECT: "arti_llm_select",
  ARTI_HIDE_RATING_FEEDBACK: "arti_hide_rating_feedback",
  ARTI_DO_NOT_USE_STREAMING: "arti_do_not_use_streaming",
  ARTI_HELP: "arti_help",
  IMAGE_RESIZING: "image_resizing",
  RTDEV_SESSION_AUTO_FOLLOW: "rtdev_session_auto_follow",
  RTDEV_REALTIME_ANSWER_UPDATE: "rtdev_realtime_answer_update",
  RTDEV_REALTIME_SHAREOUT_USER: "rtdev_realtime_shareout_user",
  RTDEV_REALTIME_SLIDESHOW: "rtdev_realtime_slideshow",
  RTDEV_REALTIME_REPLACE_POLLING: "rtdev_realtime_replace_polling",
  RTDEV_REALTIME_ACTIVITY_TIMER: "rtdev_realtime_activity_timer",
  RTDEV_ARTI_KIT_RECS: "rtdev_arti_kit_recs",
  RTDEV_ARTI_TEAM_SUMMARY: "rtdev_arti_team_summary",
  RTDEV_ARTI_ALLOW_UPLOAD: "rtdev_arti_allow_upload",
  RTDEV_PERF_HELPER_CONVO: "rtdev_perf_helper_convo",
}

const SWITCHES = {}

const fetchFeatures = async () => {
  const { data } = await root.get("/features/")
  return {
    ...(data?.flags || {}),
    ...(data?.switches || {}),
    ...(data?.samples || {}),
  }
}

const useFeatures = ({ enabled = true } = {}) => {
  const {
    data = {},
    isFetching,
    isInitialLoading,
  } = useQuery(featuresCacheKey, fetchFeatures, {
    cacheTime: 10 * 60 * 1000,
    staleTime: 10 * 60 * 1000,
    enabled,
  })
  return {
    ...data,
    isFetching,
    isInitialLoading,
  }
}

const featuresCacheKey = ["features"]

export default useFeatures

export { FLAGS, TEAM_FLAGS, ACCOUNT_FLAGS, SHARED_FLAGS, SWITCHES, featuresCacheKey }
