import cn from "classnames"
import { Form, Formik } from "formik"
import { useState, useMemo } from "react"
import { styled } from "styled-components"

import SearchInput from "components/SearchInput"
import { getKitStatus, sortDemoKits, sortKits } from "domains/LeaderKit/KitsTableofContents/utils"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { SquareIcon } from "icons/FontAwesomeIcons"
import Button from "ui/Button"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"
import { KitGoal } from "utils/kit"

const KitFiltersRevamp = ({
  basicKits,
  unavailableKits,
  initialVisibleKitsCount,
  kitInstanceMap,
  visibleKits,
  setVisibleKits,
  setVisibleKitsCount,
  user,
}) => {
  const [minLengthFilter, setMinLengthFilter] = useState(15)
  const [maxLengthFilter, setMaxLengthFilter] = useState(180)
  const [statusFilter, setStatusFilter] = useState("available")
  const [goalsFilter, setGoalsFilter] = useState({
    [KitGoal.BUILD_CONNECTION]: false,
    [KitGoal.SKILL_DEVELOPMENT]: false,
    [KitGoal.TEAM_INSIGHTS]: false,
    [KitGoal.HAVE_FUN]: false,
  })
  const [searchQuery, setSearchQuery] = useState([])
  const { isTabletOrLarger } = useWindowSize()

  // array of numbers starting at 15 and incrementing by 15 up to 180
  const minLengthOptions = Array(12)
    .fill(0)
    .map((_, index) => (index + 1) * 15)
  const maxLengthOptions = Array(12)
    .fill(0)
    .map((_, index) => (index + 1) * 15)
  const statusOptions = [
    { value: "all", label: "All" },
    { value: "available", label: "Not started" },
    { value: "in_progress", label: "In progress" },
    { value: "scheduled", label: "Scheduled" },
    { value: "complete", label: "Completed" },
  ]

  const initialValues = {
    min_length: 15,
    max_length: 180,
    status: "available",
  }

  const standardKits = basicKits.filter((kit) => kit.type !== "mini")
  const unavailableStandardKits = unavailableKits
    .filter((kit) => kit.type !== "mini")
    .map((kit) => ({ ...kit, unavailable: true }))
  const kitTotal = `(${visibleKits.length} of ${standardKits.length + unavailableStandardKits.length})`

  const filteredKits = useMemo(() => {
    const standardKits = basicKits.filter((kit) => kit.type !== "mini")
    const unavailableStandardKits = unavailableKits
      .filter((kit) => kit.type !== "mini")
      .map((kit) => ({ ...kit, unavailable: true }))
    const listOfKits =
      statusFilter === "all" || statusFilter === "available"
        ? [...standardKits, ...unavailableStandardKits]
        : standardKits

    return listOfKits.filter((kit) => {
      if (minLengthFilter && kit.rounded_session_time_minutes < minLengthFilter) {
        return false
      }

      if (maxLengthFilter && kit.rounded_session_time_minutes > maxLengthFilter) {
        return false
      }

      const kitInstance = kitInstanceMap[kit.slug]
      const kitStatus = getKitStatus(kitInstance, kit)
      if (statusFilter !== "all" && statusFilter !== "available" && kitStatus !== statusFilter) {
        return false
      }
      if (statusFilter === "available" && kitStatus !== "available" && kitStatus !== "unavailable") {
        return false
      }

      const activeGoalsFilter = Object.keys(goalsFilter).filter((key) => goalsFilter[key])
      const activeGoalsAndKitGoalsMatches = activeGoalsFilter.filter((goalFilter) => kit.goals.includes(goalFilter))
      const goalFiltersMatches = activeGoalsAndKitGoalsMatches.length > 0
      if (activeGoalsFilter.length && !goalFiltersMatches) {
        return false
      }

      const searchMatches = searchQuery.some(
        (term) => kit.title.toLowerCase().includes(term) || kit.description.toLowerCase().includes(term)
      )
      if (searchQuery.length && !searchMatches) {
        return false
      }

      return true
    })
  }, [
    basicKits,
    unavailableKits,
    kitInstanceMap,
    minLengthFilter,
    maxLengthFilter,
    statusFilter,
    goalsFilter,
    searchQuery,
  ])

  useEffectAfterChange(() => {
    if (user.is_demo_mode_active) {
      setVisibleKits(sortDemoKits(filteredKits, kitInstanceMap))
    } else {
      setVisibleKits(sortKits(filteredKits, kitInstanceMap))
    }

    setVisibleKitsCount(initialVisibleKitsCount)
  }, [
    initialVisibleKitsCount,
    filteredKits,
    setVisibleKits,
    setVisibleKitsCount,
    kitInstanceMap,
    user.is_demo_mode_active,
  ])

  return (
    <Formik initialValues={initialValues}>
      <Form>
        <View $justifyContent="space-between" className="mb-medium">
          <h2>Kits {kitTotal}</h2>
          {!!isTabletOrLarger && (
            <SearchInput query={searchQuery} setQuery={setSearchQuery} placeholder="Search for a kit" />
          )}
        </View>
        <View $gap="20px" $flexWrap="wrap">
          <View $flexDirection="column" $width="auto">
            <div className="text-semi-bold mb-xs">Min length</div>
            <AdvancedSelectField
              name="min_length"
              onChange={({ value }) => setMinLengthFilter(value)}
              options={minLengthOptions.map((option) => ({
                label: `${option} minutes`,
                value: option,
                isDisabled: option > maxLengthFilter,
              }))}
              width={134}
            />
          </View>
          <View $flexDirection="column" $width="auto">
            <div className="text-semi-bold mb-xs">Max length</div>
            <AdvancedSelectField
              name="max_length"
              onChange={({ value }) => setMaxLengthFilter(value)}
              options={maxLengthOptions.map((option) => ({
                label: `${option} minutes`,
                value: option,
                isDisabled: option < minLengthFilter,
              }))}
              width={134}
            />
          </View>
          <View $flexDirection="column" $width="auto">
            <div className="text-semi-bold mb-xs">Status</div>
            <AdvancedSelectField
              name="status"
              onChange={({ value }) => setStatusFilter(value)}
              options={statusOptions.map((option) => ({ label: option.label, value: option.value }))}
              width={166}
            />
          </View>
        </View>
        <View className="mt-medium text-gray-8" $alignItems="center" $flexWrap="wrap">
          <div className="text-small text-semi-bold mr-small">Goals:</div>
          <GoalPill
            goalType="Build connection"
            color="var(--orange-2)"
            tintColor="var(--orange-tint)"
            goal={KitGoal.BUILD_CONNECTION}
            goalsFilter={goalsFilter}
            setGoalsFilter={setGoalsFilter}
          />
          <GoalPill
            goalType="Skill development"
            color="var(--rising-green)"
            tintColor="var(--green-tint)"
            goal={KitGoal.SKILL_DEVELOPMENT}
            goalsFilter={goalsFilter}
            setGoalsFilter={setGoalsFilter}
          />
          <GoalPill
            goalType="Team insights"
            color="var(--rising-yellow)"
            tintColor="var(--yellow-tint)"
            goal={KitGoal.TEAM_INSIGHTS}
            goalsFilter={goalsFilter}
            setGoalsFilter={setGoalsFilter}
          />
          <GoalPill
            goalType="Have fun"
            color="var(--orchid)"
            tintColor="var(--orchid-tint)"
            goal={KitGoal.HAVE_FUN}
            goalsFilter={goalsFilter}
            setGoalsFilter={setGoalsFilter}
          />
        </View>
      </Form>
    </Formik>
  )
}

const GoalPill = styled(function GoalPill({ className, goalType, color, goal, goalsFilter, setGoalsFilter }) {
  const { isTabletOrSmaller } = useWindowSize()

  const toggleFilter = (goal) => {
    setGoalsFilter((prevGoalsFilter) => {
      const newGoalsFilter = { ...prevGoalsFilter, [goal]: !prevGoalsFilter[goal] }
      return newGoalsFilter
    })
  }

  return (
    <div className={cn(className, "mr-xs", { "mb-xs": isTabletOrSmaller })}>
      <Button className="goals-pill tertiary blur-4" onClick={() => toggleFilter(goal)}>
        <SquareIcon className="rotated-icon m-none" color={goalsFilter[goal] ? color : "var(--gray-6)"} />
        <span className={cn("text-thin", goalsFilter[goal] ? "text-gray-9" : "text-gray-8")}>{goalType}</span>
      </Button>
    </div>
  )
})`
  .rotated-icon {
    transform: rotate(45deg);
    width: 12.86px;
    height: 11.89px;
  }

  .goals-pill {
    border-radius: var(--border-radius-large);
    border-color: ${({ color, goal, goalsFilter }) => (goalsFilter[goal] ? color : "transparent")};
    background-color: ${({ tintColor, goal, goalsFilter }) => (goalsFilter[goal] ? tintColor : "transparent")};
    font-size: 13px;
    padding: 6px 12px;
  }
`

export default KitFiltersRevamp
