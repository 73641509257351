import { Formik } from "formik"
import { find, keyBy } from "lodash-es"
import { useState } from "react"

import { getUserExerciseInstance } from "../Exercise/results_utils"

import ShareResultsButton from "./ShareResultsButton"
import UpdateResultsButton from "./UpdateResultsButton"

import CareerHorizonsSelectedUserView from "domains/Exercise/ResultsComponents/CareerHorizonsSelectedUserView"
import CareerHorizonsTeamView from "domains/Exercise/ResultsComponents/CareerHorizonsTeamView"
import SelectField from "forms/fields/SelectField"
import { MountainsIcon } from "icons/FontAwesomeIcons"
import { useLatestTeamExerciseInstances } from "resources/exercise"
import { sortUsersByShortName, useUser } from "resources/users"
import useQueryParams from "ui/hooks/useQueryParams"
import Loading from "ui/Loading"
import View from "ui/View"

const CareerHorizonsResultsReview = ({ kit, resultsPage, selectedTeam }) => {
  const { data: currentUser } = useUser({ userId: "me" })
  const query = useQueryParams()

  const { data: exerciseInstances, isFetching } = useLatestTeamExerciseInstances({
    teamId: selectedTeam.id,
    exerciseSlug: kit.exercise.slug,
  })

  if (isFetching) {
    return <Loading />
  }

  const users = selectedTeam.members.filter((x) => getUserExerciseInstance(exerciseInstances, x))
  const initialUserId = parseInt(query.participant)
  const initialUser = find(users, { id: initialUserId })

  return (
    <>
      <View $alignItems="flex-start" className="mb-large">
        <MountainsIcon className="text-orange-2 title-icon mr-medium" />
        <h1 className="text-gray-9">{resultsPage.title}</h1>
      </View>
      <p className="text-gray-9 mb-medium">
        Exploring hopes and dreams for long-term Career Horizons can reveal and illuminate impactful actions for today.
        Here are potential career paths (and actions) explored during the Rising Team session.
      </p>
      <SelectedUserResults
        kit={kit}
        users={users}
        currentUser={currentUser}
        exerciseInstances={exerciseInstances}
        initialUser={initialUser}
        selectedTeam={selectedTeam}
      />
    </>
  )
}

const TEAM_ALL = 0

const SelectedUserResults = ({ kit, users, exerciseInstances, initialUser, selectedTeam }) => {
  const [selectedUserId, setSelectedUserId] = useState(initialUser?.id || TEAM_ALL)
  const sortedUsers = sortUsersByShortName({ users })
  const selectorInitialValues = { current_team_member: selectedUserId }
  const selectedExercise = getUserExerciseInstance(exerciseInstances, { id: selectedUserId })
  const [shareLink, setShareLink] = useState(initialUser?.id || TEAM_ALL)
  const userIdMap = keyBy(users, "id")

  const handleUserChange = ({ target }) => {
    const user = find(users, {
      id: parseInt(target.value),
    })
    setSelectedUserId(user?.id || TEAM_ALL)
  }

  return (
    <>
      <View $alignItems="flex-start" $flexWrap="wrap" className="mb-xl mt-medium">
        <View $flexDirection="row" $flexDirectionTablet="column" $flexDirectionMobile="column" $alignItems="center">
          <View $flexDirection="column">
            <Formik initialValues={selectorInitialValues} className="mb-xl">
              <SelectField name="current_team_member" size="medium" value={selectedUserId} onChange={handleUserChange}>
                <option value={TEAM_ALL}>Team Overview</option>
                {sortedUsers.map(({ id, short_name }) => (
                  <option key={id} value={id}>
                    {short_name}
                  </option>
                ))}
              </SelectField>
            </Formik>
          </View>
          <View $justifyContent="flex-end" $justifyContentTablet="flex-start" $justifyContentMobile="flex-start">
            <ShareResultsButton
              selectedUserId={selectedUserId}
              selectedExercise={selectedExercise}
              shareLink={shareLink}
              setShareLink={setShareLink}
              shareAssessmentTitle="Career Horizons"
            />
            <UpdateResultsButton selectedUserId={selectedUserId} kitSlug={kit.slug} selectedTeam={selectedTeam} />
          </View>
        </View>
      </View>

      {selectedUserId === TEAM_ALL ? (
        <CareerHorizonsTeamView exerciseInstances={exerciseInstances} userIdMap={userIdMap} />
      ) : (
        <CareerHorizonsSelectedUserView exercise={selectedExercise} />
      )}
    </>
  )
}

export default CareerHorizonsResultsReview
