import cn from "classnames"
import { Form, Formik } from "formik"

import ArtiChatInputArea from "./ArtiChatInputArea"
import ArtiChatMessages from "./ArtiChatMessages"
import ArtiUserMemberSelectorMessage from "./ArtiMemberSelectorMessage"
import ArtiTeamMemberOptedOutMessage from "./ArtiTeamMemberOptedOutMessage"
import ArtiUpload from "./ArtiUpload"

const ArtiChatTypePerformanceReview = ({
  user,
  team,
  llm,
  selectedMember,
  teamMembers,
  memberId,
  onMemberChange,
  memberIdTeamNameMap,
  onStartNewConversation,
  showTeamSummary,
  chatTypeSelected,
  isFetchingHistorySession,
  messages,
  loading,
  historyExchangeId,
  chatInputRef,
  artiTourPopularTopicsMenuOpen,
  isComponentMounted,
  onSubmit,
  allowArtiUpload,
  isArtiUploadAvailable,
  setIsArtiUploadAvailable,
}) => {
  const memberOptedOut = selectedMember?.arti_disabled
  return (
    <>
      <ArtiUserMemberSelectorMessage
        user={user}
        team={team}
        llm={llm}
        selectedMember={selectedMember}
        teamMembers={teamMembers}
        memberId={memberId}
        onMemberChange={onMemberChange}
        memberIdTeamNameMap={memberIdTeamNameMap}
        onStartNewConversation={onStartNewConversation}
        showTeamSummary={showTeamSummary}
        chatTypeSelected={chatTypeSelected}
      />
      {!memberOptedOut ? (
        <div
          className={cn("fadeable conversation-container", {
            hidden: !selectedMember || isFetchingHistorySession,
          })}
        >
          <ArtiChatMessages
            messages={messages}
            user={user}
            team={team}
            loading={loading}
            llm={llm}
            historyExchangeId={historyExchangeId}
            chatInputRef={chatInputRef}
            chatTypeSelected={chatTypeSelected}
          />
          <ArtiChatInputArea
            loading={loading}
            isComponentMounted={isComponentMounted}
            onSubmit={onSubmit}
            chatInputRef={chatInputRef}
            messages={messages}
            user={user}
            team={team}
            selectedMember={selectedMember}
            artiTourPopularTopicsMenuOpen={artiTourPopularTopicsMenuOpen}
            chatTypeSelected={chatTypeSelected}
            className="mb-medium"
          />
          {!!allowArtiUpload && !!isArtiUploadAvailable && (
            <Formik initialValues={{ message: "", fileName: null }}>
              <Form className="mt-xs mb-xl-mobile-never mb-xs-mobile-only">
                <ArtiUpload onSubmit={onSubmit} setIsArtiUploadAvailable={setIsArtiUploadAvailable} />
              </Form>
            </Formik>
          )}
        </div>
      ) : (
        <ArtiTeamMemberOptedOutMessage selectedMember={selectedMember} />
      )}
    </>
  )
}

export default ArtiChatTypePerformanceReview
