import { ResponsiveLine } from "@nivo/line"

import theme from "ui/theme"

const defaultTheme = {
  grid: {
    line: {
      strokeWidth: "2px",
      stroke: "#EEEEEE",
    },
  },
  axis: {
    ticks: {
      line: {
        strokeWidth: "2px",
        stroke: "#EEEEEE",
      },
      text: {
        fontWeight: 400,
        color: "var(--gray-8)",
        fontSize: "0.813rem",
      },
    },
  },
  fontFamily: theme.normalFontFamily,
}

const styleByClassName = {
  default: {
    strokeWidth: 1.5,
    color: "var(--gray-9)",
  },
  bottomTick: {
    font: "13px sans-serif",
    fontWeight: 400,
  },
}

const CustomGridLine =
  (y) =>
  ({ yScale, innerWidth }) => (
    <line x1={-32} x2={innerWidth + 32} y1={yScale(y)} y2={yScale(y)} stroke="#EEEEEE" strokeWidth={2} />
  )

const Lines = ({ series, lineGenerator, xScale, yScale }) =>
  series.map(({ id, data, className }) => {
    const { color: _, ...style } = styleByClassName[className] || styleByClassName.default
    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x),
            y: yScale(d.data.y),
          }))
        )}
        fill="none"
        stroke="var(--gray-9)"
        style={style}
      />
    )
  })

const TrendLineChart = ({
  data,
  axisLeft = {},
  axistLeftLabels,
  colorFunc,
  hasNPSDetails,
  gridYValues = 5,
  leftMargin = 100,
  theme = {},
  yScaleMax,
  yScaleMin = 1,
  tickSize = 40,
  tickValues = 5,
  useCustomGridLine,
}) => (
  <ResponsiveLine
    data={data}
    theme={{ ...defaultTheme, ...theme }}
    colors="green"
    lineWidth={1}
    margin={{ top: 20, right: 60, bottom: 100, left: leftMargin }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: yScaleMin,
      max: yScaleMax,
      stacked: true,
      reverse: false,
    }}
    axisTop={null}
    axisBottom={{
      tickSize: 15,
      tickPadding: 0,
      tickRotation: 0,
      renderTick: ({ textAnchor, textX, textY, value, x, y }) => (
        <g transform={`translate(${x},${y})`}>
          <line x1="0" y1="-7" x2="0" y2="15" style={{ stroke: "#EEEEEE", strokeWidth: 2 }}></line>
          <text style={styleByClassName.bottomTick} textAnchor={textAnchor} transform={`translate(${textX},${textY})`}>
            {getTspanGroups(value)}
          </text>
        </g>
      ),
    }}
    axisLeft={{
      orient: "left",
      tickSize,
      tickPadding: useCustomGridLine ? 48 : 16,
      tickRotation: 0,
      tickValues,
      format(value) {
        return axistLeftLabels[value]
      },
      ...axisLeft,
    }}
    axisRight={{
      tickSize,
      tickValues,
      format() {
        return ""
      },
    }}
    curve="linear"
    enableCrosshair={false}
    enableGridX={false}
    gridYValues={gridYValues}
    pointSize={13}
    pointSymbol={(input) => {
      const color = colorFunc ? colorFunc(input.datum.y) : "var(--gray-6)"
      return <circle cx="0" cy="0" r="5" stroke={color} strokeWidth="2" fill={color} />
    }}
    pointBorderWidth={2}
    pointLabelYOffset={-12}
    useMesh={true}
    layers={[
      "grid",
      "markers",
      "axes",
      "areas",
      ...(useCustomGridLine
        ? [CustomGridLine(1), CustomGridLine(1.75), CustomGridLine(2.5), CustomGridLine(3.25), CustomGridLine(4)]
        : []),
      Lines,
      "points",
      "slices",
      "mesh",
      "legends",
    ]}
    tooltip={(input) => {
      const avg_value = input.point.data.y
      const diff_percentage = parseFloat(input.point.data.z)
      const current_index = input.point.index
      const percentage_value = Number.isNaN(diff_percentage)
        ? "Percentage value not available"
        : `${diff_percentage > 0 ? "+" : ""}${diff_percentage?.toFixed(1)}%`
      return (
        <div className="bg-gray-30 text-white px-small py-xs text-semi-bold border-radius-xxs">
          {hasNPSDetails ? (
            <>
              <div>
                NPS Score: {avg_value} {current_index !== 0 && ` | ${percentage_value}`}
              </div>
              <div>NPS Promoters: {input.point.data.nps_details?.nps_promoters}</div>
              <div>NPS Passive: {input.point.data.nps_details?.nps_passive}</div>
              <div>NPS Detractors: {input.point.data.nps_details?.nps_detractors}</div>
            </>
          ) : (
            <>
              {avg_value}
              {current_index !== 0 && ` | ${percentage_value}`}
            </>
          )}
        </div>
      )
    }}
  />
)

const getTspanGroups = (value) => {
  value = value.replace("Run", "").trim()
  const valueArray = value.split(" ")
  return (
    <>
      {valueArray.map((v, idx) => (
        <tspan x="0" dy="1.2em" key={idx} fill="var(--gray-8)">
          {idx === 0 ? <tspan style={{ fontWeight: "bold" }}>Run {v}</tspan> : v}
        </tspan>
      ))}
    </>
  )
}

export default TrendLineChart
