import BulletedList from "ui/BulletedList"

const RankOrderFieldTopNList = ({ identifier, count, exerciseInstance, className }) => {
  const values = exerciseInstance?.answers?.find((answer) => answer.identifier === identifier)?.data ?? []

  if (!values.length) {
    return null
  }

  const topNValues = values.slice(0, count)

  return (
    <BulletedList className={className}>
      {topNValues.map((value, index) => (
        <li key={index}>{value}</li>
      ))}
    </BulletedList>
  )
}

export default RankOrderFieldTopNList
