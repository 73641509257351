import * as Sentry from "@sentry/browser"
import { last, sortBy } from "lodash-es"

import { getExerciseComponent, getUserExerciseInstance } from "../results_utils"

import CheckboxGroupReadOnlyField from "./CheckboxGroupReadOnlyField"
import DateFieldReadOnlyField from "./DateFieldReadOnlyField"
import FieldsList from "./FieldsList"
import GuessingGameCorrectUserBubbles from "./GuessingGameCorrectUserBubbles"
import GuessingGameRanking from "./GuessingGameRanking"
import IconGroupRatingFieldAggregatedMultiUserBarChart from "./IconGroupRatingFieldAggregatedMultiUserBarChart"
import IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart from "./IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart"
import IconGroupRatingFieldSelectedUserBarChart from "./IconGroupRatingFieldSelectedUserBarChart"
import IconRatingFieldReadOnly from "./IconRatingFieldReadOnly"
import IfAnswerEquals from "./IfAnswerEquals"
import { IfAnswersExist, IfAnswersDoNotExist } from "./IfAnswersExist"
import IfMeIsSelectedUser from "./IfMeIsSelectedUser"
import Image from "./Image"
import ImageFieldMultiUserGrid from "./ImageFieldMultiUserGrid"
import MultiUserIfAnyAnswerEquals from "./MultiUserIfAnswersExist"
import PercentageGroupFieldAggregatedMultiUserBarChart from "./PercentageGroupFieldAggregatedMultiUserBarChart"
import PercentageGroupFieldDonutChart from "./PercentageGroupFieldDonutChart"
import PercentageGroupFieldMultiUserCircles from "./PercentageGroupFieldMultiUserCircles"
import PercentageGroupRatingFieldDonutChart from "./PercentageGroupRatingFieldDonutChart"
import PieSliceGroupFieldAggregatedMultiUserBarChart from "./PieSliceGroupFieldAggregatedMultiUserBarChart"
import PieSliceGroupFieldAggregatedMultiUserScatterPlot from "./PieSliceGroupFieldAggregatedMultiUserScatterPlot"
import PieSliceGroupFieldPieChart from "./PieSliceGroupFieldPieChart"
import PieSliceGroupRatingFieldAggregatedMultiUserBarChart from "./PieSliceGroupRatingFieldAggregatedMultiUserBarChart"
import PieSliceGroupRatingFieldPieChart from "./PieSliceGroupRatingFieldPieChart"
import RadioGroupCollectionMultiUserBarChart from "./RadioGroupCollectionMultiUserBarChart"
import RadioGroupFieldAggregatedMultiUserBarChart from "./RadioGroupFieldAggregatedMultiUserBarChart"
import RadioGroupFieldAggregatedMultiUserStackedBar from "./RadioGroupFieldAggregatedMultiUserStackedBar"
import RadioGroupIconButtons from "./RadioGroupIconButtons"
import RadioGroupIconList from "./RadioGroupIconList"
import RadioGroupIcons from "./RadioGroupIcons"
import RadioGroupList from "./RadioGroupList"
import RadioGroupMultiUserBarChart from "./RadioGroupMultiUserBarChart"
import RadioGroupMultiUserCircleCounter from "./RadioGroupMultiUserCircleCounter"
import RadioGroupMultiUserIconGrid from "./RadioGroupMultiUserIconGrid"
import RadioGroupMultiUserOptionList from "./RadioGroupMultiUserOptionList"
import RadioGroupMultiUserRangeGrid from "./RadioGroupMultiUserRangeGrid"
import RadioGroupMultiUserSimpleBarChart from "./RadioGroupMultiUserSimpleBarChart"
import RadioGroupMultiUserTextButtonGrid from "./RadioGroupMultiUserTextButtonGrid"
import RadioGroupMultiUserWordCloud from "./RadioGroupMultiUserWordCloud"
import RadioGroupRange from "./RadioGroupRange"
import RadioGroupReadOnlyField from "./RadioGroupReadOnlyField"
import RadioGroupSpanText from "./RadioGroupSpanText"
import RadioGroupTextButtonEmptyBlocks from "./RadioGroupTextButtonEmptyBlocks"
import RadioGroupTextButtons from "./RadioGroupTextButtons"
import RadioGroupWithCustomSpanText from "./RadioGroupWithCustomSpanText"
import RankOrderFieldTopNHighlight from "./RankOrderFieldTopNHighlight"
import RankOrderFieldTopNList from "./RankOrderFieldTopNList"
import RatingFieldReadOnly from "./RatingFieldReadOnly"
import RepeatingGroupTextFieldList from "./RepeatingGroupTextFieldList"
import RepeatingGroupTextFieldMatchingList from "./RepeatingGroupTextFieldMatchingList"
import RepeatingGroupTextFieldMatchingListGroups from "./RepeatingGroupTextFieldMatchingListGroups"
import RichTextFieldMultiUserAccordionList from "./RichTextFieldMultiUserAccordionList"
import RichTextFieldMultiUserList from "./RichTextFieldMultiUserList"
import RichTextFieldRichText from "./RichTextFieldRichText"
import RichTextFieldValueReferenceText from "./RichTextFieldValueReferenceText"
import SelectedUserRevealButton from "./SelectedUserRevealButton"
import SelectedUserShortName from "./SelectedUserShortName"
import SelectFieldMultiUserList from "./SelectFieldMultiUserList"
import SelectFieldMultiUserPieChart from "./SelectFieldMultiUserPieChart"
import SelectFieldReadOnlyField from "./SelectFieldReadOnlyField"
import SelectFieldSpanText from "./SelectFieldSpanText"
import StickyNotesTeamFavoritesShare from "./StickyNotesTeamFavoritesShare"
import StickyNotesUserFavoritesShare from "./StickyNotesUserFavoritesShare"
import TextFieldAggregatedMultiUserScroll from "./TextFieldAggregatedMultiUserScroll"
import TextFieldAggregatedMultiUserSlideshowWithRevealAccordion from "./TextFieldAggregatedMultiUserSlideshowWithRevealAccordion"
import TextFieldAggregatedMultiUserWordCloud from "./TextFieldAggregatedMultiUserWordCloud"
import TextFieldMultiUserList from "./TextFieldMultiUserList"
import TextFieldReadOnlyField from "./TextFieldReadOnlyField"
import TextFieldText from "./TextFieldText"
import TextFieldValueReferenceText from "./TextFieldValueReferenceText"
import TextReferenceCheckboxGroupReadOnlyField from "./TextReferenceCheckboxGroupReadOnlyField"

import Accordion from "components/Accordion"
import blockComponents from "components/Blocks/blockComponents"
import renderBlocks from "components/Blocks/renderBlocks"
import { blockIcons } from "icons"
import blockUIComponents from "ui/blockUIComponents"
import SelectedUserContext, { useSelectedUser } from "ui/SelectedUserContext"

const MultiUserSingleUserRow = ({
  exerciseInstances,
  sortedUsers,
  singleUserRowBlocks,
  showUserName,
  showAccordion,
  shortAccordion,
  accordionTag,
  className,
}) => {
  const WrapperComponent = showUserName && showAccordion ? Accordion : "div"
  return (
    <>
      {sortedUsers.map((user) => (
        <WrapperComponent
          key={user.id}
          title={user.short_name}
          {...(showUserName && showAccordion ? { shortAccordion, accordionTag } : {})}
          className={className}
        >
          {!!showUserName && !showAccordion && <h4 className="text-semi-bold mb-xs">{user.short_name}</h4>}
          <SelectedUserContext.Provider value={{ selectedUser: user }}>
            <ResultsBlocks
              blocks={singleUserRowBlocks}
              exerciseInstances={exerciseInstances}
              sortedUsers={sortedUsers}
            />
          </SelectedUserContext.Provider>
        </WrapperComponent>
      ))}
    </>
  )
}

const EXERCISE_IDENTIFIER_COMPONENTS = {
  CheckboxGroupReadOnlyField,
  DateFieldReadOnlyField,
  FieldsList,
  IconRatingFieldReadOnly,
  IconGroupRatingFieldSelectedUserBarChart,
  Image,
  PercentageGroupFieldDonutChart,
  PercentageGroupFieldMultiUserCircles,
  PercentageGroupRatingFieldDonutChart,
  PieSliceGroupFieldPieChart,
  PieSliceGroupRatingFieldPieChart,
  RadioGroupCollectionMultiUserBarChart,
  RadioGroupIconButtons,
  RadioGroupIconList,
  RadioGroupIcons,
  RadioGroupList,
  RadioGroupMultiUserBarChart,
  RadioGroupMultiUserCircleCounter,
  RadioGroupMultiUserIconGrid,
  RadioGroupMultiUserOptionList,
  RadioGroupMultiUserRangeGrid,
  RadioGroupMultiUserSimpleBarChart,
  RadioGroupMultiUserTextButtonGrid,
  RadioGroupMultiUserWordCloud,
  RadioGroupRange,
  RadioGroupReadOnlyField,
  RadioGroupSpanText,
  RadioGroupWithCustomSpanText,
  RadioGroupTextButtons,
  RadioGroupTextButtonEmptyBlocks,
  RankOrderFieldTopNHighlight,
  RankOrderFieldTopNList,
  RatingFieldReadOnly,
  RepeatingGroupTextFieldList,
  RepeatingGroupTextFieldMatchingList,
  RepeatingGroupTextFieldMatchingListGroups,
  RichTextFieldMultiUserAccordionList,
  RichTextFieldMultiUserList,
  RichTextFieldRichText,
  RichTextFieldValueReferenceText,
  SelectFieldMultiUserList,
  SelectFieldMultiUserPieChart,
  SelectFieldReadOnlyField,
  SelectFieldSpanText,
  TextFieldMultiUserList,
  TextFieldReadOnlyField,
  TextFieldText,
  TextFieldValueReferenceText,
  TextReferenceCheckboxGroupReadOnlyField,
  GuessingGameRanking,
  GuessingGameCorrectUserBubbles,
  StickyNotesUserFavoritesShare,
  StickyNotesTeamFavoritesShare,
}
const AGGREGATED_EXERCISE_IDENTIFIER_COMPONENTS = {
  IconGroupRatingFieldAggregatedMultiUserBarChart,
  IconGroupRatingFieldAggregatedMultiUserBreakoutBarChart,
  PercentageGroupFieldAggregatedMultiUserBarChart,
  PieSliceGroupFieldAggregatedMultiUserBarChart,
  PieSliceGroupRatingFieldAggregatedMultiUserBarChart,
  PieSliceGroupFieldAggregatedMultiUserScatterPlot,
  RadioGroupFieldAggregatedMultiUserBarChart,
  RadioGroupFieldAggregatedMultiUserStackedBar,
  TextFieldAggregatedMultiUserScroll,
  TextFieldAggregatedMultiUserSlideshowWithRevealAccordion,
  TextFieldAggregatedMultiUserWordCloud,
}
const MULTI_USER_COMPONENTS = {
  ImageFieldMultiUserGrid,
  MultiUserIfAnyAnswerEquals,
  MultiUserSingleUserRow,
  PercentageGroupFieldMultiUserCircles,
  RadioGroupCollectionMultiUserBarChart,
  RadioGroupMultiUserBarChart,
  RadioGroupMultiUserCircleCounter,
  RadioGroupMultiUserIconGrid,
  RadioGroupMultiUserOptionList,
  RadioGroupMultiUserRangeGrid,
  RadioGroupMultiUserSimpleBarChart,
  RadioGroupMultiUserTextButtonGrid,
  RadioGroupMultiUserWordCloud,
  RichTextFieldMultiUserAccordionList,
  RichTextFieldMultiUserList,
  SelectFieldMultiUserList,
  SelectFieldMultiUserPieChart,
  TextFieldMultiUserList,
}
const OTHER_RESULTS_BLOCK_COMPONENTS = {
  IfAnswerEquals,
  IfAnswersExist,
  IfAnswersDoNotExist,
  IfMeIsSelectedUser,
  ImageFieldMultiUserGrid,
  MultiUserIfAnyAnswerEquals,
  MultiUserSingleUserRow,
  SelectedUserRevealButton,
  SelectedUserShortName,
}

const isExerciseIdentifierComponent = (Tag) => Object.values(EXERCISE_IDENTIFIER_COMPONENTS).includes(Tag)
const isAggregatedExerciseIdentifierComponent = (Tag) =>
  Object.values(AGGREGATED_EXERCISE_IDENTIFIER_COMPONENTS).includes(Tag)
const isResultsBlockComponent = (Tag) =>
  Object.values({
    ...EXERCISE_IDENTIFIER_COMPONENTS,
    ...AGGREGATED_EXERCISE_IDENTIFIER_COMPONENTS,
    ...OTHER_RESULTS_BLOCK_COMPONENTS,
  }).includes(Tag)
const isMultiUserComponent = (Tag) => Object.values(MULTI_USER_COMPONENTS).includes(Tag)

const ResultsBlocks = ({ blocks, exerciseInstances, teamExerciseInstance, sortedUsers, useAggregatedResultsData }) => {
  const { selectedUser } = useSelectedUser()

  if (!blocks || (!exerciseInstances?.length && !teamExerciseInstance)) {
    return null
  }

  const exerciseInstance = getUserExerciseInstance(exerciseInstances, selectedUser) ?? teamExerciseInstance

  const components = {
    ...blockComponents,
    ...blockUIComponents,
    ...blockIcons,
    ...EXERCISE_IDENTIFIER_COMPONENTS,
    ...AGGREGATED_EXERCISE_IDENTIFIER_COMPONENTS,
    ...OTHER_RESULTS_BLOCK_COMPONENTS,
  }
  return renderBlocks(blocks, components, ({ Tag, props }) => {
    if (isExerciseIdentifierComponent(Tag)) {
      const exerciseComponent = getBestGuessExerciseComponent({
        exerciseInstance,
        exerciseInstances,
        identifier: props.identifier,
      })
      if (!exerciseComponent) {
        return null // since there is no exerciseComponent, skip rendering for this block
      } else if (isMultiUserComponent(Tag)) {
        return {
          ...props,
          exerciseInstances,
          exerciseComponent,
          sortedUsers,
        }
      } else {
        return {
          ...props,
          exerciseInstance,
          exerciseComponent,
          sortedUsers,
        }
      }
    } else if (isAggregatedExerciseIdentifierComponent(Tag)) {
      return {
        ...props,
        useAggregatedResultsData,
        sortedUsers,
      }
    } else if (isResultsBlockComponent(Tag)) {
      if (isMultiUserComponent(Tag)) {
        return {
          ...props,
          exerciseInstances,
          sortedUsers,
        }
      } else {
        return {
          ...props,
          exerciseInstance,
          sortedUsers,
        }
      }
    }
    return props
  })
}

const getBestGuessExerciseComponent = ({ exerciseInstance, exerciseInstances, identifier }) => {
  const bestGuessExerciseInstance = exerciseInstance ?? last(sortBy(exerciseInstances, "created_at"))
  const exerciseComponent = getExerciseComponent(bestGuessExerciseInstance, identifier)
  if (!exerciseComponent) {
    Sentry.captureMessage(
      `ResultsBlocks.js: Missing exercise component for instance ${bestGuessExerciseInstance?.id}, identifier: ${identifier}`
    )
  }
  return exerciseComponent
}

export default ResultsBlocks
