import { useKitSession } from "../KitSessionContext"

import { useSessionExerciseInstances } from "resources/exercise"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import { useHasTeamFeature } from "utils/team"

const IfAnyUserExerciseAnswerEqualsContainer = ({ kitInstance, identifier, answerValue, children }) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesFlagActive } = useHasTeamFeature(
    team,
    SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE
  )
  const sessionRealtimeUpdates = !!sessionRealtimeUpdatesFlagActive && !!kitInstance && !!team

  const { data: exerciseInstances, isFetching } = useSessionExerciseInstances(kitInstance?.id, {
    sessionRealtimeUpdates,
    refetchInterval: 30000,
  })

  if (!exerciseInstances && isFetching) {
    return <Loading />
  }

  if (!exerciseInstances) {
    return null
  }

  const exerciseAnswers = exerciseInstances
    .flatMap((instance) => instance.answers)
    .filter((answer) => answer.identifier === identifier)

  const hasAnswerWithValue = exerciseAnswers.some((exerciseAnswer) => exerciseAnswer.data.value === answerValue)

  return <>{hasAnswerWithValue ? children : null}</>
}

export default IfAnyUserExerciseAnswerEqualsContainer
