import cn from "classnames"
import { useState } from "react"

import Button from "./Button"
import Loading from "./Loading"
import { colors } from "./theme"
import View from "./View"

const DownloadCSVLink = ({
  className,
  downloadCSVExport,
  loadingIconLocation = "right",
}: {
  className: string
  downloadCSVExport: () => void
  loadingIconLocation: string
}) => {
  const [isExportingCSV, setIsExportingCSV] = useState(false)

  const onClick = async () => {
    setIsExportingCSV(true)
    try {
      await downloadCSVExport()
    } finally {
      setIsExportingCSV(false)
    }
  }

  return (
    <View $alignItems="center" className={cn(className, "fit-content")}>
      {!!isExportingCSV && loadingIconLocation === "left" && <Loading inline className="mr-xs" />}
      <Button color={colors.risingBlue} className="link-semi-bold" onClick={onClick}>
        Download CSV
      </Button>
      {!!isExportingCSV && loadingIconLocation === "right" && <Loading inline />}
    </View>
  )
}

export default DownloadCSVLink
