import { find, get, sortBy } from "lodash-es"

import { asArray } from "utils/array"
import { truncate } from "utils/string"

const getUserExerciseInstance = (exerciseInstances, user) => find(exerciseInstances, { user_id: user?.id })

const answerAsArray = (answer, defaultValue) => (answer == null ? defaultValue : asArray(answer))

const getUserAnswer = (exerciseInstances, user, identifier) => {
  const exerciseInstance = getUserExerciseInstance(exerciseInstances, user)
  if (!exerciseInstance) {
    return null
  }
  return getExerciseAnswer(exerciseInstance, identifier)
}

const getSortedTruncatedUserAnswers = (exerciseInstances, sortedUsers, identifier, maxNumResults = null) => {
  const sortedAnswers = sortBy(
    sortedUsers.map((user) => [user, getUserAnswer(exerciseInstances, user, identifier) ?? []]),
    ([_user, answer]) => !answer?.length
    // put rows with answers before rows without answers, maintaining original sort ordering otherwise
  )
  return !maxNumResults ? sortedAnswers : sortedAnswers.slice(0, maxNumResults)
}

const getExerciseComponent = (exerciseInstance, identifier) => {
  const exerciseComponents = [
    ...exerciseInstance.definition.steps.flatMap((step) => step.components),
    ...(exerciseInstance.definition?.other_exercise_components ?? []),
  ]
  return find(exerciseComponents, { identifier })
}

const getExerciseAnswer = (exerciseInstance, identifier) => {
  const answer = find(exerciseInstance?.answers, { identifier })?.data
  if (!answer) {
    return null
  }
  return get(answer, ["value"], answer)
}

const getSortedExerciseAnswers = (exerciseInstances, identifier) =>
  exerciseInstances
    .flatMap((instance) => instance.answers)
    .filter((answer) => answer.identifier === identifier)
    .filter((answer) => get(answer.data, ["value"]))
    .sort((answer1, answer2) => {
      const value1 = get(answer1.data, ["value"], answer1)
      const value2 = get(answer2.data, ["value"], answer2)
      return value1.localeCompare(value2)
    })

const getTeamLead = (participants, team) => find(participants, (u) => u.id === team.team_lead_id)

const formatExercisePieChartData = (items, sliceValues, otherValue = null) => {
  // Accept otherValue in either of two formats:
  // 1) as a string (what comes from getExerciseAnswer)
  // 2) as a { value: <string> } object (what comes from useField)
  otherValue = typeof otherValue === "string" ? otherValue : otherValue?.value
  return items.map(({ identifier, label, description }) => ({
    id: identifier,
    label: (identifier === "other" && truncate(otherValue, 16)) || label || "",
    value: sliceValues?.[identifier] ?? null,
    description: description || "",
  }))
}

export {
  getUserExerciseInstance,
  getUserAnswer,
  getSortedTruncatedUserAnswers,
  getExerciseComponent,
  getExerciseAnswer,
  getSortedExerciseAnswers,
  getTeamLead,
  answerAsArray,
  formatExercisePieChartData,
}
