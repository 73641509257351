import { QRCode } from "react-qrcode-logo"
import { useNavigate } from "react-router"

import PromoIndex from "."

import { useAuth } from "domains/Authentication/resource"
import { useCreateShrmVolunteerSession } from "resources/get_started"
import { useUser } from "resources/users"
import Button from "ui/Button"
import Loading from "ui/Loading"
import PageTitle from "ui/PageTitle"
import { useCurrentTheme } from "ui/ThemeUpdateContext"
import View from "ui/View"

const ShrmVolunteer = () => {
  const { data: auth, isFetching } = useAuth()
  const navigate = useNavigate()
  const { data: user } = useUser({ userId: !!auth && "me" })
  const { mutateAsync: createSession } = useCreateShrmVolunteerSession()
  const theme = useCurrentTheme()

  if (isFetching) {
    return <Loading />
  }

  if (!auth) {
    return <PromoIndex promoCode="shrm-24-volunteer" />
  }

  const onCreateSessionClick = async () => {
    const kitInstance = await createSession()
    navigate(`/startsession/${kitInstance.id}/`)
  }

  return (
    <>
      <PageTitle>SHRM Volunteer</PageTitle>
      <View className="p-xl" $flexDirection="column" $alignItems="center">
        <img
          src={"https://static.risingteam.com/kit_assets/onboarding/shrm-civil-conversations-brand-logo.png"}
          alt="SHRM Civil Conversations"
          width="100%"
          className="mb-xl"
        />

        <p className="mb-medium">Would you like to start a new Civil Conversations session?</p>
        <div className="mb-xl">
          <Button onClick={onCreateSessionClick}>Yes</Button>
          <Button className="secondary" onClick={() => navigate("/")}>
            No
          </Button>
        </div>
        {!!user?.is_staff && (
          <QRCode
            value="https://app.risingteam.com/shrm-volunteer/"
            size={200}
            fgColor={theme.gray9}
            quietZone={0}
            ecLevel="M"
            qrStyle="dots"
            logoImage={`${import.meta.env.VITE_PUBLIC_URL}/logo192.png`}
            removeQrCodeBehindLogo={true}
          />
        )}
      </View>
    </>
  )
}

export default ShrmVolunteer
