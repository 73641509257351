import cn from "classnames"
import { useNavigate } from "react-router-dom"
import { styled } from "styled-components"

import SessionImage from "domains/KitSession/components/SessionImage"
import LinkGrid from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/LinkGrid"
import TeamPhotoModalContent from "domains/LeaderKit/TeamPhotoModalContent"
import TeamPhotoShareCopy from "domains/LeaderKit/TeamPhotoShareCopy"
import {
  ChartLineIcon,
  EditIcon,
  ShareIcon,
  StarIcon,
  TrainIcon,
  ChartUserIcon,
  DoorOpenIcon,
  ImageIcon,
} from "icons/FontAwesomeIcons"
import { getStandaloneExerciseUrl, useSessionPicture, useRestartKitSessionTestOnly } from "resources/monthly_kit"
import useFeatures, { FLAGS, SHARED_FLAGS } from "ui/hooks/useFeatures"
import useWindowSize from "ui/hooks/useWindowSize"
import { useModal } from "ui/ModalContext"
import SocialShareButtons from "ui/SocialShareButtons"
import View from "ui/View"
import { useHasTeamFeature } from "utils/team"

const CompletedKitExpandedContentRevamp = styled(function CompletedKitExpandedContentRevamp({
  className,
  kitInstance,
  kitInfo,
  user,
  selectedTeam,
  isSetupStep = false,
}) {
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(
    selectedTeam,
    SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING
  )
  const { data: sessionPictureUrl } = useSessionPicture({
    kitInstanceId: kitInstance.id,
    sessionRealtimeUpdates: sessionRealtimeUpdatesV2,
  })
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  const { setModal } = useModal()

  const isSelectedTeamLead = selectedTeam.team_lead_id === user.id
  const { [FLAGS.REOPEN_SESSION]: reopenSessionFlag } = useFeatures()
  const { mutateAsync: restartKitSessionTestOnly } = useRestartKitSessionTestOnly()
  const navigate = useNavigate()

  const onReopenSession = async () => {
    await restartKitSessionTestOnly({ kitInstanceId: kitInstance.id })
    navigate(kitInstance.home_url)
  }

  const openViewPhotoModal = () => {
    setModal({
      content: <TeamPhotoModalContent kitInfo={kitInfo} sessionPictureUrl={sessionPictureUrl} />,
      size: "medium",
      showFrame: false,
    })
  }
  const links = [
    kitInfo.has_results && {
      text: "View results",
      url: kitInstance.results_url,
      Icon: ChartUserIcon,
    },
    isSelectedTeamLead && {
      text: "View report",
      url: `/reports/?reportType=session_summary&teamId=${selectedTeam.id}&kitSlug=${kitInfo.slug}`,
      Icon: ChartLineIcon,
    },
    !!sessionPictureUrl && {
      text: "View Photo",
      onClick: openViewPhotoModal,
      Icon: ImageIcon,
    },
    !!sessionPictureUrl && {
      text: "Share photo",
      onClick: () => {
        setModal({
          title: "Share your photo",
          content: <SharePhotoModalContent sessionPictureUrl={sessionPictureUrl} kitInfo={kitInfo} />,
          styles: { content: { overflow: "auto" } },
        })
      },
      Icon: ShareIcon,
    },
    kitInfo.has_bonus && {
      text: "See bonus",
      url: kitInstance.bonus_url,
      Icon: StarIcon,
    },
    kitInfo.has_prep &&
      isSelectedTeamLead && {
        text: "Review training",
        url: kitInstance.prep_url,
        Icon: TrainIcon,
      },
    kitInfo.has_standalone_exercise && {
      text: "Retake exercise",
      url: getStandaloneExerciseUrl({ slug: kitInfo.slug, teamId: selectedTeam.id }),
      Icon: EditIcon,
    },
    !!reopenSessionFlag &&
      !!isSelectedTeamLead && {
        text: "Reopen Session",
        onClick: onReopenSession,
        Icon: DoorOpenIcon,
      },
  ].filter(Boolean)

  return (
    <div className={className}>
      {!!links.length && (
        <View className="full-width" $flexDirectionMobile="column">
          {!isSetupStep && (!!isSelectedTeamLead || !!sessionPictureUrl) && (
            <div
              className={cn("session-picture-container", {
                "mr-large": isMobileLandscapeOrLarger,
                "mb-medium": isMobileOrSmaller,
              })}
            >
              {!!isSelectedTeamLead ? (
                <SessionImage kitInstance={kitInstance} enableRefetch={false} />
              ) : (
                !!sessionPictureUrl && (
                  <img
                    src={sessionPictureUrl}
                    alt={`Your team during the ${kitInfo.title} session`}
                    className="border-radius-small"
                  />
                )
              )}
            </div>
          )}
          <div className="full-width">
            <LinkGrid links={links} hasSessionPicture={!!sessionPictureUrl || !!isSelectedTeamLead} />
          </div>
        </View>
      )}
    </div>
  )
})`
  .session-picture-container {
    --session-picture-width: 350px;
    --session-picture-height: 220px;

    width: var(--session-picture-width);

    img {
      max-width: var(--session-picture-width);
      max-height: var(--session-picture-height);
      object-fit: contain;
      cursor: pointer;
    }

    @media (max-width: ${({ theme }) => theme.mobileLandscapeMax}) {
      width: 100%;

      img {
        max-width: 100%;
      }
    }
  }
`

const SharePhotoModalContent = ({ sessionPictureUrl, kitInfo }) => (
  <div className="space-y-medium">
    <TeamPhotoShareCopy />
    <img
      src={sessionPictureUrl}
      alt={`Your team during the ${kitInfo.title} session`}
      className="full-width border-radius-small"
    />
    <SocialShareButtons url={sessionPictureUrl} />
  </div>
)

export default CompletedKitExpandedContentRevamp
