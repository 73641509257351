import fileDownload from "js-file-download"

import Table from "components/Table"
import { getKitUsageTeamsExport, useAccount, useKitUsageTeamReportData } from "resources/billing"
import DownloadCSVLink from "ui/DownloadCSVLink"
import HorizontalRule from "ui/HorizontalRule"
import Loading from "ui/Loading"
import { generateListTooltipTitle } from "ui/Tooltip"
import View from "ui/View"

const KitUsageTeamReport = ({
  className,
  accountId,
  accountTags,
  multiAccountIds,
  kitSlug,
  teamId,
  teamTags,
  includeArchivedTeams,
}) => {
  const { data: kitUsageTeamData, isFetching } = useKitUsageTeamReportData({
    accountId,
    accountTags,
    multiAccountIds,
    kitSlug,
    teamId,
    teamTags,
    includeArchivedTeams,
  })

  // TODO(evnp): should this work with multiple accounts and account tags?
  const { data: account, isFetching: isFetchingAccount } = useAccount(accountId)

  if ((!kitUsageTeamData && isFetching) || (!account && isFetchingAccount)) {
    return <Loading />
  }

  const isConnectAccount = account?.is_connect_account

  const downloadCSVExport = async () => {
    const csvExport = await getKitUsageTeamsExport({
      accountId,
      accountTags,
      multiAccountIds,
      kitSlug,
      teamId,
      teamTags,
      includeArchivedTeams,
    })
    fileDownload(csvExport, "teams_export.csv")
  }

  return (
    <div className={className}>
      <HorizontalRule />
      {!!kitUsageTeamData ? (
        <View $flexDirection="row" className="mb-medium" $alignItems="center">
          <h3 className="mr-medium">Teams</h3>
          {!isConnectAccount && <DownloadCSVLink downloadCSVExport={downloadCSVExport} />}
        </View>
      ) : (
        <h3 className="mb-medium">Teams</h3>
      )}
      <Table
        className={className}
        rows={kitUsageTeamData?.teams ?? []}
        empty="No kit usage data available yet."
        notFound="No matching data"
        maxColumnWidth={230}
        columns={[
          {
            id: "team_name",
            Header: "Team name",
            accessor: "team_name",
          },
          {
            id: "team_lead",
            Header: "Team lead",
            accessor: "team_lead",
          },
          {
            id: "members",
            Header: "Members",
            accessor: (team) => team.member_names.length,
            tooltip: (team) => (team.member_names.length > 0 ? generateListTooltipTitle(team.member_names, 20) : null),
          },
          {
            id: "completed_kits",
            Header: "Total Kits completed",
            accessor: (team) => team.completed_kits.length,
            tooltip: (team) =>
              team.completed_kits.length > 0
                ? generateListTooltipTitle(team.completed_kits.map((kit) => `${kit.title} ${kit.completed_at}`))
                : null,
          },
          {
            id: "completed_development_kits",
            Header: "Development Kits completed",
            accessor: (team) => team.completed_development_kits.length,
            tooltip: (team) =>
              team.completed_development_kits.length > 0
                ? generateListTooltipTitle(
                    team.completed_development_kits.map((kit) => `${kit.title} ${kit.completed_at}`)
                  )
                : null,
          },
          {
            id: "completed_connection_kits",
            Header: "Connection Kits completed",
            accessor: (team) => team.completed_connection_kits.length,
            tooltip: (team) =>
              team.completed_connection_kits.length > 0
                ? generateListTooltipTitle(
                    team.completed_connection_kits.map((kit) => `${kit.title} ${kit.completed_at}`)
                  )
                : null,
          },
          {
            id: "completed_minis",
            Header: "Minis completed",
            accessor: (team) => team.completed_minis.length,
            tooltip: (team) =>
              team.completed_minis.length > 0
                ? generateListTooltipTitle(team.completed_minis.map((kit) => `${kit.title} ${kit.completed_at}`))
                : null,
          },
          {
            id: "scheduled_kits",
            Header: "Kits scheduled",
            accessor: (team) => team.scheduled_kits.length,
            tooltip: (team) =>
              team.scheduled_kits.length > 0
                ? generateListTooltipTitle(team.scheduled_kits.map((kit) => `${kit.title} ${kit.scheduled_time}`))
                : null,
          },
          {
            id: "next_session_date",
            Header: "Next session date",
            accessor: (team) => team.next_kit.date,
          },
          {
            id: "next_scheduled_kit",
            Header: "Next scheduled kit",
            accessor: (team) => team.next_kit.title,
          },
        ]}
      />
    </div>
  )
}

export default KitUsageTeamReport
