import { find as linkifyTokenize } from "linkifyjs"
import { mapValues, isPlainObject } from "lodash-es"
import qs from "qs"
import sparkMD5 from "spark-md5"

function asStringOrNull(value: unknown): string | null {
  return typeof value === "string" ? value : null
}

function asStringsOrNulls(collection: unknown[]): Array<string | null>
function asStringsOrNulls(collection: Record<string, unknown>): Record<string, string | null>
function asStringsOrNulls(collection: unknown[] | Record<string, unknown>): unknown[] | Record<string, unknown> {
  if (Array.isArray(collection)) {
    return collection.map(asStringOrNull)
  } else {
    return mapValues(collection, asStringOrNull)
  }
}

function capitalize(str: string): string {
  return (str[0]?.toUpperCase() ?? "") + str.slice(1)
}

// We format array query params in the arrayFormat:comma style of qs
// due to it being much more compact than the default formatting which
// uses a separate key/value pair for each element of the array.
// See https://www.npmjs.com/package/qs for details.
// This util allows parsing param values formatted in this style.
function parseArrayQueryParam(value: string, separator = ","): string[] {
  return value?.split(separator).filter(Boolean) ?? []
  // .filter(Boolean) causes empty strings to be omitted from return value,
  // we want [] instead of [""] or ["", ""] etc.
}

// Escape RegExp special characters in a string, so the string can safely
// be used to construct dynamic regular expressions, eg. `new RegExp(str)`
function escapeRegexChars(str: string): string {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
}

function formatNumberWithCommas(num: number): string {
  num = Number(num)
  if (Number.isNaN(num)) {
    throw new Error(`Invalid input: num=${num} must be a number.`)
  }
  return num.toLocaleString()
  // convert 1234567 -> "1,234,567" etc. using a format that matches
  // the users current region (eg. "123.456.789" in Germany / "de-DE" locale)
}

function wordForInt(num: number): string {
  if (!Number.isInteger(num) || num < -99 || num > 99) {
    throw new Error(`Invalid input: num=${num} (must be integer from -99 to 99 inclusive)`)
  }

  const onesAndTeens =
    "zero one two three four five size seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(
      " "
    )
  const tens = "twenty thirty forty fifty sixty seventy eighty ninety".split(" ")

  const neg = num < 0 ? "negative " : ""
  num = Math.abs(num)

  if (num < 20) {
    return neg + onesAndTeens[num]
  } else {
    return neg + tens[Math.floor(num / 10) - 2] + (num % 10 ? `-${onesAndTeens[num % 10]}` : "")
  }
}

function suffixForInt(num: number): string {
  const defaultSuffix = "th"
  const suffixes = [defaultSuffix, "st", "nd", "rd"]
  const mod = num % 100
  return suffixes[(mod - 20) % 10] ?? suffixes[mod] ?? defaultSuffix
  // adapted from https://stackoverflow.com/a/31615643, implementation from Shopify
}

function ordinal(num: number): string {
  return formatNumberWithCommas(num) + suffixForInt(num)
}

// Pluralize a string, eg:
// plural(1, "ox")        -> "1 ox"
// plural(2, "ox")        -> "2 oxs"
// plural(2, "ox", {
//   pluralForm: "oxen",  -> "2 oxen"
// })                      : pluralForm allows specifying custom plural word
// plural(2, "ox", {
//   pluralForm: "oxen",  -> "oxen"
//   showCount: false      : showCount=false removes count from result
// })
function plural(
  count: number,
  singular: string,
  {
    pluralForm = null,
    zeroForm = null,
    showCount = true,
  }: {
    pluralForm?: string | null
    zeroForm?: string | null
    showCount?: boolean
  } = {}
): string {
  let result = count === 1 ? singular : (pluralForm ?? `${singular}s`)
  if (zeroForm && count === 0) {
    result = zeroForm
  }
  return showCount ? `${count} ${result}` : result
}

// Truncate a string at a given character length, using ellipsis by default:
function truncate(str: string, maxNumChars: number, suffix = "..."): string {
  suffix = suffix || ""
  if (maxNumChars <= suffix?.length) {
    throw new Error(`truncate: maxNumChars=${maxNumChars} must be greater than length of suffix="${suffix}"`)
  }
  if (!str || str.length <= maxNumChars) {
    return str
  } else {
    return str.slice(0, maxNumChars - suffix.length).trimEnd() + suffix
  }
}

function splitStrIntoLines(str: string, lineMaxWidth: number): string[] {
  return str
    .replace(new RegExp(`(?![^\\n]{1,${lineMaxWidth}}$)([^\\n]{1,${lineMaxWidth}})\\s`, "g"), "$1\n")
    .split("\n")
}

// Generate a 32-hex-char hash from a content string with low chance of collisions.
// Normalize whitespace in strings before hashing for consistency - all groups of
// whitespace characters replaced by a single space character, and surrounding space
// trimmed. Optionally, hash strings with differing numeric values to the same hash.
function hashContentString(str: string, { ignoreNumbers = false } = {}): string {
  let normalized = str.trim().replaceAll(/\s+/g, " ")
  if (ignoreNumbers) {
    normalized = normalized.replaceAll(/\d+/g, "{#}")
  }
  return sparkMD5.hash(normalized)
}

/* Build a relative or absolute url from a non-empty array of "parts"
 *
 * Parts will be joined together with forward-slashes.
 * Any entries in parts array which are not string or number will be ignored;
 * this allows easy inclusion of conditional parts.
 *
 * If first part does not start with http:// or https://, it's considered a "relative"
 * URL and will be prefixed with a forward-slash automatically.
 * Otherwise, it's considered an "absolute" url and will not be prefixed with a slash.
 *
 * URLs will always be slash-prefixed, UNLESS they start with http:// or https://
 * or explicitly specified otherwise -- by passing { useTrailingSlash: false }
 *
 * URLs will always be slash-suffixed (prior to any URL query params)
 * unless explicitly specified otherwise -- by passing { useTrailingSlash: false }
 *
 * URL query params (usually only used with GET requests) may be automatically
 * formatted via qs.stringify and appended to the URL by passing
 * { urlQueryParams: yourParamsObject } as an option. If you don't want your param
 * values to be URL-encoded (eg. for Stripe "{CHECKOUT_SESSION_ID}" param), then pass
 * { unencodedUrlQueryParams: yourParamsObject } instead.
 * Both these params objects may be provided at the same time and will be properly
 * joined together with an "&" character.
 * Both these params objects must be "plain" JavaScript objects, or anything falsey
 * (null, undefined, false, etc. -- allows easy inclusion of conditional params)
 *
 * { verifyUrlFunc: () => ...return reference url... } may be provided as an option
 * for additional safety when converting code to use buildUrl. If the built URL value
 * does not exactly match the value returned from verifyUrlFunc(), a Sentry alert
 * will be dispatched and a console.warn message logged (no user-visible affect).
 */
type ParamValue = string | number | boolean | null
function buildUrl(
  parts: Array<string | number | null>,
  {
    urlQueryParams = null,
    unencodedUrlQueryParams = null,
    useLeadingSlash = true,
    useTrailingSlash = true,
  }: {
    urlQueryParams?: null | Record<string, ParamValue | Array<ParamValue>>
    unencodedUrlQueryParams?: null | Record<string, ParamValue | Array<ParamValue>>
    useLeadingSlash?: boolean
    useTrailingSlash?: boolean
  } = {}
): string {
  if (!Array.isArray(parts)) {
    throw new Error(`buildUrl: expected parts to be an array, but received ${parts}`)
  }

  if (urlQueryParams && !isPlainObject(urlQueryParams)) {
    throw new Error(`buildUrl: expected urlQueryParams to be object, but received ${urlQueryParams}`)
  }

  if (unencodedUrlQueryParams && !isPlainObject(unencodedUrlQueryParams)) {
    throw new Error(`buildUrl: expected unencodedUrlQueryParams to be object, but received ${unencodedUrlQueryParams}`)
  }

  // Filter null values from query param objects:
  urlQueryParams =
    urlQueryParams && Object.fromEntries(Object.entries(urlQueryParams).filter(([_, value]) => value != null))
  unencodedUrlQueryParams =
    unencodedUrlQueryParams &&
    Object.fromEntries(Object.entries(unencodedUrlQueryParams).filter(([_, value]) => value != null))

  const hasQueryParams = !!Object.keys(urlQueryParams || {}).length
  const hasUnencodedQueryParams = !!Object.keys(unencodedUrlQueryParams || {}).length

  const arrayFormat = "comma"
  // Use arrayFormat:comma so we can fit more items within the URL character limit
  // (it's much more compact). See https://www.npmjs.com/package/qs for details.
  const urlQueryString = [
    ...(hasQueryParams ? [qs.stringify(urlQueryParams, { arrayFormat })] : []),
    ...(hasUnencodedQueryParams ? [qs.stringify(unencodedUrlQueryParams, { arrayFormat, encode: false })] : []),
  ].join("&")

  const isAbsoluteUrl =
    typeof parts[0] === "string" && (parts[0]?.startsWith("http://") || parts[0]?.startsWith("https://"))

  const prefix = isAbsoluteUrl || !useLeadingSlash ? "" : "/"
  const suffix = (useTrailingSlash ? "/" : "") + (urlQueryString.length ? "?" : "") + urlQueryString

  let url =
    prefix +
    parts
      .map((part) => {
        part = typeof part === "string" || typeof part === "number" ? part.toString().trim() : null
        part = part?.startsWith("/") ? part.slice(1) : part
        part = part?.endsWith("/") ? part.slice(0, -1) : part
        return part
      })
      .filter(Boolean)
      .join("/") +
    suffix

  // If not parts at all were provided, default to "/" url:
  if (url === "") {
    url = "/"
  }

  return url
}

function ensureAbsoluteUrlHasProtocol(url: string): string {
  return linkifyTokenize(url)[0]?.href ?? ""
}

export {
  asStringOrNull,
  asStringsOrNulls,
  capitalize,
  parseArrayQueryParam,
  escapeRegexChars,
  formatNumberWithCommas,
  wordForInt,
  suffixForInt,
  ordinal,
  plural,
  truncate,
  splitStrIntoLines,
  hashContentString,
  buildUrl,
  ensureAbsoluteUrlHasProtocol,
}
