import { compareAsc, parseISO, compareDesc } from "date-fns"

import { getKitInstancesCacheKey, getScheduleNextCacheKey } from "resources/monthly_kit"

const KIT_STATUS = {
  COMPLETE: "complete",
  SCHEDULED: "scheduled",
  AVAILABLE: "available",
  IN_PROGRESS: "in_progress",
  UNAVAILABLE: "unavailable",
}

const getKitStatus = (kitInstance, kit) =>
  kit.unavailable
    ? KIT_STATUS.UNAVAILABLE
    : kitInstance?.session_completed_at
      ? KIT_STATUS.COMPLETE
      : kitInstance?.session_started_at
        ? KIT_STATUS.IN_PROGRESS
        : kitInstance?.scheduled_time
          ? KIT_STATUS.SCHEDULED
          : KIT_STATUS.AVAILABLE

const kitStatusSortMap = {
  [KIT_STATUS.IN_PROGRESS]: {
    groupOrder: 1,
    matchedStatusComparator: compareDesc,
    matchedStatusDateField: "session_started_at",
  },
  [KIT_STATUS.SCHEDULED]: {
    groupOrder: 2,
    matchedStatusComparator: compareAsc,
    matchedStatusDateField: "scheduled_time",
  },
  [KIT_STATUS.AVAILABLE]: {
    groupOrder: 3,
  },
  [KIT_STATUS.COMPLETE]: {
    groupOrder: 4,
    matchedStatusComparator: compareDesc,
    matchedStatusDateField: "session_completed_at",
  },
  [KIT_STATUS.UNAVAILABLE]: {
    groupOrder: 5,
  },
}
const demoKitStatusSortMap = {
  ...kitStatusSortMap,
  [KIT_STATUS.COMPLETE]: {
    ...kitStatusSortMap[KIT_STATUS.COMPLETE],
    groupOrder: 3,
  },
  [KIT_STATUS.AVAILABLE]: { groupOrder: 4 },
}

const sortKits = (kits, kitInstanceMap) => [...kits].sort(compareKits({ kitInstanceMap, sortMap: kitStatusSortMap }))
const sortDemoKits = (kits, kitInstanceMap) =>
  [...kits].sort(compareKits({ kitInstanceMap, sortMap: demoKitStatusSortMap }))

const compareKits =
  ({ kitInstanceMap, sortMap }) =>
  (kit1, kit2) => {
    const kitInstance1 = kitInstanceMap[kit1.slug]
    const kitInstance2 = kitInstanceMap[kit2.slug]
    const kitStatus1 = getKitStatus(kitInstance1, kit1)
    const kitStatus2 = getKitStatus(kitInstance2, kit2)

    if (kitStatus1 === kitStatus2) {
      const { matchedStatusComparator, matchedStatusDateField } = sortMap[kitStatus1]
      if (matchedStatusComparator && matchedStatusDateField) {
        return matchedStatusComparator(
          parseISO(kitInstance1[matchedStatusDateField]),
          parseISO(kitInstance2[matchedStatusDateField])
        )
      }
    }

    const { groupOrder: groupOrder1 } = sortMap[kitStatus1]
    const { groupOrder: groupOrder2 } = sortMap[kitStatus2]

    return groupOrder1 - groupOrder2
  }

const getRefetchKitHomeKitsFunction = (teamId, queryClient) => () => {
  const refetchKeys = [getKitInstancesCacheKey({ teamId }), getScheduleNextCacheKey({ teamId })]
  refetchKeys.forEach((queryKey) => queryClient.refetchQueries({ queryKey }))
}

export { KIT_STATUS, getKitStatus, sortKits, sortDemoKits, getRefetchKitHomeKitsFunction }
