import { Link } from "react-router-dom"
import { styled } from "styled-components"

import View from "ui/View"

const UnavailableKitExpandedContentRevamp = styled(function UnavailableKitExpandedContentRevamp({ user, className }) {
  const isAdmin = user.can_manage_billing_for_an_account

  return (
    <div className={className}>
      <View $alignItems="center" $alignItemsMobile="flex-start" $flexDirectionMobile="column-reverse">
        {!!isAdmin && (
          <Link to="/subscribe" className="text-semi-bold">
            See pricing
          </Link>
        )}
      </View>
    </div>
  )
})`
  .upgrade-confirmation-box {
    background: rgba(255 210 87 / 10%);
  }
`

export default UnavailableKitExpandedContentRevamp
