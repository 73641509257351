import cn from "classnames"
import { styled } from "styled-components"

import View from "ui/View"

const RankOrderFieldTopNHighlight = styled(function RankOrderFieldTopNHighlight({
  identifier,
  count,
  exerciseInstance,
  className,
}) {
  const values = exerciseInstance?.answers?.find((answer) => answer.identifier === identifier)?.data ?? []
  if (!values.length) {
    return null
  }

  return (
    <div className={className}>
      {values.map((value, index) => {
        const itemNumber = index + 1
        const highlighted = itemNumber <= count
        const isFirstNonHighlight = itemNumber === count + 1
        return (
          <RankOrderItem
            key={index}
            itemNumber={itemNumber}
            highlighted={highlighted}
            className={cn(className, "border-top", { "first-non-highlight": isFirstNonHighlight })}
          >
            {value}
          </RankOrderItem>
        )
      })}
    </div>
  )
})`
  .first-non-highlight {
    border-top: 1px dashed var(--rising-orange);
  }
`

const RankOrderItem = ({ itemNumber, children, highlighted, className }) => (
  <View className={cn(className, "py-medium px-xs", { "bg-gray-5 text-gray-7": !highlighted })}>
    <div className={cn("mr-medium", { "text-rising-orange": !!highlighted })}>{itemNumber}</div>
    <div>{children}</div>
  </View>
)

export default RankOrderFieldTopNHighlight
