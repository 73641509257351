import { useKitSession } from "domains/KitSession/KitSessionContext"
import ChoicesField from "forms/fields/ChoicesField"
import { useSessionOrTeamExerciseInstances } from "resources/exercise"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import { useHasTeamFeature } from "utils/team"

const ExerciseRadioGroupField = ({
  name,
  kit,
  options,
  option_exercise_field_id,
  option_exercise_field_save_as_text,
  subtitle_exercise_field_id,
  ...props
}) => {
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdates } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_ANSWER_UPDATE)
  const { data: exerciseInstances = [], isInitialLoading } = useSessionOrTeamExerciseInstances(kit.exercise.slug, {
    refetchInterval: 30000,
    enabled: !!option_exercise_field_id,
    sessionRealtimeUpdates,
  })

  if (!!option_exercise_field_id && isInitialLoading) {
    return null
  }

  const exerciseFieldOptions = exerciseInstances
    .map((instance) => {
      const answer = instance.answers.find((answer) => answer.identifier === option_exercise_field_id)

      if (!answer?.data?.value) {
        return null
      }

      const subtitleValue =
        !!subtitle_exercise_field_id &&
        instance.answers.find((answer) => answer.identifier === subtitle_exercise_field_id)?.data?.value

      return {
        value: option_exercise_field_save_as_text ? answer.data.value : (answer.id?.toString() ?? answer.id),
        label: answer.data.value + (subtitleValue ? `: ${subtitleValue}` : ""),
      }
    })
    .filter(Boolean)

  if (!!option_exercise_field_id && !exerciseFieldOptions.length) {
    return <p className="text-gray-8 text-italic">No response given.</p>
  }

  return (
    <ChoicesField
      name={`${name}.value`}
      type="radio"
      className="space-y-small"
      options={!!exerciseFieldOptions.length ? exerciseFieldOptions : options}
      {...props}
    />
  )
}

export default ExerciseRadioGroupField
