import * as Sentry from "@sentry/browser"
import cn from "classnames"
import { Form, Formik } from "formik"
import fileDownload from "js-file-download"
import { range, sortBy } from "lodash-es"
import { useState, useCallback } from "react"
import { Navigate, useNavigate } from "react-router"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import { useRTBrandName } from "components/AccountTypeContext"
import SearchInput from "components/SearchInput"
import EditTeamForm from "domains/Admin/EditTeamForm"
import ManageAdminsTable from "domains/Admin/ManageAdminsTable"
import TeamModal from "domains/Admin/TeamModal"
import TeamsTable from "domains/Admin/TeamsTable"
import {
  canUserManageAccount,
  canUserInviteTeamLeads,
  canUserSeeAccountMaxTeams,
  canUserPurchaseAccountTeams,
  canUserRequestMoreAccountTeams,
  canUserManageAccountBilling,
  canUserUpdateAccountSubscription,
  canUserCancelAccountSubscription,
  canUserReactivateAccountSubscription,
  canUserManageAllAccountsStaffOnly,
  getAdminUrl,
  hasAccountMaxTeamsRemaining,
  numAccountMaxTeamsRemaining,
  numAccountMaxTeams,
  parseIncludeAllAccountsQueryParam,
  createIncludeAllAccountsQueryParam,
  canUserSeeAccountMaxUsers,
  numAccountMaxUsers,
  numAccountCurrentUsers,
} from "domains/Admin/utils"
import AdvancedSelectField from "forms/fields/AdvancedSelectField"
import { Choice } from "forms/fields/ChoicesField"
import EmailField from "forms/fields/EmailField"
import SelectField from "forms/fields/SelectField"
import TextField from "forms/fields/TextField"
import ValidatedField from "forms/fields/ValidatedField"
import handleErrors from "forms/handleErrors"
import Yup from "forms/yup"
import { PlusIcon } from "icons/FontAwesomeIcons"
import {
  useAccount,
  useUpdateAccount,
  useAccountTeams,
  useAccountAdmins,
  useAccountTeamLeads,
  previewPurchaseAccountQuantity,
  usePurchaseAccountQuantity,
  useAdminAccounts,
  useInviteTeamLeads,
  useInviteAdmin,
  getCustomerPortalSession,
  useReactivateSubscription,
  getAccountTeamsExport,
  createSelectFieldSearchAccountsFunction,
  getAccountUsersExport,
} from "resources/billing"
import { useUser } from "resources/users"
import BulletedList from "ui/BulletedList"
import Button from "ui/Button"
import DownloadCSVLink from "ui/DownloadCSVLink"
import useEffectAfterChange from "ui/hooks/useEffectAfterChange"
import useFeatures from "ui/hooks/useFeatures"
import useQueryParams from "ui/hooks/useQueryParams"
import HorizontalRule from "ui/HorizontalRule"
import Loading from "ui/Loading"
import Modal from "ui/Modal"
import { useSelectedTeam } from "ui/SelectedTeamContext"
import SubmitButton from "ui/SubmitButton"
import { colors } from "ui/theme"
import Tooltip from "ui/Tooltip"
import View from "ui/View"
import { formatTimestampAsDate } from "utils/date"

const AdminHome = styled(function AdminHome({ className }) {
  const navigate = useNavigate()
  const { accountId, includeAllAccounts, inviteLeads } = useQueryParams()
  const parsedIncludeAllAccounts = parseIncludeAllAccountsQueryParam(includeAllAccounts)
  const { data: user } = useUser({ userId: "me" })
  const { data: accounts, accountsIsFetching } = useAdminAccounts()
  const { data: account, accountIsFetching } = useAccount(accountId)
  const { mutateAsync: reactivateSubscription } = useReactivateSubscription(accountId)
  const [teamsQuery, setTeamsQuery] = useState([])
  const features = useFeatures()
  const { data: teams } = useAccountTeams(accountId)
  const { selectedTeam: selectedTeamOrNull } = useSelectedTeam()

  if (!account && accountIsFetching) {
    return <Loading />
  }

  if ((!accounts && accountsIsFetching) || !user) {
    return <Loading />
  }

  if (!accounts?.length) {
    return null
  }

  const enableAsyncAccountSearch = canUserManageAllAccountsStaffOnly(user, features)
  if (enableAsyncAccountSearch && account && !accounts.find((a) => a.id === account.id)) {
    accounts.push(account)
  }

  const sortedAccounts = sortBy(accounts, ({ name }) => name.toLowerCase())
  if (!accountId) {
    const defaultAccount = sortedAccounts.filter((a) => a.id === selectedTeamOrNull?.account_id)[0] ?? sortedAccounts[0]
    const accountId = defaultAccount.id
    return <Navigate to={getAdminUrl({ accountId, includeAllAccounts, inviteLeads })} replace />
  }

  if (!account) {
    return null
  }

  const isConnectAccount = account.is_connect_account

  const onIncludeInactiveAccountsChange = (e) => {
    const checked = !!e.target.checked
    const includeAllAccounts = !checked
      ? null
      : createIncludeAllAccountsQueryParam({
          activeAccounts: true,
          activeAndInactiveAccounts: true,
        })
    navigate(getAdminUrl({ accountId, includeAllAccounts, inviteLeads }))
  }

  const asyncAccountSearchFunction = enableAsyncAccountSearch
    ? createSelectFieldSearchAccountsFunction({
        includeInactiveAccounts: parsedIncludeAllAccounts.activeAndInactiveAccounts,
      })
    : null

  const onBillingClick = async () => {
    const returnUrl = window.location.href
    const data = await getCustomerPortalSession(account.id, returnUrl)
    window.location.href = data.url
  }

  const downloadTeamsCSVExport = async () => {
    const csvExport = await getAccountTeamsExport(accountId)
    fileDownload(csvExport, "admin_teams_export.csv")
  }

  const downloadUsersCSVExport = async () => {
    const csvExport = await getAccountUsersExport(accountId)
    fileDownload(csvExport, "admin_users_export.csv")
  }

  const hasMultipleAccounts = sortedAccounts.length > 1
  const hasZeroTeams = !teams?.length
  const hasMultipleTeams = !hasZeroTeams && teams.length > 1
  const teamsLoading = !Array.isArray(teams)

  return (
    <div className={className}>
      <h1 className="mb-large">Admin</h1>
      {canUserManageAllAccountsStaffOnly(user, features) && (
        <View className="mb-medium">
          <Choice
            type="checkbox"
            label="Include Inactive Accounts (internal use only)"
            onChange={onIncludeInactiveAccountsChange}
            checked={parsedIncludeAllAccounts.activeAndInactiveAccounts}
          />
        </View>
      )}
      {(!!hasMultipleAccounts || !!enableAsyncAccountSearch) && (
        <>
          <h5 className="mb-small">Billing account name</h5>
          <AccountField
            className="mb-medium"
            accounts={sortedAccounts}
            currentAccount={account}
            includeAllAccounts={includeAllAccounts}
            asyncSearchFunction={asyncAccountSearchFunction}
            editable={canUserManageAccount(account, user, features)}
          />
        </>
      )}
      {teamsLoading ? (
        <Loading />
      ) : (
        <>
          <View $justifyContent="space-between" $flexWrap="wrap" $gap="var(--spacing-2)" className="my-medium">
            <View $alignItems="center">
              <h2 className="manage-teams-section text-nowrap mr-medium">Teams</h2>
              {!!hasMultipleTeams && !isConnectAccount && (
                <DownloadCSVLink downloadCSVExport={downloadTeamsCSVExport} />
              )}
            </View>
            {!!hasMultipleTeams && (
              <SearchInput
                key={0}
                className="manage-teams-search flex-basis-0"
                query={teamsQuery}
                setQuery={setTeamsQuery}
                placeholder="Search for a team"
              />
            )}
          </View>
          <ManageTeams account={account} user={user} teams={teams} query={teamsQuery} />
          {canUserInviteTeamLeads(account, user, teams, features) && (
            <>
              <h2 className="invite-leads-section mt-xl mb-medium">
                Invite {hasZeroTeams ? "your" : "more"} team leads
              </h2>
              <ManageTeamLeads account={account} />
            </>
          )}
        </>
      )}
      {canUserManageAccount(account, user, features) && (
        <>
          <h2 className="admins-section mt-xxl">Admins</h2>
          <ManageAdmins account={account} user={user} />
          {canUserSeeAccountMaxUsers(account, user, features) && (
            <>
              <View $alignItems="center">
                <h2 className="mr-medium">People</h2>
                <DownloadCSVLink downloadCSVExport={downloadUsersCSVExport} />
              </View>
              <span className="text-nowrap">
                {numAccountCurrentUsers(account, teams)} of {numAccountMaxUsers(account)} registered seats in use
              </span>
            </>
          )}
          {canUserManageAccountBilling(account, user, features) &&
            (canUserUpdateAccountSubscription(account, user, features) ||
              canUserCancelAccountSubscription(account, user, features) ||
              canUserReactivateAccountSubscription(account, user, features)) && (
              <>
                <h2 className="mb-medium mt-xxxl">Billing</h2>
                <div className={cn(className, "mb-xl text-nowrap")}>
                  {canUserUpdateAccountSubscription(account, user, features) && (
                    <Button className="tertiary" onClick={onBillingClick}>
                      View Billing
                    </Button>
                  )}
                  {canUserReactivateAccountSubscription(account, user, features) && (
                    <Button className="tertiary" onClick={reactivateSubscription}>
                      Reactivate Subscription
                    </Button>
                  )}
                  {canUserCancelAccountSubscription(account, user, features) && (
                    <Link to={`/admin/${account.id}/cancel`}>
                      <Button className="tertiary">Cancel Subscription</Button>
                    </Link>
                  )}
                </div>
              </>
            )}
        </>
      )}
    </div>
  )
})`
  .manage-teams-search {
    margin-top: -6px;
  }
`

const PurchaseAccountQuantityModal = styled(function PurchaseAccountQuantityModal({
  account,
  isOpen,
  onRequestClose,
  className,
}) {
  const initialValues = { new_quantity: account.product.quantity + 1 }

  const [isPreviewFetching, setPreviewFetching] = useState(true)
  const { mutateAsync: purchaseAccountQuantity } = usePurchaseAccountQuantity(account.id)
  const [purchaseAccountQuantityLoading, setPurchaseAccountQuantityLoading] = useState(false)
  const [purchaseAccountQuantityError, setPurchaseAccountQuantityError] = useState(false)
  const [newAccountQuantity, setNewAccountQuantity] = useState(initialValues.new_quantity)
  const [previewData, setPreviewData] = useState(null)
  const isStripeSubscriptionActive = !!previewData?.is_stripe_subscription_active
  const proratedAmount = ((previewData?.prorated_amount ?? 0) / 100).toFixed(2)
  const nextInvoiceAmount = ((previewData?.next_invoice_amount ?? 0) / 100).toFixed(2)
  const nextInvoiceDate = !previewData ? null : formatTimestampAsDate(previewData.next_invoice_date * 1000)
  const prorationDate = previewData?.proration_date ?? null
  const subscriptionType = account.product.subscription_type
  const payPeriodNounText = subscriptionType === "YEARLY" ? "year" : subscriptionType === "MONTHLY" ? "month" : "period"
  const payPeriodAdverbText =
    subscriptionType === "YEARLY" ? "yearly" : subscriptionType === "MONTHLY" ? "monthly" : "recurring"

  const onSubmit = handleErrors(
    async (values) => {
      const confirmMessage = [
        "Your payment method on file will be charged $",
        proratedAmount,
        " immediately.\nStarting ",
        nextInvoiceDate,
        ", you will be charged $",
        nextInvoiceAmount,
        " per ",
        payPeriodNounText,
        ".\nPress OK to continue:",
      ].join("")
      if (window.confirm(confirmMessage)) {
        setPurchaseAccountQuantityError(false)
        setPurchaseAccountQuantityLoading(true)
        await purchaseAccountQuantity({ ...values, proration_date: prorationDate })
        setPurchaseAccountQuantityLoading(false)
        onRequestClose()
      }
    },
    (_data, _values, _formik, err) => {
      setPurchaseAccountQuantityLoading(false)
      setPurchaseAccountQuantityError(true)
      Sentry.captureException(err)
      return null
    }
  )

  const onUpdatePaymentInformation = async () => {
    const returnUrl = window.location.href
    const data = await getCustomerPortalSession(account.id, returnUrl)
    window.location.href = data.url
  }

  const onAccountQuantityChange = async (ev) => {
    let newAccountQuantity = ev?.target?.value ?? initialValues.new_quantity
    newAccountQuantity = newAccountQuantity && parseInt(newAccountQuantity)
    if (
      Number.isInteger(newAccountQuantity) &&
      newAccountQuantity > account.product.quantity &&
      newAccountQuantity <= 10
    ) {
      setNewAccountQuantity(newAccountQuantity)
      setPreviewFetching(true)
      const preview = await previewPurchaseAccountQuantity(account.id, newAccountQuantity)
      setPreviewFetching(false)
      setPreviewData(preview)
    }
  }

  const onAccountQuantityChangeCallback = useCallback(onAccountQuantityChange, [
    account.id,
    account.product.quantity,
    initialValues.new_quantity,
  ])

  // Fetch initial preview with starting quantity increase value:
  useEffectAfterChange(() => {
    if (isOpen) {
      onAccountQuantityChangeCallback()
    }
  }, [isOpen, onAccountQuantityChangeCallback])

  const closeModal = () => {
    setPurchaseAccountQuantityError(false)
    onRequestClose()
  }

  return (
    <Modal
      className={className}
      title="Buy more"
      isOpen={isOpen}
      onRequestClose={closeModal}
      content={
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values }) => (
            <Form>
              {!!purchaseAccountQuantityLoading && <Loading className="quantity-form-loading" />}
              <div
                className={cn("quantity-form", {
                  disabled: !isStripeSubscriptionActive || isPreviewFetching || purchaseAccountQuantityLoading,
                })}
              >
                <p className="mb-large mr-xxxxl pr-xxxxl">
                  You can increase the number of teams who can run Rising Team sessions by adding more to your
                  subscription.
                </p>
                <div className="mb-small">
                  <span className="text-semi-bold mr-xs">
                    Current number of teams who can run Rising Team sessions:
                  </span>
                  {account.product.quantity}
                </div>
                <div className="quantity-fields mb-large">
                  <div className="text-semi-bold mb-xs mr-xs">Increase to:</div>
                  <SelectField
                    className="mr-large"
                    name="new_quantity"
                    value={newAccountQuantity}
                    size="medium"
                    onInput={onAccountQuantityChange}
                  >
                    {range(initialValues.new_quantity, 11).map((quantity) => (
                      <option key={quantity} value={quantity}>
                        {quantity}
                      </option>
                    ))}
                  </SelectField>
                </div>
                <div className="my-xl">
                  By increasing your number of seats to <span className="text-semi-bold">{values.new_quantity}</span>:
                  <BulletedList className="mt-xs">
                    <li>
                      a prorated amount of{" "}
                      <span className="text-bold">
                        {isPreviewFetching ? <Loading inline className="value-loading" /> : `$${proratedAmount}`}
                      </span>{" "}
                      will immediately be charged to the payment method you have on file
                    </li>
                    <li>
                      Starting <span className="text-bold">{nextInvoiceDate}</span>, you will be charged a{" "}
                      {payPeriodAdverbText} amount of{" "}
                      <span className="text-bold">
                        {isPreviewFetching ? <Loading inline className="value-loading" /> : `$${nextInvoiceAmount}`}
                      </span>
                    </li>
                  </BulletedList>
                </div>
                {!!purchaseAccountQuantityError && (
                  <div className="quantity-form-error mb-large">
                    <span className="text-danger text-bold">There was an error updating your subscription.</span> Please
                    try updating your payment information by clicking the button below, and try again. Or,{" "}
                    <Link to="/contact" className="text-bold">
                      Contact Us
                    </Link>{" "}
                    for support.
                    <Button className="tertiary mt-medium" onClick={onUpdatePaymentInformation}>
                      Update payment information
                    </Button>
                  </div>
                )}
                <Button type="submit">Save</Button>
                <Button className="tertiary" onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      }
    />
  )
})`
  .quantity-form {
    opacity: 1;
    transition: opacity 0.2s ease;
  }
  .quantity-form.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  .quantity-form-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const RequestAccountQuantityModal = ({ user, account, isOpen, onRequestClose, className }) => {
  const { name, email } = account.owner
  const emailLink = (
    <a className="text-semi-bold" href={`mailto:${email}`}>
      {email}
    </a>
  )
  const ownerDesc = name ? (
    <>
      <span className="text-semi-bold">{name}</span> at {emailLink}
    </>
  ) : (
    emailLink
  )
  return (
    <Modal
      className={className}
      title="Request more"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      content={
        <>
          <p className="mb-medium mr-xxxxl pr-xxxxl">
            {email === user.email ? (
              <>
                Please{" "}
                <Link to="/contact" className="text-bold">
                  Contact Us
                </Link>
              </>
            ) : (
              <>Email {ownerDesc}</>
            )}{" "}
            to increase the number of teams who can run Rising Team sessions.
          </p>
          <div className="mb-xl">
            <span className="text-semi-bold mr-xs">Current number of teams who can run Rising Team sessions:</span>
            {account.product.quantity}
          </div>
          <Button className="tertiary" onClick={onRequestClose}>
            Ok
          </Button>
        </>
      }
    />
  )
}

const ManageTeams = ({ user, account, teams, query }) => {
  const features = useFeatures()
  const rtBrandName = useRTBrandName()
  const [showAddTeamModal, setShowAddTeamModal] = useState(false)
  const [showEditTeamModal, setShowEditTeamModal] = useState(null)
  const [showPurchaseAccountQuantityModal, setShowPurchaseAccountQuantityModal] = useState(false)
  const [showRequestAccountQuantityModal, setShowRequestAccountQuantityModal] = useState(false)
  const canUserAddAccountTeams = account?.can_user_add_account_teams
  const contactDesc = canUserManageAccount(account, user, features)
    ? "Contact us"
    : "Contact your account administrator"
  const maxTeamsMsg = canUserPurchaseAccountTeams(account, user, features)
    ? "You've reached the maximum number of teams. Click 'Buy more' to increase your subscription's team quantity."
    : `You've reached the maximum number of teams. ${contactDesc} if you need to add more.`
  const maxAccountQuantityMsg = `You've reached the maximum team quantity. ${contactDesc} if you need to add more.`

  const onPurchaseAccountQuantityClick = async () =>
    canUserPurchaseAccountTeams(account, user, features) && setShowPurchaseAccountQuantityModal(true)

  const onRequestAccountQuantityClick = async () => setShowRequestAccountQuantityModal(true)

  // If user can invite team leads, don't show form directly on page since takes too much vertical space.
  const showSingleTeamEditFormDirectlyOnPage =
    teams?.length === 1 && !canUserInviteTeamLeads(account, user, teams, features)

  return (
    <div className="mb-medium">
      {canUserManageAccount(account, user, features) && (
        <p className="mb-medium">
          Here are teams and team leads provisioned to run {rtBrandName} sessions for this billing account.
        </p>
      )}
      {showSingleTeamEditFormDirectlyOnPage ? (
        <EditTeamForm className="full-width mb-xl" team={teams[0]} account={account} user={user} />
      ) : (
        <TeamsTable
          className="mb-large"
          account={account}
          user={user}
          teams={teams}
          query={query}
          editFn={(team) => setShowEditTeamModal(team.id)}
        />
      )}
      {!!canUserAddAccountTeams && (
        <>
          {!!showSingleTeamEditFormDirectlyOnPage && <HorizontalRule margin="mb-xl" />}
          <span className="pr-medium">
            <Tooltip
              bottom
              wrap
              className="inline-block fit-content"
              width="auto"
              title={maxTeamsMsg}
              disabled={hasAccountMaxTeamsRemaining(account, teams)}
            >
              <Button
                className={cn("tertiary", { disabled: !hasAccountMaxTeamsRemaining(account, teams) })}
                onClick={() => hasAccountMaxTeamsRemaining(account, teams) && setShowAddTeamModal(true)}
              >
                <PlusIcon />
                Add a team to your account
              </Button>
            </Tooltip>
            {canUserSeeAccountMaxTeams(account, user, features) && (
              <>
                <span className="ml-large mr-xl text-nowrap">
                  {numAccountMaxTeamsRemaining(account, teams)} remaining (of {numAccountMaxTeams(account)})
                </span>
                {canUserPurchaseAccountTeams(account, user, features) ? (
                  <span className="pr-large">
                    <Tooltip
                      bottom
                      wrap
                      className="fit-content"
                      title={maxAccountQuantityMsg}
                      disabled={canUserPurchaseAccountTeams(account, user, features)}
                    >
                      <Button
                        color={colors.risingBlue}
                        className={cn("link-semi-bold", {
                          disabled: !canUserPurchaseAccountTeams(account, user, features),
                        })}
                        onClick={onPurchaseAccountQuantityClick}
                      >
                        Buy more
                      </Button>
                    </Tooltip>
                  </span>
                ) : canUserRequestMoreAccountTeams(account, user, features) ? (
                  <span className="pr-large">
                    <Button
                      color={colors.risingBlue}
                      className="link-semi-bold"
                      onClick={onRequestAccountQuantityClick}
                    >
                      Request more
                    </Button>
                  </span>
                ) : null}
              </>
            )}
          </span>
          <TeamModal
            account={account}
            user={user}
            isOpen={!!showAddTeamModal}
            onRequestClose={() => setShowAddTeamModal(false)}
          />
          {canUserSeeAccountMaxTeams(account, user, features) &&
            (canUserPurchaseAccountTeams(account, user, features) ? (
              <PurchaseAccountQuantityModal
                account={account}
                isOpen={showPurchaseAccountQuantityModal}
                onRequestClose={() => setShowPurchaseAccountQuantityModal(false)}
              />
            ) : (
              <RequestAccountQuantityModal
                user={user}
                account={account}
                isOpen={showRequestAccountQuantityModal}
                onRequestClose={() => setShowRequestAccountQuantityModal(false)}
              />
            ))}
        </>
      )}
      <TeamModal
        account={account}
        user={user}
        isOpen={!!showEditTeamModal}
        team={teams.find((team) => team.id === showEditTeamModal)}
        onRequestClose={() => setShowEditTeamModal(null)}
      />
    </div>
  )
}

const ManageTeamLeads = ({ account }) => {
  let { data: leads, isFetchingLeads } = useAccountTeamLeads(account.id)

  leads = leads ?? []

  if (!account || (!leads && isFetchingLeads)) {
    return <Loading />
  }

  const isPepmAccount = account.is_per_user_billing
  const numPurchased = isPepmAccount ? Math.min(account.product.max_users, 10) : account.product.max_teams
  const numInvites = numPurchased - leads.length

  return (
    <div className="manage-team-leads mb-xxl">
      <p className="mb-large">
        Invite your team leads so they can start planning their sessions. Each person will receive an invitation to set
        up their account and schedule their first session with their team. If you would also like to run sessions with
        your own team, include your email below.
      </p>
      {numInvites > 0 && <InviteTeamLeadsForm billingAccount={account} numInvites={numInvites} />}
    </div>
  )
}

const ManageAdmins = ({ account, user }) => {
  const [showAdminsForm, setShowAdminsForm] = useState(false)
  const { data: acceptedAdmins, isFetchingAccepted } = useAccountAdmins(account.id, { accepted: true })
  const { data: invitedAdmins, isFetchingInvited } = useAccountAdmins(account.id, { accepted: false })
  const rtBrandName = useRTBrandName()

  if ((!acceptedAdmins && isFetchingAccepted) || (!invitedAdmins && isFetchingInvited)) {
    return <Loading />
  }

  return (
    <div className="manage-admins mb-xxl">
      <p className="my-medium">
        In addition to yourself, you may invite additional account administrators by entering their email addresses
        below. Account admins can view reports, invite team leads, and manage billing. If an admin would also like to
        run {rtBrandName} sessions with their own team, they need to be added as a team lead.
      </p>
      <ManageAdminsTable
        className="manage-admins-table my-large"
        account={account}
        user={user}
        acceptedAdmins={acceptedAdmins}
        invitedAdmins={invitedAdmins}
      />
      {!showAdminsForm ? (
        <Button className="add-admin-button tertiary" onClick={() => setShowAdminsForm(true)}>
          <PlusIcon />
          Add another admin
        </Button>
      ) : (
        <InviteAdditionalAdminsForm billingAccount={account} />
      )}
    </div>
  )
}

const InviteTeamLeadsForm = ({ billingAccount, numInvites }) => {
  const { mutateAsync: inviteTeamLeads } = useInviteTeamLeads(billingAccount?.id)

  const emailFieldsData = range(numInvites).map((idx) => {
    const emailNum = idx + 1
    return {
      emailNum,
      fieldName: `email_${emailNum}`,
      label: `Email ${emailNum}`,
    }
  })

  const emailSchema = Yup.object().shape(
    Object.fromEntries(
      emailFieldsData.map((data) => [
        data.fieldName,
        Yup.string().email("Please enter a valid email.").label(data.label),
      ])
    )
  )

  const initialValues = Object.fromEntries(emailFieldsData.map((data) => [data.fieldName, ""]))

  const onSubmit = handleErrors(async (values, formik) => {
    await inviteTeamLeads(values)
    formik.resetForm()
  })

  return (
    <Formik
      validationSchema={emailSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setValues, setErrors }) => (
        <Form name="invite-leads-form" className="mt-large">
          <div className="text-semi-bold">Enter email addresses below</div>
          {emailFieldsData.map((data) => (
            <ValidatedField
              base={EmailField}
              key={data.emailNum}
              className="mt-small"
              name={data.fieldName}
              value={values[`email_${data.emailNum}`]}
              onClear={() => setValues({ [`email_${data.emailNum}`]: "" })}
              onClick={() => setErrors({})}
              width={330}
            />
          ))}
          <SubmitButton label="Send invites" className="tertiary mt-large" />
        </Form>
      )}
    </Formik>
  )
}

const InviteAdditionalAdminsForm = ({ billingAccount }) => {
  const { mutateAsync: inviteAdmin } = useInviteAdmin(billingAccount?.id)
  const emailSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  })

  const initialValues = {
    email: "",
  }

  const onSubmit = handleErrors(async (values, formik) => {
    await inviteAdmin(values)
    formik.resetForm()
  })

  return (
    <Formik
      validationSchema={emailSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setValues, setErrors }) => (
        <Form name="invite-admin-form" className="mt-large">
          <div className="text-semi-bold">Enter email address below</div>
          <ValidatedField
            base={EmailField}
            key="invite-admin-email"
            className="mt-small"
            name="email"
            value={values.email}
            onClear={() => setValues({ email: "" })}
            onClick={() => setErrors({})}
            width={330}
          />
          <SubmitButton label="Send invite" className="tertiary mt-large" />
        </Form>
      )}
    </Formik>
  )
}

const AccountField = styled(function AccountField({
  className,
  accounts,
  currentAccount,
  includeAllAccounts,
  asyncSearchFunction,
  editable = false,
}) {
  const { mutateAsync: updateAccount } = useUpdateAccount(currentAccount.id)
  const [isEditing, setIsEditing] = useState(false)

  const navigate = useNavigate()

  const onAccountChange = ({ value }) => {
    navigate(getAdminUrl({ accountId: value, includeAllAccounts }))
  }

  const onSubmit = handleErrors(async (values) => {
    if (editable) {
      await updateAccount(values)
    }
    setIsEditing(false)
  })

  const enableAsyncAccountSearch = !!asyncSearchFunction
  const accountOptions = [
    ...(enableAsyncAccountSearch ? [{ value: "", label: "Type to search all accounts...", isDisabled: true }] : []),
    ...accounts.map((account) => ({
      value: account.id,
      label: account.id === currentAccount?.id ? currentAccount?.name : account.name,
      // use currentAccount?.name here so that it's updated when renamed
    })),
  ]

  return (
    <div className={className}>
      {editable && isEditing ? (
        <Formik initialValues={{ name: currentAccount?.name ?? "" }} onSubmit={onSubmit}>
          {({ setValues }) => (
            <Form>
              <View $alignItems="center">
                <TextField name="name" autoFocus size="medium" className="mr-large" />
                <SubmitButton color={colors.risingBlue} className="link-semi-bold p-xs">
                  Save
                </SubmitButton>
                <Button
                  className="link-gray p-xs"
                  onClick={() => {
                    setIsEditing(false)
                    setValues({ name: currentAccount?.name ?? "" })
                  }}
                >
                  Cancel
                </Button>
              </View>
            </Form>
          )}
        </Formik>
      ) : (
        <View $alignItems="center">
          <Formik initialValues={{ id: currentAccount?.id ?? null }}>
            <Form>
              <AdvancedSelectField
                className="account-select medium"
                name="id"
                onChange={onAccountChange}
                options={accountOptions}
                asyncSearchFunction={asyncSearchFunction}
                key={currentAccount?.name /* force re-render when account is renamed */}
              />
              {!!editable && (
                <Tooltip bottom wrap className="inline-block fit-content" title="Change account name">
                  <Button
                    color={colors.risingBlue}
                    className="link-semi-bold p-xs ml-large"
                    onClick={() => setIsEditing(true)}
                  >
                    Rename
                  </Button>
                </Tooltip>
              )}
            </Form>
          </Formik>
        </View>
      )}
    </div>
  )
})`
  display: inline-block;
  .account-select {
    display: inline-block;
    width: 330px;
    .advanced-select__control {
      width: 100%;
    }
    .advanced-select__single-value {
      font-size: 16px;
      margin-left: 0;
    }
    &.disabled {
      pointer-events: none;
      .advanced-select__indicators {
        display: none;
      }
    }
  }
`

export default AdminHome
