import * as Sentry from "@sentry/browser"
import { useFormikContext } from "formik"
import { useState } from "react"

import { fetchS3Post } from "api"
import { getAWSRtaiUploadURL } from "resources/users"
import { getImageExtension } from "utils/image"

const MAX_IMAGE_SIZE_MB = 8

const ArtiUpload = ({ onSubmit, setIsArtiUploadAvailable }) => {
  const [isUploading, setIsUploading] = useState(false)
  const formik = useFormikContext()

  async function onUpload(evt) {
    const uploadFile = evt.target.files[0]
    if (!uploadFile) {
      return
    }

    setIsUploading(true)

    const fileType = getImageExtension({ file: uploadFile })

    if (uploadFile.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
      //   helpers.setError(`Image size must be under ${MAX_IMAGE_SIZE_MB}mb`)
      setIsUploading(false)
      return
    }

    const onSuccess = async ({ objectKeyAWS }) => {
      onSubmit(
        {
          message: "Uploaded a file",
          fileName: objectKeyAWS,
        },
        formik
      )
      setIsUploading(false)
      setIsArtiUploadAvailable(false)
    }
    const onError = ({ error }) => {
      setIsUploading(false)
      Sentry.captureException(error)
    }
    const { aws_presigned_data: presignedUploadData, object_key: objectKeyAWS } = await getAWSRtaiUploadURL({
      userId: "me",
      fileType,
    })
    fetchS3Post(presignedUploadData, uploadFile)
      .then((response) => {
        if (response.ok) {
          onSuccess({ objectKeyAWS })
        } else {
          onError({ error: response })
        }
      })
      .catch((error) => {
        onError({ error })
      })
  }

  if (isUploading) {
    return <p>Uploading...</p>
  }

  return (
    <>
      <div className="input-image-wrapper">
        <input
          className="input-image"
          type="file"
          accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, image/*"
          onChange={onUpload}
        ></input>
      </div>
    </>
  )
}

// const getAWSUploadErrorMessage = () => "An error occurred while uploading the file. Please upload again."

export default ArtiUpload
