import { styled } from "styled-components"

import { smileRatingOptions } from "forms/fields/SmileRatingField"
import getIconOrError from "icons"
import Loading from "ui/Loading"
import ScatterPlot from "ui/ScatterPlot"

const PieSliceGroupFieldAggregatedMultiUserScatterPlot = styled(
  function PieSliceGroupFieldAggregatedMultiUserScatterPlot({
    identifiers,
    useAggregatedResultsData,
    has_sensitive_data,
    additional_data,
    className,
  }) {
    const { data: aggregatedResults, isFetching } = useAggregatedResultsData({
      component: "PieSliceGroupFieldAggregatedMultiUserScatterPlot",
      identifiers,
      has_sensitive_data,
      additional_data,
    })

    if (!aggregatedResults && isFetching) {
      return <Loading />
    }

    if (!aggregatedResults) {
      return null
    }

    if (aggregatedResults.insufficient_data) {
      return (
        <p className="p-large bg-gray-1 mt-small">
          To maintain anonymity, we only share results when there are at least three team members with complete results.
        </p>
      )
    }

    const chartData = Object.entries(aggregatedResults).map(([id, { votes, rating_average }]) => ({
      id,
      data: [{ x: votes, y: rating_average }],
    }))
    // const d = [
    //   {
    //     id: 'advancement',
    //     data: [
    //       {
    //         x: 2,
    //         y: 4,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'autonomy',
    //     data: [
    //       {
    //         x: 0,
    //         y: 0,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'compensation',
    //     data: [
    //       {
    //         x: 3,
    //         y: 3,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'contribution',
    //     data: [
    //       {
    //         x: 1,
    //         y: 4,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'flexibility',
    //     data: [
    //       {
    //         x: 0,
    //         y: 0,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'growth',
    //     data: [
    //       {
    //         x: 2,
    //         y: 3,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'mastery',
    //     data: [
    //       {
    //         x: 4,
    //         y: 4,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'people',
    //     data: [
    //       {
    //         x: 0,
    //         y: 0,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'purpose',
    //     data: [
    //       {
    //         x: 3,
    //         y: 2,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'recognition',
    //     data: [
    //       {
    //         x: 0,
    //         y: 0,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'responsibility',
    //     data: [
    //       {
    //         x: 0,
    //         y: 0,
    //       },
    //     ],
    //   },
    //   {
    //     id: 'values',
    //     data: [
    //       {
    //         x: 0,
    //         y: 0,
    //       },
    //     ],
    //   },
    // ]

    const maxXValue = Math.max(...chartData.map(({ data }) => data[0].x))
    const isMaxXValueOdd = maxXValue % 2
    let chartXAxisMax = maxXValue + (maxXValue < 15 || isMaxXValueOdd ? 1 : 2)
    // Ensure the chart x-axis maximum label has a number shown beneath it, not a
    // blank space between two numbers. We do this by ensuring it is either
    // a) lower than 15, which is the point at which Nivo starts skipping numbers
    // b) if greater than 15, it's an even number
    // For even larger x-axis sizes, round to nearest multiple of 5 or 10:
    if (chartXAxisMax >= 30) {
      chartXAxisMax = chartXAxisMax - (chartXAxisMax % 5) + 5 // round up to nearest multiple of 5
    } else if (chartXAxisMax >= 60) {
      chartXAxisMax = chartXAxisMax - (chartXAxisMax % 10) + 10 // round up to nearest multiple of 10
    }

    const matchingPointMap = new Map()
    const annotations = Object.entries(aggregatedResults).map(([key, point]) => {
      const matchingPointKey = JSON.stringify({ votes: point.votes, rating_average: point.rating_average })
      const matchingPointList = (matchingPointMap.get(matchingPointKey) ?? []).concat([point])
      matchingPointMap.set(matchingPointKey, matchingPointList)

      // These thresholds define how close a point must be on X and Y axis to be considered
      // a "nearby point" in a given direction:
      const yNeighborThreshold = 1
      const xNeighborThreshold = chartXAxisMax / 10 // scale relative to x-axis size
      const xyRatio = yNeighborThreshold / xNeighborThreshold
      // They differ because 1 unit of distance on the X axis is usually larger than
      // 1 unit of distance on the Y axis (in widescreen mode).

      // Find nearby points to adjust labels so they don't overlap (as much as possible):
      const neighborsBelow = Object.values(aggregatedResults).find(
        (otherPoint) =>
          otherPoint.label !== point.label &&
          Math.abs(otherPoint.votes - point.votes) <= xNeighborThreshold &&
          point.rating_average >= otherPoint.rating_average &&
          point.rating_average - otherPoint.rating_average <= yNeighborThreshold &&
          Math.abs(point.rating_average - otherPoint.rating_average) >
            Math.abs(otherPoint.votes - point.votes) * xyRatio
        // last line calculation ensures "neighbors below" aren't also considered neighbors left/right
      )
      const neighborsAbove = Object.values(aggregatedResults).find(
        (otherPoint) =>
          otherPoint.label !== point.label &&
          Math.abs(otherPoint.votes - point.votes) <= xNeighborThreshold &&
          point.rating_average <= otherPoint.rating_average &&
          otherPoint.rating_average - point.rating_average <= yNeighborThreshold &&
          Math.abs(otherPoint.rating_average - point.rating_average) >
            Math.abs(otherPoint.votes - point.votes) * xyRatio
        // last line calculation ensures "neighbors above" aren't also considered neighbors left/right
      )
      const neighborsLeft = Object.values(aggregatedResults).find(
        (otherPoint) =>
          otherPoint.label !== point.label &&
          point.votes >= otherPoint.votes &&
          point.votes - otherPoint.votes <= xNeighborThreshold &&
          Math.abs(otherPoint.rating_average - point.rating_average) <= yNeighborThreshold &&
          Math.abs(otherPoint.rating_average - point.rating_average) <
            Math.abs(otherPoint.votes - point.votes) * xyRatio
        // last line calculation ensures "neighbors left" aren't also considered neighbors above/below
      )
      const neighborsRight = Object.values(aggregatedResults).find(
        (otherPoint) =>
          otherPoint.label !== point.label &&
          point.votes <= otherPoint.votes &&
          otherPoint.votes - point.votes <= xNeighborThreshold &&
          Math.abs(otherPoint.rating_average - point.rating_average) <= yNeighborThreshold &&
          Math.abs(otherPoint.rating_average - point.rating_average) <
            Math.abs(otherPoint.votes - point.votes) * xyRatio
        // last line calculation ensures "neighbors right" aren't also considered neighbors above/below
      )

      // Calculate how many points lie at this specific position on the scatter plot,
      // and if there are multiple, adjust the noteY below so that labels for each point
      // appear in a vertical list below the point, instead of overlapping one another:
      const matchingPointsLabelYOffset = (35 / 2) * (matchingPointList.length - 1)

      let notePosition = {
        noteX: -1,
        noteY: 35 + matchingPointsLabelYOffset,
        // adjust vertical position based on pointCount calculated above, so labels don't overlap
        noteWidth: !point?.label?.length ? 0 : point.label.length * 3.5, // magic number to center text labels horizontally
        // noteWidth is undocumented, see:
        // https://github.com/plouc/nivo/blob/master/packages/annotations/src/compute.ts#L170-L175
        // (or search within that file if line numbers are no longer accurate)
        noteTextOffset: 5,
      }

      // Use alternate label positioning if nearby points were found which label will overlap:
      const placeLabelTop = neighborsBelow && !neighborsAbove
      const placeLabelRight = neighborsLeft && !neighborsRight && !placeLabelTop
      const placeLabelLeft = neighborsRight && !neighborsLeft && !placeLabelTop && !placeLabelRight

      if (placeLabelTop) {
        notePosition = {
          ...notePosition,
          noteY: (35 + matchingPointsLabelYOffset) * -1,
          noteTextOffset: -15,
        }
      } else if (placeLabelRight) {
        notePosition = {
          noteX: 20,
          noteY: 13 + matchingPointsLabelYOffset,
        }
      } else if (placeLabelLeft) {
        notePosition = {
          noteX: -30,
          noteY: 12 + matchingPointsLabelYOffset,
          noteWidth: point.label.length * 7,
        }
      }

      return {
        type: "circle",
        match: {
          serieId: key,
        },
        note: point.label,
        offset: 0,
        ...notePosition,
      }
    })

    const dividerAreaLeftHeight = 1.15
    const dividerAreaRightHeight = 2.4
    // make the divider area go up to 24% of the way down the right side of chart
    // so that it aligns with the spot halfway between "Moderately happy" and "Neutral"
    const DividerArea = ({ innerWidth: w, innerHeight: h }) => (
      <path
        d={`M0,${h / dividerAreaLeftHeight}L${w},${h / dividerAreaRightHeight}L${w},${h}L0,${h}`}
        // the path above creates a right triangle covering the lower half of
        // the chart, going from the bottom-left to top-right corner
        fill="var(--rising-orange)"
        style={{ mixBlendMode: "multiply", pointerEvents: "none" }}
        opacity={0.1}
      />
    )

    const YAxisIcons = ({ innerHeight: chartHeight }) => (
      <g className="x-axis-icons">
        {[...smileRatingOptions].reverse().map((option, idx) => {
          const Icon = getIconOrError(option.icon)
          const iconSize = 24
          const positionX = iconSize * 1.5 * -1
          const positionY = (chartHeight / 4) * idx - iconSize / 2
          return (
            <g key={idx} transform={`translate(${positionX},${positionY})`}>
              <Icon key={option.icon} color={option.color} width={`${iconSize}px`} height={`${iconSize}px`} />
            </g>
          )
        })}
      </g>
    )

    return (
      <div className={className}>
        <ScatterPlot
          data={chartData}
          axisLeft={{
            legend: "Average Sentiment",
            format: () => "",
            // blank out x-axis labels so we can replace with smile rating icons
          }}
          axisBottom={{
            legend: "Votes",
            format: (value) => (Number.isInteger(value) ? value : ""),
          }}
          yScale={{ type: "linear", min: 1, max: 5 }}
          xScale={{ type: "linear", min: 0, max: chartXAxisMax }}
          annotations={annotations}
          layers={["grid", "axes", DividerArea, YAxisIcons, "nodes", "markers", "mesh", "legends", "annotations"]}
          theme={{
            annotations: {
              outline: {
                outlineWidth: 0,
                strokeWidth: 0,
              },
              link: {
                outlineWidth: 0,
                strokeWidth: 0,
              },
            },
          }}
          nodeSize={32}
          nodeComponent={({ node }) => {
            const iconSize = 32
            const option = smileRatingOptions[Math.round(node.data.y) - 1] ?? null
            const Icon = option && getIconOrError(option.icon)
            return (
              option && (
                <g transform={`translate(${node.x - iconSize / 2},${node.y - iconSize / 2})`}>
                  <circle cx={iconSize / 2} cy={iconSize / 2} r={iconSize / 2} fill="white" />
                  <Icon key={option.icon} color={option.color} width={`${iconSize}px`} height={`${iconSize}px`} />
                </g>
              )
            )
          }}
          isInteractive={true}
          useMesh={true /* chart tooltips will not work without useMesh=true */}
          tooltip={({ node }) => {
            const matchingPointKey = JSON.stringify({ votes: node.xValue, rating_average: node.yValue })
            const matchingPointList = matchingPointMap.get(matchingPointKey)
            return (matchingPointList?.length ? matchingPointList : [node]).map((point, idx) => {
              const label = point.serieId ?? point.label
              const desc = point.description
              const votes = point.xValue ?? point.votes
              const rating = point.yValue ?? point.rating_average
              const option = smileRatingOptions[Math.round(rating) - 1] ?? null
              const ratingLabel = option?.label
              return (
                <div className="scatter-plot-tooltip" key={label}>
                  {!!idx && <hr />}
                  <div>
                    <strong className="text-capitalize">
                      {label}
                      {!!desc && ":"}
                    </strong>{" "}
                    {desc ?? ""}{" "}
                  </div>
                  <div>
                    <strong>{votes}</strong> votes
                  </div>
                  <div>
                    <strong>{ratingLabel}</strong> on average
                  </div>
                </div>
              )
            })
          }}
        />
      </div>
    )
  }
)`
  height: 485px;

  svg[role="img"] + div {
    margin-top: -1rem; // move tooltips up slightly relative to cursor
  }

  // fix annotations so hovering them doesn't make chart tooltips disappear:
  text {
    pointer-events: none;
  }

  // fix chart width on mobile:
  @media (max-width: ${({ theme }) => theme.tabletMax}) {
    margin-left: -25px;
    margin-right: -120px;
  }
`

export default PieSliceGroupFieldAggregatedMultiUserScatterPlot
