import cn from "classnames"
import { Link } from "react-router-dom"
import { styled } from "styled-components"

import AvailableKitExpandedContentRevamp from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/AvailableKitExpandedContentRevamp"
import CompletedKitExpandedContentRevamp from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/CompletedKitExpandedContentRevamp"
import KitScheduleModeExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/KitScheduleModeExpandedContent"
import RescheduleKitScheduleModeExpandedContent from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/RescheduleKitScheduleModeExpandedContent"
import TMScheduledExpandedContentRevamp from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/TMScheduledExpandedContentRevamp"
import UnavailableKitExpandedContentRevamp from "domains/LeaderKit/KitsTableofContents/components/ExpandedContent/UnavailableKitExpandedContentRevamp"
import KitTypePillRevamp from "domains/LeaderKit/KitsTableofContents/components/KitTypePillRevamp"
import { KIT_STATUS, getKitStatus } from "domains/LeaderKit/KitsTableofContents/utils"
import { BoltIcon, CalendarIcon, CheckIcon, ClockIcon, SpinnerIcon, SquareIcon } from "icons/FontAwesomeIcons"
import { getStandaloneExerciseUrl } from "resources/monthly_kit"
import useWindowSize from "ui/hooks/useWindowSize"
import View from "ui/View"
import { formatTimestampAsDate } from "utils/date"
import { KIT_GOALS_DATA, KitType, formatKitSessionTime } from "utils/kit"

const KitTileRevamp = ({
  kitInfo,
  kitInstance,
  kitStatus,
  exerciseInstance,
  user,
  selectedTeam,
  initiallyExpanded,
  isConnectAccount,
  className,
  isScheduleModeActive,
  isSetupStep = false,
  isCurrentTeamLead,
}) => {
  const windowSize = useWindowSize()
  const kitStatusConfig = getKitStatusConfig(
    kitInfo,
    kitInstance,
    exerciseInstance,
    user,
    selectedTeam,
    isConnectAccount,
    windowSize,
    isScheduleModeActive,
    isSetupStep
  )

  return (
    <KitTileContents
      kitInfo={kitInfo}
      kitInstance={kitInstance}
      kitStatus={kitStatus}
      user={user}
      kitStatusConfig={kitStatusConfig}
      selectedTeam={selectedTeam}
      initiallyExpanded={initiallyExpanded}
      className={className}
      isScheduleModeActive={isScheduleModeActive}
      isSetupStep={isSetupStep}
      isCurrentTeamLead={isCurrentTeamLead}
    />
  )
}

// KitTileContents is split into its own component so we can pass kitStatusConfig
// as a prop to access it in styled-components css
const KitTileContents = styled(function KitTileContents({
  kitInfo,
  kitInstance,
  kitStatus,
  user,
  kitStatusConfig,
  selectedTeam,
  className,
  isScheduleModeActive,
  isSetupStep,
  isCurrentTeamLead,
}) {
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  const homeUrl = kitInstance?.home_url ?? `/kit/${kitInfo.slug}?team_id=${selectedTeam.id}`

  const { linkUrl, ExpandedContent, showGoals } = kitStatusConfig
  const Wrapper = linkUrl && !isScheduleModeActive ? Link : "div"
  const wrapperProps = {}
  if (linkUrl) {
    wrapperProps.to = linkUrl
  }

  const isConnectionKit = kitInfo.type === KitType.BOOST
  const [_firstSentence, ...otherSentences] = kitInfo.description.split(". ")
  const removeFirstSentenceInDescription = otherSentences.join(". ") + (otherSentences.length ? "." : "")
  const kitDescription = isConnectionKit ? removeFirstSentenceInDescription : kitInfo.description
  const minutesText = formatKitSessionTime(kitInfo.session_time_minutes)
  const goals = kitInfo.goals.map((goal) => ({
    slug: kitInfo.slug,
    goal: KIT_GOALS_DATA[goal].title,
    color: KIT_GOALS_DATA[goal].color,
  }))

  return (
    <div className={className}>
      <Wrapper {...wrapperProps}>
        <div className="kit p-medium border-radius-medium">
          <View $alignItems="center" $justifyContent="space-between">
            <div className="full-width">
              <View
                $alignItems="center"
                $alignItemsMobile="flex-start"
                $flexDirectionMobile="column-reverse"
                className={cn("mb-xxs", { "space-x-xs": isMobileLandscapeOrLarger })}
              >
                {isSetupStep ? (
                  <View $flexDirection="column">
                    <View $justifyContent="space-between" $flexDirectionMobile="column">
                      <Link to={homeUrl} className="text-gray-9 kit-title" data-testid={`kit-link:${kitInfo.slug}`}>
                        <h3>{kitInfo.title}</h3>
                      </Link>
                      <View className="text-semi-bold text-gray-8 fit-content" $alignItems="center">
                        <ClockIcon className="mr-xxs" />
                        <span className="minutes-text">{minutesText}</span>
                      </View>
                    </View>
                    <KitTypePillRevamp className="mr-xs mt-xs" selectedKitInfo={kitInfo} />
                  </View>
                ) : (
                  <>
                    {!!isMobileOrSmaller && <KitTypePillRevamp className="mt-xxs" selectedKitInfo={kitInfo} />}
                    <View $justifyContent="space-between" $flexDirectionMobile="column">
                      <View $alignItems="center">
                        {ExpandedContent && !kitInfo.unavailable && !isScheduleModeActive && isCurrentTeamLead ? (
                          <Link to={homeUrl} className="text-gray-9 kit-title" data-testid={`kit-link:${kitInfo.slug}`}>
                            <h3>{kitInfo.title}</h3>
                          </Link>
                        ) : (
                          <h3 className="text-gray-9 kit-title">{kitInfo.title}</h3>
                        )}
                        {!!isMobileLandscapeOrLarger && (
                          <KitTypePillRevamp className="ml-small" selectedKitInfo={kitInfo} />
                        )}
                      </View>
                      <View className="text-semi-bold text-gray-8" $width="200px" $alignItems="center">
                        <ClockIcon className={cn("mr-xxs", { "ml-medium": !!isMobileLandscapeOrLarger })} />
                        <span className="minutes-text">{minutesText}</span>
                      </View>
                    </View>
                  </>
                )}
              </View>
              <View
                $justifyContent="space-between"
                $flexDirection={isSetupStep ? "column" : "row"}
                $flexDirectionMobile="column"
              >
                <View>
                  {kitStatus === "available" || isSetupStep ? (
                    <Link to={homeUrl} className="text-gray-9" data-testid={`kit-link:${kitInfo.slug}`}>
                      <View className="text-gray-9 mb-xs">{kitDescription}</View>
                    </Link>
                  ) : kitStatus !== "complete" ? (
                    <View className="text-gray-9 mb-xs">{kitDescription}</View>
                  ) : null}
                </View>
                <View
                  $flexDirection={isSetupStep ? "row" : "column"}
                  $flexDirectionMobile="row"
                  $flexWrap={isSetupStep ? "wrap" : "initial"}
                  $flexWrapMobile="wrap"
                  $width={!!isMobileLandscapeOrLarger && !isSetupStep ? "200px" : "auto"}
                  className="text-gray-9"
                >
                  {!!showGoals &&
                    goals.map(({ goal, color }) => (
                      <div key={goal} className={cn("goal-text", { "pr-small": !!isMobileOrSmaller || !!isSetupStep })}>
                        <SquareIcon
                          className={cn("mr-xxs rotated-icon", {
                            "ml-medium": !!isMobileLandscapeOrLarger && !isSetupStep,
                          })}
                          color={color}
                        />
                        {goal}
                      </div>
                    ))}
                </View>
              </View>
            </div>
          </View>
          {!isSetupStep && <KitStatus kitStatusConfig={kitStatusConfig} />}
          {!!ExpandedContent && (
            <ExpandedContent
              kitInstance={kitInstance}
              kitInfo={kitInfo}
              user={user}
              selectedTeam={selectedTeam}
              className="mt-xs"
              isScheduleModeActive={isScheduleModeActive}
              isSetupStep={isSetupStep}
            />
          )}
        </div>
      </Wrapper>
    </div>
  )
})`
  .minutes-text,
  .goal-text {
    font-size: 12px;
  }

  .goal-text {
    font-weight: 500;
  }

  .rotated-icon {
    transform: rotate(45deg);
    width: 12.86px;
    height: 11.89px;
  }

  .kit {
    box-shadow: var(--blur-2);

    &:hover {
      box-shadow: var(--lift-4);
    }
  }

  .kit-title {
    &:hover {
      color: var(--rising-blue);
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }) => theme.mobileLandscapeMin}) {
    h3 {
      max-width: 300px;
    }
  }

  @media (min-width: ${({ theme }) => theme.tabletMin}) {
    h3 {
      max-width: 360px;
    }
  }
`

const KitStatus = ({ kitStatusConfig }) => {
  const { color, text, linkText, Icon } = kitStatusConfig
  const { isMobileOrSmaller, isMobileLandscapeOrLarger } = useWindowSize()
  return (
    <>
      <View $alignItems="center" className={cn("text-gray-8 fit-content", { "mb-xxs": isMobileOrSmaller })}>
        {!!Icon && !!text && <Icon color={color} className={cn("mr-xs", { "ml-xs": isMobileLandscapeOrLarger })} />}
        <div>{text}</div>
        {!!isMobileLandscapeOrLarger && !!linkText && (
          <div className="ml-small text-thin text-rising-blue">{linkText}</div>
        )}
      </View>
      {!!isMobileOrSmaller && !!linkText && <div className="text-thin">{linkText}</div>}
    </>
  )
}

const getKitStatusConfig = (
  kitInfo,
  kitInstance,
  exerciseInstance,
  user,
  selectedTeam,
  isConnectAccount,
  windowSize,
  isScheduleModeActive,
  isSetupStep = false
) => {
  const { isMobileOrSmaller } = windowSize
  const scheduledDateFormat = isMobileOrSmaller ? "M/dd/yyyy" : "'for' M/dd/yyyy 'at' h:mm aaa"
  const kitStatus = getKitStatus(kitInstance, kitInfo)

  const isSelectedTeamLead = selectedTeam.team_lead_id === user.id
  const isDemo = selectedTeam.demo

  if (!isSelectedTeamLead && isDemo) {
    return getDemoKitStatusConfig({ kitStatus, kitInstance })
  }

  const isConnectAccountBonusKit = isConnectAccount && kitInfo.type === KitType.KIT

  const statusOptions = {
    [KIT_STATUS.COMPLETE]: {
      showGoals: false,
      color: "var(--orange-4)",
      text: (
        <span className="text-nowrap">
          Completed {!!kitInstance?.session_completed_at && formatTimestampAsDate(kitInstance.session_completed_at)}
        </span>
      ),
      Icon: CheckIcon,
      ...(!isScheduleModeActive
        ? isSelectedTeamLead || !kitInfo.has_standalone_exercise || exerciseInstance
          ? {
              ExpandedContent: CompletedKitExpandedContentRevamp,
            }
          : {
              linkUrl: getStandaloneExerciseUrl({ slug: kitInstance?.basic_kit?.slug, teamId: selectedTeam.id }),
              linkText: "Take exercise",
            }
        : {
            ExpandedContent: KitScheduleModeExpandedContent,
          }),
    },
    [KIT_STATUS.SCHEDULED]: {
      showGoals: true,
      color: "var(--rising-yellow)",
      text: (
        <span className="text-nowrap">
          Scheduled{" "}
          {!!kitInstance?.scheduled_time && formatTimestampAsDate(kitInstance.scheduled_time, scheduledDateFormat)}
        </span>
      ),
      Icon: CalendarIcon,
      ...(isScheduleModeActive
        ? { ExpandedContent: RescheduleKitScheduleModeExpandedContent }
        : !isSelectedTeamLead
          ? { ExpandedContent: TMScheduledExpandedContentRevamp }
          : {
              linkUrl: kitInstance?.home_url,
              linkText: "Get ready",
              // For the kit tile in setup flow, continue to show kit description
              // and scheduled date even after the kit is scheduled:
              ...(isSetupStep ? { ExpandedContent: AvailableKitExpandedContentRevamp } : {}),
            }),
    },
    [KIT_STATUS.AVAILABLE]:
      isSelectedTeamLead || isScheduleModeActive
        ? {
            showGoals: true,
            color: "var(--rising-green)",
            text: selectedTeam.preview ? (
              <span className="text-nowrap">Preview session</span>
            ) : isConnectAccountBonusKit ? (
              // allow wrapping since this can go offscreen on mobile:
              "1 Development Kit included with your subscription!"
            ) : null,
            Icon: BoltIcon,
            ExpandedContent: AvailableKitExpandedContentRevamp,
          }
        : {
            showGoals: true,
            color: "var(--gray-5)",
            text: <span className="text-nowrap">Available to team leads</span>,
            Icon: BoltIcon,
          },
    [KIT_STATUS.IN_PROGRESS]: {
      showGoals: true,
      color: "var(--blue-2)",
      text: <span className="text-nowrap">In progress</span>,
      Icon: SpinnerIcon,
      ...(!isScheduleModeActive
        ? {
            linkUrl: kitInstance?.session_url,
            linkText: <span className="text-nowrap">Rejoin the session</span>,
          }
        : {
            ExpandedContent: KitScheduleModeExpandedContent,
          }),
    },
    [KIT_STATUS.UNAVAILABLE]: {
      showGoals: true,
      color: "var(--gray-5)",
      text: <span className="text-nowrap">Available with {isConnectAccount ? "upgrade" : "subscription"}</span>,
      ExpandedContent: UnavailableKitExpandedContentRevamp,
    },
  }

  return statusOptions[kitStatus]
}

const getDemoKitStatusConfig = ({ kitStatus, kitInstance }) => {
  if (kitStatus === KIT_STATUS.COMPLETE) {
    return {
      color: "var(--orange-4)",
      text: `Completed ${kitInstance?.session_completed_at && formatTimestampAsDate(kitInstance.session_completed_at)}`,
      Icon: CheckIcon,
      ExpandedContent: CompletedKitExpandedContentRevamp,
    }
  }

  return {
    color: "var(--rising-green)",
    text: "Preview session",
    Icon: BoltIcon,
    ExpandedContent: AvailableKitExpandedContentRevamp,
  }
}

export default KitTileRevamp
