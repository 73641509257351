import LinkGrid from "./LinkGrid"

import { EditIcon } from "icons/FontAwesomeIcons"
import { getStandaloneExerciseUrl } from "resources/monthly_kit"

const TMScheduledExpandedContentRevamp = ({ kitInfo, selectedTeam, className }) => {
  const links = [
    kitInfo.has_standalone_exercise && {
      text: "View or take exercise",
      url: getStandaloneExerciseUrl({ slug: kitInfo.slug, teamId: selectedTeam.id }),
      Icon: EditIcon,
    },
  ].filter(Boolean)

  return (
    <div className={className}>
      <div className="full-width">
        <LinkGrid links={links} />
      </div>
    </div>
  )
}

export default TMScheduledExpandedContentRevamp
