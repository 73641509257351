import { useQueryClient } from "@tanstack/react-query"
import { Formik } from "formik"
import { pick, isObject, mapKeys } from "lodash-es"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"

import { useAccountType, useIsSchoolAccountType, useSetAccountType } from "components/AccountTypeContext"
import { TeamMemberRegisterForm } from "domains/GetStarted/Register/RegisterForm"
import { JOB_FUNCTIONS, SCHOOL_JOB_FUNCTIONS } from "forms/fields/JobFunctionsField"
import handleErrors from "forms/handleErrors"
import Yup, { userSchemas, profileSchemas } from "forms/yup"
import { useRegister } from "resources/get_started"
import { useJoinKitInstanceByCode } from "resources/monthly_kit"
import { meUserCacheKey } from "resources/users"
import PageTitle from "ui/PageTitle"
import { getTimeZone } from "utils/date"
import { KitGroupSize } from "utils/kit"

const profileSchema = Yup.object().shape(pick(profileSchemas, ["pronouns", "job_function"]))
const baseUserSchemas = pick(userSchemas, ["first_name", "last_name", "email", "password", "accept_policies"])
const minimalRegisterSchema = Yup.object().shape(baseUserSchemas)
const normalRegisterSchema = Yup.object().shape({
  ...baseUserSchemas,
  profile: profileSchema,
})

const getMinimalInitialValues = ({ email }) => ({
  email,
  first_name: "",
  last_name: "",
  password: "",
  accept_policies: false,
})
const getNormalInitialValues = ({ email }) => ({
  ...getMinimalInitialValues({ email }),
  profile: {
    pronouns: "",
    job_function: "",
  },
})

const TeamCodeRegistration = ({ className }) => {
  const navigate = useNavigate()
  const { mutateAsync: register } = useRegister()
  const { state } = useLocation()
  const { mutateAsync: joinKitInstanceByCode } = useJoinKitInstanceByCode()
  const { sessionCode } = useParams()
  const { setAccountType } = useAccountType()
  const queryClient = useQueryClient()
  useSetAccountType({ accountType: state?.accountType, shouldSet: !!state, setAccountType })
  const isSchool = useIsSchoolAccountType()

  const showMinimalRegisterForm = state?.is_demo || state?.kitGroupSize === KitGroupSize.JUMBO
  const email = state?.email
  if (!email) {
    return null
  }

  const initialValues = showMinimalRegisterForm ? getMinimalInitialValues({ email }) : getNormalInitialValues({ email })
  const registerSchema = showMinimalRegisterForm ? minimalRegisterSchema : normalRegisterSchema
  const jobFunctions = isSchool ? SCHOOL_JOB_FUNCTIONS : JOB_FUNCTIONS

  const onSubmit = handleErrors(async (values) => {
    await register({
      ...values,
      time_zone: getTimeZone(),
    })
    const kitInstance = await joinKitInstanceByCode({ code: sessionCode })

    // Invalidate the current user in the react-query cache. If this is the user's first kit,
    // user.has_an_active_account would be false until after the joinKitInstanceByCode
    queryClient.removeQueries({ queryKey: meUserCacheKey, exact: true })
    navigate(kitInstance.session_url)
  }, mapNonFieldErrorsData)

  return (
    <div className={className}>
      <PageTitle>Create your account</PageTitle>
      <h2 className="mb-xs">Create your account</h2>
      <div>
        Already have an account?
        <Link to={`/auth/login?next=${encodeURIComponent("/code")}`} className="text-semi-bold">
          {" "}
          Log in here
        </Link>
      </div>
      <div>
        <Formik initialValues={initialValues} validationSchema={registerSchema} onSubmit={onSubmit}>
          <TeamMemberRegisterForm showMinimalRegisterForm={showMinimalRegisterForm} jobFunctions={jobFunctions} />
        </Formik>
      </div>
    </div>
  )
}

const mapNonFieldErrorsData = (errorsData) => {
  if (!isObject(errorsData)) {
    return errorsData
  }
  return mapKeys(errorsData, (value, key) => {
    if (key === "email" || key === "session_code") {
      return "non_field_errors"
    }
    return key
  })
}

export default TeamCodeRegistration
