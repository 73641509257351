import cn from "classnames"
import { useState } from "react"

import ExerciseComponentBody from "./ExerciseComponentBody"

import Button from "ui/Button"
import { scrollToTop } from "utils/browser"

const ExerciseToggleComponentsButtonBody = ({
  exerciseComponentNameMap,
  components,
  toggleComponents,
  buttonText,
  toggledButtonText,
  buttonLocation,
  kit,
  saveOnChange,
}) => {
  const [isToggled, setIsToggled] = useState(false)

  const renderComponent = ({ component, identifier, ...props }) => {
    const AsComponent = exerciseComponentNameMap[component]
    return (
      <ExerciseComponentBody
        AsComponent={AsComponent}
        key={identifier}
        identifier={identifier}
        saveOnChange={saveOnChange}
        kit={kit}
        {...props}
      />
    )
  }

  const handleOnClick = () => {
    setIsToggled(!isToggled)
    requestAnimationFrame(() => scrollToTop())
  }

  const button = (
    <Button className={cn({ secondary: !isToggled, tertiary: isToggled })} onClick={handleOnClick}>
      {isToggled ? (toggledButtonText ?? buttonText) : buttonText}
    </Button>
  )

  const visibleComponents = isToggled ? toggleComponents : components

  return (
    <div>
      {buttonLocation === "top" && button}
      {visibleComponents.map(renderComponent)}
      {buttonLocation === "bottom" && button}
    </div>
  )
}

export default ExerciseToggleComponentsButtonBody
