import { keyBy } from "lodash-es"

import { getChartConfig } from "domains/Exercise/results_utils/talents"
import TalentsAccuracyForm from "domains/Exercise/ResultsComponents/TalentsAccuracyForm"
import TalentsUserResults from "domains/Exercise/ResultsComponents/TalentsUserResults"
import FacilitatorTip from "domains/KitSession/components/FacilitatorTip"
import SidebarContainer from "domains/KitSession/components/SidebarContainer"
import StepContent from "domains/KitSession/components/StepContent"
import { useKitSession } from "domains/KitSession/KitSessionContext"
import { useOrCreateExerciseInstance } from "resources/exercise"
import { useKitParticipants } from "resources/monthly_kit"
import { SHARED_FLAGS } from "ui/hooks/useFeatures"
import Loading from "ui/Loading"
import RadarChart from "ui/RadarChart"
import TextContainer from "ui/TextContainer"
import { useHasTeamFeature } from "utils/team"

const TalentsReviewStep = ({ kitInstance, className }) => {
  const kit = kitInstance.kit
  const { team } = useKitSession()
  const { enabled: sessionRealtimeUpdatesV2 } = useHasTeamFeature(team, SHARED_FLAGS.RTDEV_REALTIME_REPLACE_POLLING)
  const { data: participants } = useKitParticipants({ kitInstance, sessionRealtimeUpdates: sessionRealtimeUpdatesV2 })
  const { data: exercise, isFetching } = useOrCreateExerciseInstance({
    teamId: kitInstance.team_id,
    slug: kit.exercise.slug,
    version: kit.exercise.version,
  })

  if (!participants || isFetching) {
    return <Loading />
  }

  const participantIdMap = keyBy(participants, "id")
  const chartConfig = getChartConfig([exercise], participantIdMap)

  return (
    <StepContent className={className}>
      <SidebarContainer>
        <TextContainer maxWidth={550}>
          <p className="text-gray-9 mb-small">
            Your natural talents are the places your chart expands towards the outer edges of the circle. The talents
            covered here are not a complete set of everything possible, rather the ones most commonly needed in the
            workplace. If you find there are other talents that are missing, feel free to bring that up in the
            discussion. Also, remember that this is about identifying what comes easily to you and that you enjoy most.
            You may also be “good at” areas that don’t spike as high on the chart.
          </p>
          <RadarChart {...chartConfig} />
        </TextContainer>
        <FacilitatorTip
          className="mb-xl sidebar-right"
          tip="Give everyone a few minutes to review their results. Use the module at the bottom of this page to know when to move on to the next screen."
        />
      </SidebarContainer>

      <h2 className="text-bold text-gray-9 mt-xl mb-medium">Your top talents</h2>
      <p className="text-gray-9 mb-large">
        These areas are more likely to come easily to you and bring you joy. Try to spend as much of your time here as
        you can.
      </p>
      <TalentsUserResults exercise={exercise} className="mb-xl" />
      <TalentsAccuracyForm exercise={exercise} />
    </StepContent>
  )
}

export default TalentsReviewStep
