import { set } from "lodash-es"

import AssessmentAccuracyForm from "domains/Exercise/ResultsComponents/AssessmentAccuracyForm"
import CrisisResponseUserResponderTypeResults from "domains/Exercise/ResultsComponents/CrisisResponseUserResponderTypeResults"
import StepContent from "domains/KitSession/components/StepContent"
import LineRatingField from "forms/fields/LineRatingField"
import TextareaField from "forms/fields/TextareaField"
import Yup from "forms/yup"
import { useOrCreateExerciseInstance, useMutateExerciseAnswer } from "resources/exercise"
import Loading from "ui/Loading"
import TextContainer from "ui/TextContainer"

const CrisisResponseResponderTypeStep = ({ kitInstance, className }) => {
  const kit = kitInstance.kit
  const { data: exercise, isFetching } = useOrCreateExerciseInstance({
    teamId: kitInstance.team_id,
    slug: kit.exercise.slug,
    version: kit.exercise.version,
  })

  if (isFetching && !exercise) {
    return <Loading />
  }

  return (
    <StepContent className={className}>
      <h2 className="text-gray-9 mb-medium">Review your results</h2>
      <TextContainer maxWidth={860}>
        <p className="text-gray-9 mb-large mr-small">
          Based on those scenarios, your answers most closely matched the following Responder Types. We all have the
          ability to act as any Responder Type and we'll practice that in the next step.
        </p>
      </TextContainer>
      <h3 className="text-bold text-gray-9 mb-xl">Your top matches are:</h3>
      <CrisisResponseUserResponderTypeResults exercise={exercise} className="mb-large" />
      <CrisisResponseAssessmentAccuracyReview exercise={exercise} />
    </StepContent>
  )
}

const CrisisResponseAssessmentAccuracyReview = ({ exercise }) => {
  const { mutateAsync: mutateExerciseAnswer } = useMutateExerciseAnswer(exercise.id)

  const initialValues = {
    assessment_feel: exercise.answers.find(({ identifier }) => identifier === "assessment_feel")?.data,
    assessment_feel_reason: exercise.answers.find(({ identifier }) => identifier === "assessment_feel_reason")?.data,
  }
  const lineRatingLabels = ["Not at all", "Just a bit", "Somewhat", "Pretty much", "A lot"]

  const saveOnChange = (name, value) => {
    mutateExerciseAnswer(set({}, name, value))
  }

  return (
    <AssessmentAccuracyForm title="Reflect" initialValues={initialValues} validationSchema={reviewSchema}>
      <p className="text-normal text-gray-9 mb-medium">
        The purpose of this exercise is to help uncover patterns and inspire conversation; not put you in a box.
      </p>
      <div className="text-field-label mt-large mb-xs">How much do these results feel like you?</div>
      <LineRatingField
        numOptions={5}
        name="assessment_feel"
        axisLabels={lineRatingLabels}
        ariaLabels={lineRatingLabels}
        className="mb-xl"
        saveOnChange={saveOnChange}
      />
      <TextareaField
        name="assessment_feel_reason.value"
        label="Why or why not?"
        className="mb-xl"
        saveOnChange={saveOnChange}
      />
    </AssessmentAccuracyForm>
  )
}

const reviewSchema = Yup.object().shape({
  assessment_feel: Yup.object().shape({
    value: Yup.string().required().label("How much does this assessment feel like you?"),
  }),
  assessment_feel_reason: Yup.object().shape({
    value: Yup.string().label("Why or why not?"),
  }),
})

export default CrisisResponseResponderTypeStep
