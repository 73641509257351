import { getExerciseAnswer, formatExercisePieChartData } from "domains/Exercise/results_utils"
import PieSliceChart from "ui/PieSliceChart"
import TextContainer from "ui/TextContainer"
import { getRepeatingColorFunc } from "ui/theme"

const PieSliceGroupFieldPieChart = ({ identifier, exerciseInstance, exerciseComponent, className }) => {
  const sliceValues = getExerciseAnswer(exerciseInstance, identifier)
  const otherValue = getExerciseAnswer(exerciseInstance, `${identifier}_other`)
  const { items, colors } = exerciseComponent

  if (!sliceValues || !Object.values(sliceValues).some((value) => value > 0)) {
    return (
      <TextContainer>
        <p>It looks like you haven't selected any slices.</p>
      </TextContainer>
    )
  }

  const selectedItems = items.filter(({ identifier }) => sliceValues[identifier] > 0)
  const data = formatExercisePieChartData(selectedItems, sliceValues, otherValue)
  const keys = selectedItems.map(({ identifier }) => identifier)
  const getColor = getRepeatingColorFunc(keys, colors, { sortKeys: false })

  return <PieSliceChart data={data} colors={getColor} enableArcLabels={false} className={className} />
}

export default PieSliceGroupFieldPieChart
